import { ParentsForm } from '@components/templates';
import { Layout } from '@components/atoms';
import { trackGA4PageView } from '@data/utils';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useEffect } from 'react';
import { PAGE_NAMES } from '@domain/constants';

export const EditParentsPage = () => {
  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.profileDetailsEditParents,
      title: PAGE_NAMES.profileDetailsEditParents,
    });
  }, []);

  return (
    <Layout isFlex containerStyles='justify-between'>
      <ParentsForm />
    </Layout>
  );
};
