import { ReactNode } from 'react';

interface BorderContainerProps {
  children: ReactNode;
  containerStyles?: string;
}

export const BorderContainer = ({
  children,
  containerStyles,
}: BorderContainerProps) => {
  return (
    <div className={`w-full rounded-xl p-2 ${containerStyles}`}>{children}</div>
  );
};
