export const ATTENDANCE_STREAK_CAP = 'attendance_streak_cap';
export const ATTENDANCE_STREAK_BONUS = 'attendance_streak_bonus';
export const ATTENDANCE_STREAK_REWARD = 'attendance_streak_reward';
export const COMPLETE_CLASS_REWARD = 'complete_class_reward';
export const COMPLETE_MODULE_REWARD = 'complete_module_reward';
export const DAILY_PRAYER_MULTIPLIER = 'daily_prayer_multiplier';
export const DAILY_PRAYER_WEEKLY_STREAK_BONUS =
  'daily_prayer_weekly_streak_bonus';
export const DAILY_PRAYER_WEEKLY_STREAK_CAP = 'daily_prayer_weekly_streak_cap';
export const DAILY_PRAYER_WEEKLY_STREAK_REWARD =
  'daily_prayer_weekly_streak_reward';
export const LESSON_IMPROVEMENT_BONUS = 'lesson_improvement_bonus';
export const LESSON_NEW_RECORD_BONUS = 'lesson_new_record_bonus';
export const RETO_EARLY_BIRD_MULTIPLIER = 'reto_early_bird_multiplier';
export const RETO_MILESTONE_MARK = 'reto_milestone_mark';
export const RETO_MILESTONE_REWARD = 'reto_milestone_reward';
export const RETO_STREAK_REWARD = 'reto_streak_reward';
export const WELCOME_BONUS = 'welcome_bonus';
