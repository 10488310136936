import { Modal } from '@components/molecules';
import { Button, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { closeModalCloseLesson } from '@store/slices';
import { useDispatch } from 'react-redux';

interface ModalCloseLessonProps {
  isOpen: boolean;
  clickExitLesson?: () => void;
}

export const ModalCloseLesson = ({
  isOpen,
  clickExitLesson,
}: ModalCloseLessonProps) => {
  const { t } = useTranslation('components/organisms/modalCloseLesson');
  const dispatch = useDispatch();

  return isOpen ? (
    <Modal
      open={isOpen}
      title={t('title')}
      onClose={() => dispatch(closeModalCloseLesson())}
    >
      <div className='grid gap-4'>
        <Typography variant='span'>{t('message')}</Typography>

        <Button
          style='primary'
          text={t('buttons.continueLesson')}
          type='button'
          onClick={() => dispatch(closeModalCloseLesson())}
        />

        <Button
          type='button'
          style='secondary'
          text={t('buttons.exitLesson')}
          onClick={clickExitLesson ?? (() => {})}
        />
      </div>
    </Modal>
  ) : null;
};
