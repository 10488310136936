import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePubNub } from 'pubnub-react';
import { PollData } from '@components/molecules';
import {
  UseActivityProps,
  ActivitySubtype,
  ActivityPayload,
  CustomMessageEvent,
  SlideActionType,
  SlideBodyType,
} from '@domain/constants';
import { UserSelectors } from '@store/slices';
import { useSelector } from 'react-redux';

export const useQuiz = ({ channel, slideId }: UseActivityProps) => {
  const [responses, setResponse] = useState<string[]>([]);

  const pubnub = usePubNub();

  const userId = useSelector(UserSelectors.getId);

  const handleQuizResponse = useCallback(
    (event: CustomMessageEvent) => {
      const body = event.message.body;

      if (
        body.type === SlideBodyType.ACTIVITY &&
        body.activity_type === ActivitySubtype.QUIZ &&
        body.activity_id === slideId
      ) {
        setResponse(responses => [...responses, body.answer!]);
      }
    },
    [slideId]
  );

  const sendQuizResponse = async (answer: string) => {
    if (answer) {
      const messagePayload: ActivityPayload = {
        uuid: userId,
        action: SlideActionType.ACTIVITY,
        body: {
          type: SlideBodyType.ACTIVITY,
          activity_type: ActivitySubtype.QUIZ,
          answer,
          activity_id: slideId,
        },
        subscribed_channel: channel,
        actual_channel: channel,
      };

      await pubnub.publish({
        channel: channel,
        message: messagePayload,
      });
    }
  };

  const countStrings = (strings: string[]): PollData[] => {
    const countMap: { [key: string]: number } = {};

    for (const str of strings) {
      if (countMap[str]) {
        countMap[str]++;
      } else {
        countMap[str] = 1;
      }
    }

    const result: PollData[] = Object.keys(countMap).map(key => ({
      x: key,
      y: countMap[key],
    }));

    return result;
  };

  useEffect(() => {
    const listenerParams = {
      message: handleQuizResponse,
    };

    pubnub.addListener(listenerParams);
    pubnub.subscribe({ channels: [channel] });

    return () => {
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, channel, handleQuizResponse]);

  useEffect(() => {
    setResponse([]);
  }, [slideId]);

  const quizResponses = useMemo(() => countStrings(responses), [responses]);

  return {
    quizResponses,
    sendQuizResponse,
  };
};
