import { ReactSVG } from 'react-svg';

interface BadgeImageProps {
  src: string;
  color: string;
  className?: string;
}

export const BadgeImage = ({ src, color, className }: BadgeImageProps) => {
  return (
    <div className={className}>
      <ReactSVG
        src={src}
        beforeInjection={svg => {
          if (color) {
            svg.querySelectorAll('path').forEach(path => {
              path.setAttribute('fill', color);
            });
          }
        }}
      />
    </div>
  );
};
