import { Content, UserRole } from '@domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';
import { SlideTextInput, Wordcloud } from '@components/molecules';
import { useWordCloud } from '@hooks/index';

interface RenderWordCloudActivityProps {
  slide: Content;
  role: UserRole;
  channel: string;
  showResults: boolean;
}

export const RenderWordCloudActivity = ({
  slide,
  role,
  channel,
  showResults,
}: RenderWordCloudActivityProps) => {
  const { words, sendWordCloudResponse } = useWordCloud({
    channel,
    slideId: slide.id,
  });

  const screenWidth = window.screen.width - 48;
  const screenHeight = window.screen.height / 3;

  return (
    <SlideContentWrapper
      title={slide.wordCloud!.question}
      type={slide.type}
      subtype={slide.subtype}
    >
      {role === UserRole.CATECHIST ? (
        <Wordcloud words={words} width={screenWidth} height={screenHeight} />
      ) : !showResults ? (
        <SlideTextInput
          onSubmit={sendWordCloudResponse}
          activitySubtype={slide.subtype}
        />
      ) : (
        <Wordcloud words={words} width={screenWidth} height={screenHeight} />
      )}
    </SlideContentWrapper>
  );
};
