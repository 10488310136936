export const formatDateTime = (date: Date) => {
  if (!date) return '';

  const dateTime = new Date(date);

  const formattedDate = dateTime.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  const formattedTime = dateTime.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });
  const formattedDateTime = `${formattedDate} at ${formattedTime}`;

  return formattedDateTime;
};

export const convertToUTCMinus4 = (date: Date) => {
  const utcTime = date.getTime();
  const utcOffsetInMilliseconds = -4 * 60 * 60 * 1000;
  const utcMinus4Date = new Date(utcTime + utcOffsetInMilliseconds);
  return utcMinus4Date.toISOString().replace('Z', '-04:00');
};
