import { ReactNode } from 'react';
import { Loader } from '../Loader/Loader';

interface ProfileCardProps {
  children: ReactNode;
  className?: string;
  isLoading?: boolean;
}

export const ProfileCard = ({
  children,
  className,
  isLoading,
}: ProfileCardProps) => {
  return (
    <div
      className={`w-full rounded-xl bg-secondary-surface-100 p-4 shadow-general ${className}`}
    >
      {isLoading ? <Loader /> : children}
    </div>
  );
};
