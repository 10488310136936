interface LoginImageProps {
  image: string;
}

export const LoginImage = ({ image }: LoginImageProps) => {
  return (
    <div className='h-1/5'>
      <img className='h-full w-full object-cover' src={image}></img>
    </div>
  );
};
