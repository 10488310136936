import { useDispatch, useSelector } from 'react-redux';
import {
  setEmail,
  setClassCode,
  setPassword,
  setConfirmPassword,
  setLastName,
  setUsername,
  addGuardian,
  updateGuardian,
  setClassName,
  setName,
  loginSuccess,
  setAvatar,
} from '@store/slices';
import { OnboardingSelectors, resetOnboarding } from '@store/slices';
import { useMutation } from '@tanstack/react-query';
import { WebClientRest } from '@data/api/clients';
import { Api } from '@data/api';
import {
  UserRole,
  AUTH_TOKEN_STORAGE_KEY,
  ToastNotificationType,
  SignupAnalytics,
} from '@domain/constants';
import { HttpStatusCode } from '@domain/api';
import { NotificationsDispatcher, trackGA4Events } from '@data/utils';
import { useTranslation } from 'react-i18next';
import { loginAdapter } from '@data/adapters';

interface CheckUserExistsData {
  email: string;
  username: string;
}

export const useSignup = () => {
  const dispatch = useDispatch();
  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });
  const api = new Api(apiInstance);
  const userOnboarding = useSelector(OnboardingSelectors.getOnboarding);

  const { t } = useTranslation(['pages/auth/signup/signupUserPage', 'common']);

  const checkUserExists = async ({ email, username }: CheckUserExistsData) => {
    try {
      const params = {
        email,
        username,
      };

      Object.keys(params).forEach(key => {
        if (!params[key]) {
          delete params[key];
        }
      });

      const response = await api.users.checkUserExists(params);

      if (response.status === HttpStatusCode.OK) {
        const { email_exists, username_exists } = response.data;

        if (email_exists) {
          throw {
            title: t('toastErrors.emailAlreadyExists.title'),
            message: t('toastErrors.emailAlreadyExists.message'),
          };
        }
        if (username_exists) {
          throw {
            title: t('toastErrors.usernameAlreadyExists.title'),
            message: t('toastErrors.usernameAlreadyExists.message'),
          };
        }
      }
    } catch (error: unknown) {
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: error.title,
        message: error.message,
      });
      throw error;
    }
  };

  const createUser = async () => {
    const checkAndReturnArray = parents => {
      if (parents.length === 0) {
        return [];
      }

      const isEmpty = parents.every(parent =>
        Object.values(parent).every(value => !value)
      );

      return isEmpty ? [] : parents;
    };

    const parents = checkAndReturnArray(userOnboarding.guardians);
    try {
      const response = await api.users.createStudent({
        email: userOnboarding.email || null,
        username: userOnboarding.username || null,
        password: userOnboarding.password,
        password_confirmation: userOnboarding.confirmPassword,
        role: UserRole.STUDENT,
        first_name: userOnboarding.firstName,
        last_name: userOnboarding.lastName,
        course_id: userOnboarding.classCode,
        shop_id: userOnboarding.avatar,
        parents_attributes: parents.map(parent => ({
          relationship: parent.relationship,
          first_name: parent.firstName,
          last_name: parent.lastName,
          phone_number: parent.phoneNumber,
          email: parent.email,
        })),
      });

      if (response.status === HttpStatusCode.OK) {
        const user = loginAdapter(response.data);

        localStorage.setItem(
          AUTH_TOKEN_STORAGE_KEY,
          response.headers.authorization
        );
        dispatch(
          loginSuccess({
            ...user,
          })
        );

        dispatch(resetOnboarding());

        trackGA4Events(
          SignupAnalytics.categories.signup,
          SignupAnalytics.actions.createAccount,
          SignupAnalytics.labels.createAccount
        );

        return;
      }

      throw Error('Error creating user');
    } catch (error) {
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastErrors.general.title', {
          ns: 'pages/auth/signup/signupAvatarPage',
        }),
        message: t('toastErrors.general.message', {
          ns: 'pages/auth/signup/signupAvatarPage',
        }),
      });
      throw error;
    }
  };

  const getFreeAvatars = async () => {
    try {
      const response = await api.shop.getFreeAvatars();

      if (response.status === HttpStatusCode.OK) {
        return response.data;
      }

      throw Error('Error getting avatars');
    } catch (error) {
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastErrors.getAvatars.title', {
          ns: 'pages/auth/signup/signupAvatarPage',
        }),
        message: t('toastErrors.getAvatars.message', {
          ns: 'pages/auth/signup/signupAvatarPage',
        }),
      });
      throw error;
    }
  };

  const getClassNameByCode = async (classCode: string) => {
    try {
      const response = await api.classes.getClassById(classCode);

      if (response.status === HttpStatusCode.OK) {
        return response.data.course.name;
      }

      throw Error('Class not found');
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  const createUserMutation = useMutation({
    mutationFn: createUser,
  });

  const checkUserExistsMutation = useMutation({
    mutationFn: ({ email, username }: CheckUserExistsData) =>
      checkUserExists({ email, username }),
  });

  const getClassNameByCodeMutation = useMutation({
    mutationFn: (classCode: string) => getClassNameByCode(classCode),
  });

  const setClassCodeInput = (classCode: string) => {
    dispatch(setClassCode(classCode));
  };

  const setEmailInput = (email: string) => {
    dispatch(setEmail(email));
  };

  const setNameInput = (name: string) => {
    dispatch(setName(name));
  };

  const setLastNameInput = (lastName: string) => {
    dispatch(setLastName(lastName));
  };

  const setUsernameInput = (username: string) => {
    dispatch(setUsername(username));
  };

  const setPasswordInput = (password: string) => {
    dispatch(setPassword(password));
  };

  const setConfirmPasswordInput = (confirmPassword: string) => {
    dispatch(setConfirmPassword(confirmPassword));
  };

  const setAvatarImage = (avatar: number | null) => {
    dispatch(setAvatar(avatar));
  };

  const setClassNameInput = (className: string) => {
    dispatch(setClassName(className));
  };

  const handleAddGuardian = () => {
    dispatch(addGuardian());
  };

  const handleInputChange = (index: number, field: string, value: string) => {
    dispatch(updateGuardian({ index, field, value }));
  };

  return {
    checkUserExists,
    checkUserExistsMutation,
    createUserMutation,
    getClassNameByCodeMutation,
    getFreeAvatars,
    handleAddGuardian,
    handleInputChange,
    setAvatarImage,
    setClassCodeInput,
    setClassNameInput,
    setConfirmPasswordInput,
    setEmailInput,
    setLastNameInput,
    setNameInput,
    setPasswordInput,
    setUsernameInput,
  };
};
