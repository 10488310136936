import { Typography } from '@components/atoms';
import { BadgeWithModal } from '@components/organisms';
import { useTranslation } from 'react-i18next';
import { getAchievementLevels } from 'src/data/utils';
import { ACHIEVEMENT_STATES } from 'src/domain/constants';
import { AnimatePresence, motion } from 'framer-motion';

interface BadgesListProps {
  badges: any[];
  stats: any;
}

export const BadgesList = ({ badges, stats }: BadgesListProps) => {
  const { t } = useTranslation(
    'pages/home/home/classes/myProgress/myProgressPage'
  );

  return (
    <AnimatePresence mode='wait'>
      <div className='mt-6 space-y-6'>
        <motion.div
          initial={{ y: '-50%', opacity: 0 }} // Start off-screen to the left
          animate={{ y: 0, opacity: 1 }} // Slide to the original position
          transition={{
            delay: 0.3,
            duration: 0.3,
            ease: 'easeInOut',
          }}
        >
          <Typography
            variant='span'
            className='text-[16px] font-bold leading-[20px]'
          >
            {t('badges')}
          </Typography>
        </motion.div>

        <motion.div
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{
            duration: 0.3,
            delay: 0.5,
          }}
        >
          <div className='grid grid-cols-3 gap-4'>
            {badges.map(badge => {
              const { currentLevel } = getAchievementLevels(badge.levels);

              const studentHasBadge = currentLevel >= 1;

              if (
                badge.state === ACHIEVEMENT_STATES.archived &&
                !studentHasBadge
              ) {
                return null;
              }
              return (
                <BadgeWithModal key={badge.id} badge={badge} stats={stats} />
              );
            })}
          </div>
        </motion.div>
      </div>
    </AnimatePresence>
  );
};
