import { usePubNub } from 'pubnub-react';
import { useState, useEffect, useMemo, useCallback } from 'react';
import {
  ActivitySubtype,
  UseActivityProps,
  ActivityPayload,
  SlideActionType,
  SlideBodyType,
} from '@domain/constants';
import { MessageEvent } from 'pubnub';
import { UserSelectors } from '@store/slices';
import { useSelector } from 'react-redux';

interface WordData {
  text: string;
  value: number;
}

export const useWordCloud = ({ channel, slideId }: UseActivityProps) => {
  const [responseInput, setResponseInput] = useState<string>('');
  const [responses, setResponses] = useState<string[]>([]);
  const [words, setWords] = useState<WordData[]>([]);

  const pubnub = usePubNub();

  const userId = useSelector(UserSelectors.getId);

  const handleWordCloudResponse = useCallback(
    (event: MessageEvent) => {
      const body = event.message.body;

      if (
        body.type === SlideBodyType.ACTIVITY &&
        body.activity_type === ActivitySubtype.WORD_CLOUD &&
        body.activity_id === slideId
      ) {
        setResponses(responses => [...responses, body.answer]);
      }
    },
    [slideId]
  );

  const sendWordCloudResponse = async (answer: string) => {
    if (answer) {
      const messagePayload: ActivityPayload = {
        uuid: userId,
        action: SlideActionType.ACTIVITY,
        body: {
          type: SlideBodyType.ACTIVITY,
          activity_type: ActivitySubtype.WORD_CLOUD,
          answer,
          activity_id: slideId,
        },
        subscribed_channel: channel,
        actual_channel: channel,
      };

      await pubnub.publish({
        channel: channel,
        message: messagePayload,
      });
    }
  };

  const flattenArray = (stringArray: string[]): string => {
    return stringArray.join(' ');
  };

  const wordFrequency = (text: string): WordData[] => {
    const wordCounts = new Map<string, number>();
    const words = text.toLowerCase().replace(/\W/g, ' ').split(/\s+/);

    for (const word of words) {
      const count = wordCounts.get(word) || 0;
      wordCounts.set(word, count + 1);
    }

    return Array.from(wordCounts).map(([word, count]) => ({
      text: word,
      value: count,
    }));
  };

  useEffect(() => {
    const listenerParams = {
      message: handleWordCloudResponse,
    };

    pubnub.addListener(listenerParams);
    pubnub.subscribe({ channels: [channel] });

    return () => {
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, channel, handleWordCloudResponse]);

  useMemo(() => {
    const flattenedResponses = flattenArray(responses);
    const words = wordFrequency(flattenedResponses);
    setWords(words);
  }, [responses]);

  useEffect(() => {
    setResponseInput('');
    setResponses([]);
  }, [slideId]);

  return {
    words,
    sendWordCloudResponse,
    responseInput,
    setResponseInput,
  };
};
