export const PUBLIC_ROUTES_PATHS = {
  login: '/login',
  signup: '/signup/:classCode?',
  splash: '/',
  forgotPassword: '/forgot-password',
  resetPassword: '/reset-password/:resetCode',
};

export const PROTECTED_ROUTES_PATHS = {
  home: '/home',
  shop: '/shop',
  shopAvatars: '/shop/avatars',
  shopCollectibles: '/shop/collectibles',
  shopPurchased: '/shop/purchased',
  shopPurchasedAvatars: '/shop/purchased/avatars',
  shopPurchasedCollectibles: '/shop/purchased/collectibles',
  profile: '/profile',
  prayerGame: '/prayer-game',
  modules: '/classes/:classId/modules',
  retos: '/classes/:classId/retos',
  gradedRetos: '/classes/:classId/retos/graded',
  retosAnswer: '/classes/:classId/retos/:retoId/answer',
  retosResponses: '/classes/:classId/retos/:retoId/responses',
  retoResponseDetails: '/classes/:classId/retos/:retoId/responses/:responseId',
  myProgress: '/classes/:classId/progress',
  students: '/classes/:classId/students',
  lessons: '/classes/:classId/modules/:moduleId/lessons',
  lessonSummary:
    '/classes/:classId/modules/:moduleId/lessons/:lessonId/summary',
  activity: '/classes/:classId/modules/:moduleId/lessons/:lessonId/activity/',
  leaderboard:
    '/classes/:classId/modules/:moduleId/lessons/:lessonId/leaderboard',
  profileDetails: '/profile/details',
  profileDetailsEditName: '/profile/details/edit-name',
  profileDetailsEditParents: '/profile/details/edit-parents',
  changePassword: '/profile/change-password',
  profileAvatar: '/profile/avatar',
};
