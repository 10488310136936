export enum SlideActionType {
  ACTIVITY = 'activity',
  CHANGE_SLIDE = 'change_slide',
  FINISH_LESSON = 'finish_lesson',
  EXIT_LESSON = 'exit_lesson',
  SHOW_RESULTS = 'show_results',
}

export enum SlideBodyType {
  ACTIVITY = 'activity',
  SLIDE = 'slide',
}
