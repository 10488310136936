import { useNavigate } from 'react-router-dom';
import { PUBLIC_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { WebClientRest } from '@data/api/clients';
import { Api } from '@data/api';
import { HttpStatusCode } from '@domain/api';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { NotificationsDispatcher } from '@data/utils';
import { ToastNotificationType } from '@domain/constants';
import { useTranslation } from 'react-i18next';

interface ResetPassword {
  token: string;
  password: string;
  confirmPassword: string;
}

export const useForgotPassword = () => {
  const [credentials, setCredentials] = useState<string>('');
  const [credentialsSent, setCredentialsSent] = useState<boolean>(false);

  const { t } = useTranslation([
    'pages/auth/forgotPassword/resetPasswordPage',
    'common',
  ]);

  const navigate = useNavigate();
  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });
  const api = new Api(apiInstance);

  const navigateToLogin = () => {
    navigate(PUBLIC_ROUTES_PATHS.login);
  };

  const submitForgotPassword = async (login: string) => {
    try {
      const response = await api.users.forgotPassword(login);

      if (response.status === HttpStatusCode.OK) {
        setCredentialsSent(true);
        setCredentials(login);
        return;
      }
    } catch (error: unknown) {
      const errorMessage = error.response.data.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle', { ns: 'common' }),
        message:
          errorMessage ||
          t('toastNotifications.errors.generalMessage', { ns: 'common' }),
      });

      throw error;
    }
  };

  const submitResetPassword = async ({
    token,
    password,
    confirmPassword,
  }: ResetPassword) => {
    try {
      const response = await api.users.resetPassword({
        token,
        password,
        confirm_password: confirmPassword,
      });

      if (response.status === HttpStatusCode.OK) {
        NotificationsDispatcher({
          type: ToastNotificationType.success,
          title: t('toastNotifications.success.title'),
          message: t('toastNotifications.success.message'),
        });
        navigate(PUBLIC_ROUTES_PATHS.login);

        return;
      }
    } catch (error) {
      const errorMessage = error.response.data.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle', { ns: 'common' }),
        message:
          errorMessage ||
          t('toastNotifications.errors.generalMessage', { ns: 'common' }),
      });

      throw error;
    }
  };

  const resetPasswordMutation = useMutation({
    mutationFn: ({ token, password, confirmPassword }: ResetPassword) =>
      submitResetPassword({ token, password, confirmPassword }),
  });

  const forgotPasswordMutation = useMutation({
    mutationFn: (login: string) => submitForgotPassword(login),
  });

  return {
    navigateToLogin,
    forgotPasswordMutation,
    credentials,
    credentialsSent,
    resetPasswordMutation,
  };
};
