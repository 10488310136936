import { Content, UserRole } from '@domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';
import { OpenQuestionResponses, SlideTextInput } from '@components/molecules';
import { useOpenQuestion } from '@hooks/index';

interface RenderOpenQuestionActivityProps {
  slide: Content;
  role: UserRole;
  channel: string;
  showResults: boolean;
}

export const RenderOpenQuestionActivity = ({
  slide,
  role,
  channel,
  showResults,
}: RenderOpenQuestionActivityProps) => {
  const { responses, sendOpenQuestionResponse } = useOpenQuestion({
    channel,
    slideId: slide.id,
  });

  return (
    <SlideContentWrapper
      title={slide.openQuestion?.question}
      type={slide.type}
      subtype={slide.subtype}
    >
      {role === UserRole.CATECHIST ? (
        <OpenQuestionResponses responses={responses} />
      ) : !showResults ? (
        <SlideTextInput
          activitySubtype={slide.subtype}
          questionText={slide.openQuestion?.question}
          onSubmit={sendOpenQuestionResponse}
        />
      ) : (
        <OpenQuestionResponses responses={responses} />
      )}
    </SlideContentWrapper>
  );
};
