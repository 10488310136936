import React from 'react';
import LottieAnimation, { LottieComponentProps } from 'lottie-react';

interface LottieProps
  extends Omit<
    LottieComponentProps,
    'animationData' | 'style' | 'autoplay' | 'loop'
  > {
  loop?: boolean;
  autoplay?: boolean;
  style?: React.CSSProperties;
  animationData: any;
}

export const Lottie: React.FC<LottieProps> = ({
  loop = true,
  autoplay = true,
  style,
  animationData,
  ...rest
}) => {
  return (
    <LottieAnimation
      {...rest}
      style={{ ...style }}
      autoplay={autoplay}
      loop={loop}
      animationData={animationData}
    />
  );
};
