import { useNavigate, useParams } from 'react-router-dom';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { WebClientRest } from '@data/api/clients';
import { Api } from '@data/api';
import { HttpStatusCode } from '@domain/api';
import { modulesAdapter } from '@data/adapters';
import { ToastNotificationType } from '@domain/constants';
import { NotificationsDispatcher } from '@data/utils';
import { useTranslation } from 'react-i18next';

export const useModules = () => {
  const navigate = useNavigate();
  const { t } = useTranslation('common');
  const { classId } = useParams();

  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });
  const api = new Api(apiInstance);

  const getModules = async () => {
    try {
      const response = await api.classes.getModules(classId!);

      if (response.status === HttpStatusCode.OK) {
        return modulesAdapter(response.data);
      }

      throw Error('Error getting modules');
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const navigateToHome = () => {
    navigate(PROTECTED_ROUTES_PATHS.home);
  };

  return {
    navigateToHome,
    getModules,
  };
};
