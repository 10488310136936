export interface DividerProps {
  text?: string;
  containerStyles?: string;
  className?: string;
}

export const Divider = ({ text, containerStyles, className }: DividerProps) => {
  return (
    <div className={`w-full ${containerStyles}`}>
      <div
        className={`divider before:bg-primary-200 after:bg-primary-200 ${className}`}>
        {text}
      </div>
    </div>
  );
};
