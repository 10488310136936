import { EmptyState, EmptyStateProps } from '@components/molecules';

export interface CardListProps<T> extends EmptyStateProps {
  data: T[];
  className?: string;
  CardComponent: React.FC<T>;
  getKey: (item: T, index: number) => string;
  getCardProps: (item: T, index: number) => any;
}

export const CardList = ({
  data,
  className,
  icon,
  getKey,
  getCardProps,
  CardComponent,
  label,
}: CardListProps<any>) => {
  if (!data || data.length === 0) {
    return <EmptyState icon={icon} label={label} />;
  }

  return (
    <div className={`flex flex-col gap-4 ${className}`}>
      {data.map((item, index) => {
        return (
          <CardComponent
            key={getKey(item, index)}
            {...getCardProps(item, index)}
          />
        );
      })}
    </div>
  );
};
