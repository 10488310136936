import { StudentCard } from '@components/molecules';
import { BorderContainer, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { ParentsInformation } from '@components/organisms';

interface ModalSheetStudentProps {
  student: Record<string, string>;
}

export const ModalSheetStudent = ({ student }: ModalSheetStudentProps) => {
  const { t } = useTranslation('pages/home/home/classes/students/studentsPage');
  return (
    <div className='mb-6 flex flex-col gap-6'>
      <StudentCard
        avatar={student?.profilePictureURL}
        id={Number(student?.id)}
        name={`${student?.firstName} ${student?.lastName}`}
        score={student?.points.lifetime || 0}
        showTacoPointsIcon
      />

      <BorderContainer containerStyles='bg-secondary-surface-100 flex flex-col items-center p-4 gap-4 [&>*]:w-full'>
        <div className='flex justify-between'>
          <Typography variant='span' className='font-bold'>
            {t('modal.lessonsDone')}
          </Typography>
          <Typography variant='span'>{student?.lessonsDone}</Typography>
        </div>
        <div className='flex justify-between'>
          <Typography variant='span' className='font-bold'>
            {t('modal.retos')}
          </Typography>
          <Typography variant='span'>{student?.retosDone}</Typography>
        </div>
      </BorderContainer>

      <ParentsInformation parents={student?.parents} className='shadow-none' />
    </div>
  );
};
