import { Card, Typography } from '@components/atoms';
import { StatusPill } from '@components/molecules';
import { STATES_STR } from '@domain/constants';
import { ModuleImage } from '@assets/img';
import { useNavigate, useParams } from 'react-router-dom';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { removeLessonStorage, setLessonStorage } from '@data/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react';

export interface ModuleCardProps {
  publicUid?: string;
  state: STATES_STR;
  title: string;
  description: string;
  imageUrl: string;
  cardColor: string;
  textColor: string;
}

export const ModuleCard = ({
  publicUid,
  state,
  title,
  description,
  imageUrl,
  cardColor,
  textColor,
}: ModuleCardProps) => {
  const { classId } = useParams();
  const [isAnimating, setIsAnimating] = useState(false);

  const handleClick = () => {
    if (!isModuleNotStarted()) {
      setIsAnimating(true);
    }
  };

  const isModuleInProgress = () => {
    return state == STATES_STR.inProgress || state == STATES_STR.finished;
  }
  const isModuleNotStarted = () => {
    return state === STATES_STR.notStarted;
  };

  const navigate = useNavigate();

  const navigateToLesson = () => {
    if (isModuleNotStarted()) return;

    removeLessonStorage();

    setLessonStorage(title, description, textColor, cardColor);

    navigate(
      PROTECTED_ROUTES_PATHS.lessons
        .replace(':classId', classId!)
        .replace(':moduleId', publicUid!)
    );
  };

  return (
    <AnimatePresence mode='wait'>
      <motion.div
          initial={{ scale: 1 }}
          animate={(isAnimating) ? { scale: 1.05, opacity: 1 } : { scale: 1, opacity: 1 }}
          transition={(isAnimating) ? { duration: 0.3, ease: 'easeInOut' } : { duration: 0.3, ease: 'easeInOut', type: "spring", stiffness: 400, damping: 17 }}
          onClick={handleClick}
          onAnimationComplete={isAnimating ? navigateToLesson : undefined} // Only navigate when animation is done
        >
      <Card
        style={{ backgroundColor: cardColor }}
        //onClick={handleClick}
        className='relative'
      >
        <div className='w-2/3 space-y-2'>
          {state === STATES_STR.finished && <StatusPill state={state} />}
          <div className='flex flex-col'>
            <Typography
              variant='span'
              style={{ color: textColor }}
              className='text-[16px] font-bold leading-[20px]'
            >
              {title}
            </Typography>
            <Typography
              variant='span'
              style={{ color: textColor }}
              className='text-wrap-ellipsis'
            >
              {description}
            </Typography>
          </div>
          {state !== STATES_STR.finished && <StatusPill state={state} />}
        </div>
        <img
          className='absolute right-4 top-0 h-full w-[100px]'
          src={imageUrl || ModuleImage}
        ></img>
      </Card>
      </motion.div>
    </AnimatePresence>
  );
};
