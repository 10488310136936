export const LoginAnalytics = {
  // ACTIONS
  actions: {
    login: 'Log In',
  },

  // CATEGORIES
  categories: {
    login: 'Login',
  },

  // LABELS
  labels: {
    login: 'User has logged in successfully',
  },
};
