import { HomeTemplate } from '@components/templates';
import { ClassesList, ReminderList } from '@components/organisms';
import { GameCard } from '@components/molecules';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { PAGE_NAMES, UserRole } from '@domain/constants';
import { useHome } from '@hooks/index';
import { useQuery } from '@tanstack/react-query';
import { trackGA4PageView } from 'src/data/utils';
import { useEffect } from 'react';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { setDailyPrayerGameStorage } from '@data/utils/home/localStorage';

export const HomePage = () => {
  const userRole = useSelector(UserSelectors.getRole);
  const { getHome } = useHome();

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['getHome'],
    queryFn: getHome,
    retry: false,
  });

  useEffect(() => {
    if (data?.dailyPrayerGame) {
      setDailyPrayerGameStorage(data.dailyPrayerGame);
    }
  }, [data]);

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.home,
      title: PAGE_NAMES.home,
    });
  }, []);

  return (
    <HomeTemplate isLoading={isLoading || isPending}>
      <GameCard />
      <ClassesList classes={data?.courses} />
      {userRole === UserRole.STUDENT && (
        <ReminderList
          reminder={data?.retos?.length > 0 ? data?.retos[0] : null}
        />
      )}
    </HomeTemplate>
  );
};
