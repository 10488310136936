import ReactGA4 from 'react-ga4';
import { PAGE_NAMES } from '@domain/constants';

interface TrackGA4PageViewParams {
  path: string;
  title: (typeof PAGE_NAMES)[keyof typeof PAGE_NAMES];
}

const initializeGA4 = () => {
  ReactGA4.initialize(import.meta.env.VITE_GOOGLE_ANALYTICS_MEASUREMENT_ID, {
    testMode: import.meta.env.DEV ? true : false,
  });
};

const trackGA4Events = (category: string, action: string, label?: string) => {
  ReactGA4.event({
    category,
    action,
    label,
  });
};

const trackGA4PageView = ({ path, title }: TrackGA4PageViewParams) => {
  ReactGA4.send({
    hitType: 'pageview',
    page: path,
    title,
  });
};

export default initializeGA4;

export { initializeGA4, trackGA4Events, trackGA4PageView };
