import { ShopItem, ShopItemProps } from '@components/molecules';
import { MdShoppingBag } from 'react-icons/md';
import { Typography } from '@components/atoms';

interface ShopItemListProps extends ShopEmptyStateProps {
  items: ShopItemProps[];
  studentPoints?: number | string | null;
  onClick?: () => void;
  hidePrice?: boolean;
  hideEmptyState?: boolean;
  className?: string;
  selectedAvatar?: number | null;
}

interface ShopEmptyStateProps {
  emptyStateText?: string;
}

const EmptyState = ({ emptyStateText }: ShopEmptyStateProps) => {
  return (
    <div className='flex w-full flex-col items-center  px-8 text-center'>
      <MdShoppingBag size={40} className='flex-shrink-0 text-primary-300' />
      <Typography variant='span'>{emptyStateText!}</Typography>
    </div>
  );
};

export const ShopItemList = ({
  items,
  studentPoints,
  emptyStateText,
  onClick,
  hidePrice = false,
  hideEmptyState = false,
  className,
  selectedAvatar,
}: ShopItemListProps) => {
  return items.length === 0 && !hideEmptyState ? (
    <EmptyState emptyStateText={emptyStateText} />
  ) : (
    <div className={`mt-4 grid grid-cols-3 gap-y-4 ${className}`}>
      {items.map(
        ({
          id,
          title,
          imageURL,
          requiredPoints,
          purchasedByStudent,
          link,
          type,
          description,
        }) => (
          <ShopItem
            key={id}
            id={id}
            name={title}
            avatarImage={type === 'avatar' ? imageURL : null}
            collectibleImage={type === 'collectible' ? imageURL : null}
            price={requiredPoints}
            studentPoints={studentPoints}
            isPurchased={purchasedByStudent}
            onClick={onClick}
            link={link}
            description={description}
            type={type}
            hidePrice={hidePrice}
            selectedAvatar={selectedAvatar || null}
          />
        )
      )}
    </div>
  );
};
