import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'src/ui/App';
import '@styles/index.css';
import store from '@store/index';
import { Provider } from 'react-redux';
import '@localization/i18n';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastNotificationsProvider } from '@components/organisms';
import * as Sentry from '@sentry/react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

export const queryClient = new QueryClient();

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DATA_SOURCE_NAME,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: [import.meta.env.VITE_APP_URL],
  profilesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.PROD,
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider store={store()}>
        <DndProvider backend={HTML5Backend}>
          <App />
          <ToastNotificationsProvider />
        </DndProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>
);
