import React, { useMemo } from 'react';
import { DraggableWord } from '@components/atoms';

interface DraggableWordListProps {
  words: string[];
}

const shuffleArray = (array: string[]): string[] => {
  const shuffled = [...array];
  const length = shuffled.length;

  for (let i = length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * i);
    
    if (j === i) {
      j = i - 1;
    }

    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }

  if (shuffled[0] === array[0]) {
    [shuffled[0], shuffled[length - 1]] = [shuffled[length - 1], shuffled[0]];
  }

  return shuffled;
};

export const DraggableWordList: React.FC<DraggableWordListProps> = ({ words = [] }) => {
  const shuffledWords = useMemo(() => {
    const shuffled = shuffleArray(words);
    return shuffled;
  }, [words]);

  if (shuffledWords.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-wrap justify-center items-start gap-2 p-2 my-3">
      {shuffledWords.map((word, index) => (
        <DraggableWord key={`${word}-${index}`} word={word} />
      ))}
    </div>
  );
};