import { Button, Input, Loader } from '@components/atoms';
import { HomeTemplate } from '@components/templates';
import { PositionedAvatar } from '@components/molecules';
import { SubmitHandler, useForm } from 'react-hook-form';
import { nameRules, trackGA4PageView } from '@data/utils';
import { useTranslation } from 'react-i18next';
import { useProfile } from '@hooks/index';
import { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { PAGE_NAMES } from '@domain/constants';

type Inputs = {
  firstName: string;
  lastName: string;
};

export const EditNamePage = () => {
  const { t } = useTranslation([
    'pages/home/profile/student/details/editName/editNamePage',
    'common',
  ]);
  const { navigateToProfileDetails, editNameMutation, getUser } = useProfile();

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['userDetails'],
    queryFn: getUser,
  });

  const onSubmit: SubmitHandler<Inputs> = async (data: Inputs) => {
    try {
      await editNameMutation.mutateAsync(data);
      navigateToProfileDetails();
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitForm = async (data: Inputs) => {
    onSubmit(data);
  };

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.profileDetailsEditName,
      title: PAGE_NAMES.profileDetailsEditName,
    });
  }, []);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid },
  } = useForm<Inputs>({
    defaultValues: {
      firstName: data?.firstName,
      lastName: data?.lastName,
    },
    mode: 'onChange',
  });

  useEffect(() => {
    if ((!isPending || !isLoading) && data) {
      setValue('firstName', data?.firstName);
      setValue('lastName', data?.lastName);
    }
  }, [data, setValue, isLoading, isPending]);

  return (
    <HomeTemplate isProfilePage onBackButtonClick={navigateToProfileDetails}>
      <PositionedAvatar />
      <div className='flex flex-1 flex-col justify-between gap-5 pt-12'>
        {isLoading || isPending ? (
          <Loader />
        ) : (
          <>
            <form
              id='profile-edit-name-form'
              onSubmit={handleSubmit(handleSubmitForm)}
            >
              <Input
                label={t('inputs.labels.firstName')}
                control={control}
                name='firstName'
                rules={nameRules(
                  t('inputs.errors.firstName'),
                  t('inputs.errors.invalid', {
                    inputName: 'first name',
                    ns: 'common',
                  })
                )}
              />
              <Input
                label={t('inputs.labels.lastName')}
                control={control}
                name='lastName'
                rules={nameRules(
                  t('inputs.errors.lastName'),
                  t('inputs.errors.invalid', {
                    inputName: 'last name',
                    ns: 'common',
                  })
                )}
              />
            </form>
            <Button
              className='w-full'
              disabled={!isValid || editNameMutation.isPending}
              form='profile-edit-name-form'
              type='submit'
              loading={editNameMutation.isPending}
              style='secondary'
              text={t('button.saveChanges', { ns: 'common' })}
            />
          </>
        )}
      </div>
    </HomeTemplate>
  );
};
