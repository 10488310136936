import { BorderContainer, Typography } from '@components/atoms';
import { ClassCard, ClassCardProps } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { UserRole } from '@domain/constants';
import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { MdSchool } from 'react-icons/md';

interface ClassesListProps {
  classes: ClassCardProps[];
}

export const ClassesList = ({ classes }: ClassesListProps) => {
  const { t } = useTranslation('pages/home/home/homePage');
  const role = useSelector(UserSelectors.getRole);
  // State to track whether the initial render is complete
  const [hasRendered, setHasRendered] = useState(false);

  // Effect to set hasRendered to true after the initial render
  useEffect(() => {
    const timer = setTimeout(() => {
      setHasRendered(true);
    }, 500); // Delay should match the duration of your initial animations

    // Cleanup timer if component unmounts before the timer completes
    return () => clearTimeout(timer);
  }, []);

  const [isVisible, setIsVisible] = useState(true);

  // Function to hide the card and trigger the exit animation
  const handleRemove = () => {
    setIsVisible(false);
  };

  return (
    <article>
      <AnimatePresence mode='wait'>
        <motion.div
          initial={{ y: '-150%', opacity: 0 }} // Start off-screen to the left
          animate={{ y: 0, opacity: 1 }} // Slide to the original position
          transition={{
            duration: 0.3,
            ease: 'easeInOut',
          }}
        >
          <Typography variant='h1'>
            {role === UserRole.STUDENT ? t('myClass') : t('myClasses')}
          </Typography>
        </motion.div>
        {classes.length !== 0 ? (
          <div className='mt-4 flex flex-col gap-4'>
            {classes.map((course, index) => (
              <AnimatePresence key={course.publicUid}>
                {isVisible && (
                  <motion.div
                    initial={{ x: '-130%', opacity: 0 }} // Start off-screen to the left
                    animate={{ x: 0, opacity: 1 }} // Slide to the original position
                    exit={{ x: 130, opacity: 0 }}
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{
                      type: 'spring',
                      damping: 40,
                      stiffness: 500,
                      delay: hasRendered ? 0 : 0.2 + index * 0.1,
                    }}
                  >
                    <ClassCard key={course.publicUid} {...course} />
                  </motion.div>
                )}
              </AnimatePresence>
            ))}
          </div>
        ) : (
          <BorderContainer containerStyles='flex w-full flex-col items-center p-4 mt-4 gap-4 text-center bg-secondary-surface-100'>
            <MdSchool size={40} className='flex-shrink-0 text-primary-300' />
            <Typography variant='span' className='font-bold !text-primary-400'>
              {t('emptyState')}
            </Typography>
          </BorderContainer>
        )}
      </AnimatePresence>
    </article>
  );
};
