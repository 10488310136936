import { toast } from 'react-toastify';
import {
  ToastNotification,
  ToastNotificationProps,
} from '@components/molecules';

export const NotificationsDispatcher = ({
  type,
  title,
  message,
}: ToastNotificationProps) => {
  toast(<ToastNotification type={type} title={title} message={message} />);
};
