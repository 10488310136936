import { Typography, TabLayout } from '@components/atoms';
import { TabNavigation } from '@components/organisms';
import { HomeTemplate, ModulesList } from '@components/templates';
import { useTranslation } from 'react-i18next';
import { MdChevronLeft } from 'react-icons/md';
import { useModules } from '@hooks/index';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import { getClassStorage, trackGA4PageView } from '@data/utils';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { PAGE_NAMES } from '@domain/constants';

export const ModulesPage = () => {
  const { t } = useTranslation('pages/home/home/classes/modules/modulesPage');
  const { getModules, navigateToHome } = useModules();
  const location = useLocation();

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['getModules'],
    queryFn: getModules,
    networkMode: 'always',
    retry: false,
  });

  const { name } = getClassStorage();

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: location.pathname,
      title: PAGE_NAMES.modules,
    });
  }, [location.pathname]);

  return (
    <AnimatePresence mode='wait'>
      <HomeTemplate
        childrenClassname='!px-0'
        layoutClassname='!bg-secondary-surface-100'
      >
        <TabNavigation />
        <TabLayout
          className='flex flex-1 flex-col'
          isLoading={isLoading || isPending}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              delay: 0.3,
              duration: 0.5,
              type: 'spring',
              stiffness: 200,
              damping: 5,
            }}
          >
            <MdChevronLeft
              size={24}
              className='mb-2 flex-shrink-0 cursor-pointer text-secondary-buttons-500'
              onClick={navigateToHome}
            />
          </motion.div>

          <motion.div
            initial={{ y: '-130%', opacity: 0 }} // Start off-screen to the left
            animate={{ y: 0, opacity: 1 }} // Slide to the original position
            transition={{
              duration: 0.3,
              ease: 'easeInOut',
            }}
          >
            <Typography variant='h1'>
              {t('modules', { courseName: name })}
            </Typography>
            <Typography variant='span'>{t('openSessions')}</Typography>
          </motion.div>

          <ModulesList modules={data?.modules} />
        </TabLayout>
      </HomeTemplate>
    </AnimatePresence>
  );
};
