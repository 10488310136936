import { default as RSelect } from 'react-select';
import { BasicField } from '..';
import { FieldValues } from 'react-hook-form';
import { InputProps } from '@forms/index';
import { useTranslation } from 'react-i18next';
import { MdOutlineKeyboardArrowDown } from 'react-icons/md';

interface SelectComponentProps<T extends FieldValues> extends InputProps<T> {
  options: SelectProps[];
  textLabel: string;
}

export interface SelectProps {
  value: string | number;
  label: string;
}

const Select = <T extends FieldValues>({
  rules,
  containerStyle,
  control,
  name,
  options,
  textLabel,
  ...rest
}: SelectComponentProps<T>) => {
  const { t } = useTranslation('components/atoms/select');

  return (
    <BasicField
      control={control}
      name={name}
      rules={rules}
      containerStyle={containerStyle}
      render={({ field: { onChange, value, ref }, fieldState: { error } }) => (
        <div className='text-left'>
          <span className='text-primary-400'>{textLabel}</span>
          <RSelect
            onChange={val => onChange(val?.value)}
            value={options.find(c => c.value === value)}
            placeholder={t('placeholder')}
            options={options}
            components={{
              DropdownIndicator: () => (
                <MdOutlineKeyboardArrowDown
                  size={24}
                  className='text-[#14171b]'
                />
              ),
            }}
            styles={{
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? '#F0F0FC'
                  : state.isFocused
                    ? '#F0F0FC'
                    : '#FBFBFD',
                '&:active': {
                  backgroundColor: '#DCCAED',
                },
              }),
            }}
            classNames={{
              control: () =>
                'border-primary-200 px-2 h-12 shadow-inner focus-within:border-primary-400 hover:border-primary-400 active:border-primary-400 focus-within:shadow-inner !border-primary-200 disabled:border-primary-200 !rounded-xl',
              indicatorSeparator: () => 'hidden',
              valueContainer: () => '!px-0 !text-secondary-text-300',
              menu: () => '!border-primary-200 !rounded-xl',
              option: () => '!text-secondary-text-300',
              menuList: () =>
                'rounded-xl border border-primary-200 shadow-general !p-0',
            }}
            ref={ref}
            {...rest}
          />
          <div className='h-4'>
            <span className='text-secondary-semantic-error-400'>
              {error?.message}
            </span>
          </div>
        </div>
      )}
    />
  );
};

export default Select;
