export const PAGE_NAMES = {
  login: 'Login Page',
  signupClassCodePage: 'Onboarding Class Code Page',
  signupUserPage: 'Onboarding User Page',
  signupParentsPage: 'Onboarding Parents Page',
  signupAvatarPage: 'Onboarding Avatar Page',
  forgotPassword: 'Forgot Password Page',
  resetPassword: 'Reset Password Page',
  home: 'Home Page',
  shop: 'Shop Page',
  shopAvatars: 'Shop Avatars Page',
  shopCollectibles: 'Shop Collectibles Page',
  shopPurchased: 'Shop Purchased Page',
  shopPurchasedAvatars: 'Shop Purchased Avatars Page',
  shopPurchasedCollectibles: 'Shop Purchased Collectibles Page',
  profile: 'Profile Page',
  prayerGame: 'Prayer Game Page',
  modules: 'Modules Page',
  retos: 'Retos Page',
  retosAnswer: 'Retos Answer Page',
  retosResponses: 'Retos Responses Page',
  retoResponseDetails: 'Reto Response Details Page',
  myProgress: 'My Progress Page',
  students: 'Students Page',
  lessons: 'Lessons Page',
  lessonSummary: 'Lesson Summary Page',
  activity: 'Activity Page',
  profileDetails: 'Profile Details Page',
  profileDetailsEditName: 'Profile Edit Name Page',
  profileDetailsEditParents: 'Profile Edit Parents Page',
  changePassword: 'Change Password Page',
  profileAvatar: 'Select Avatar Page',
};
