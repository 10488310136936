import React, { useRef, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { itemTypes } from '@domain/constants';
import { ANSWER_STATUS } from '@domain/constants/enums/states/States';

interface DropZoneProps {
  onDrop: (word: string) => void;
  onClick: () => void;
  isFilled: boolean;
  status: ANSWER_STATUS | null;
  isSubmitted: boolean;
  children: React.ReactNode;
}

export const DropZone: React.FC<DropZoneProps> = ({
  onDrop,
  onClick,
  isFilled,
  status,
  isSubmitted,
  children,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: itemTypes.WORD,
    drop: (item: { word: string }) => onDrop(item.word),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }), [onDrop]);

  useEffect(() => {
    const handleCustomDrop = (e: CustomEvent) => {
      onDrop(e.detail.word);
    };

    ref.current?.addEventListener('drop', handleCustomDrop as EventListener);

    return () => {
      ref.current?.removeEventListener('drop', handleCustomDrop as EventListener);
    };
  }, [onDrop]);

  drop(ref);

  let className = "drop-zone inline-flex items-center justify-center text-sm sm:text-base rounded-xl ";
  
  if (isFilled) {
    className += "px-3 py-1 sm:px-4 sm:py-2 bg-base-100 border border-primary-200 ";
  } else {
    className += "bg-gray-200 ";
  }

  if (isSubmitted) {
    className += status === ANSWER_STATUS.correct 
      ? "bg-secondary-semantic-success-400 text-secondary-text-100" 
      : "bg-secondary-semantic-error-400 text-secondary-text-100";
  } else if (isOver && !isFilled) {
    className += "bg-blue-100";
  }

  return (
    <div
      ref={ref}
      className={className}
      style={{
        minWidth: '4rem',
        height: '2rem',
        margin: '0 2px',
        display: 'inline-flex',
        verticalAlign: 'middle',
      }}
      onClick={isFilled ? onClick : undefined}
    >
      {children}
    </div>
  );
};

export default DropZone;