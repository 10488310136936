import { BottomCard } from '@components/atoms';
import {
  ChallengeContent,
  SlideControls,
  SlideControlsProps,
} from '@components/organisms';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { UserRole, Reto } from '@domain/constants';

interface ChallengeSlideProps extends SlideControlsProps {
  challenge: Reto;
}

export const ChallengeSlide = ({
  nextButtonText,
  onClickNext,
  challenge,
}: ChallengeSlideProps) => {
  const role = useSelector(UserSelectors.getRole);

  return (
    <div className='flex flex-col justify-between pt-[100px]'>
      <BottomCard containerStyles='bg-secondary-surface-200 shadow-inner justify-between !p-6 overflow-hidden'>
        <div className='flex h-[40vh] flex-1 flex-col justify-between pb-12'>
          <ChallengeContent challenge={challenge} />
          {role === UserRole.CATECHIST ? (
            <div className='fixed bottom-4 left-0 right-0 z-10 mt-4 flex w-full items-center justify-center'>
              <SlideControls
                onClickNext={onClickNext}
                nextButtonText={nextButtonText}
              />
            </div>
          ) : null}
        </div>
      </BottomCard>
    </div>
  );
};
