import {
  PUBLIC_ROUTES_PATHS,
  PROTECTED_ROUTES_PATHS,
} from '@navigation/routes/RoutesPaths';
import {
  LoginPage,
  ActivityPage,
  SignupController,
  WelcomeBackPage,
  SplashPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  HomePage,
  ShopPage,
  LessonsPage,
  RetosPage,
  MyProgressPage,
  ModulesPage,
  NotFoundPage,
  DetailsPage,
  EditNamePage,
  ChangePasswordPage,
  SelectAvatarPage,
  ProfilePage,
  EditParentsPage,
  StudentsPage,
  PrayerGamePage,
  PurchasedAvatarsPage,
  PurchasedPage,
  ShopAvatarsPage,
  ShopCollectiblesPage,
  PurchasedCollectiblesPage,
  RetosAnswerPage,
  RetosResponsesPage,
  RetoResponseDetailsPage,
  LessonSummaryPage,
  SplashRedirect,
  GradedRetosPage,
  LeaderboardPage,
} from '@pages/index';
import {
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import ProtectedRoutes from './ProtectedRoutes';
import { useAuth } from '@hooks/useAuth';
import { useTranslation } from 'react-i18next';
import { useEffect, useMemo } from 'react';
import { initializeGA4 } from '@data/utils/googleAnalytics';

export const Navigation = () => {
  const { isAuthenticated } = useAuth();
  const { t } = useTranslation('pages/common/notFoundPage/notFoundPage');

  const MAIN_ROUTES: RouteObject[] = useMemo(
    () => [
      {
        element: <SplashRedirect />,
        children: [
          {
            path: PUBLIC_ROUTES_PATHS.splash,
            element: isAuthenticated ? <WelcomeBackPage /> : <SplashPage />,
          },
          {
            path: PUBLIC_ROUTES_PATHS.login,
            element: <LoginPage />,
          },
          {
            path: PUBLIC_ROUTES_PATHS.forgotPassword,
            element: <ForgotPasswordPage />,
          },
          {
            path: PUBLIC_ROUTES_PATHS.resetPassword,
            element: <ResetPasswordPage />,
          },
        ],
      },
      {
        path: PUBLIC_ROUTES_PATHS.signup,
        element: <SignupController />,
      },
      {
        path: '*',
        element: (
          <NotFoundPage
            descriptionOne={t('descriptions.notFound')}
            descriptionTwo={t('goBack')}
          />
        ),
      },
      {
        element: <ProtectedRoutes />,
        errorElement: (
          <NotFoundPage
            descriptionOne={t('descriptions.general')}
            descriptionTwo={t('goBack')}
          />
        ),
        children: [
          {
            path: PROTECTED_ROUTES_PATHS.home,
            element: <HomePage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.modules,
            element: <ModulesPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.retos,
            element: <RetosPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.gradedRetos,
            element: <GradedRetosPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.myProgress,
            element: <MyProgressPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.activity,
            element: <ActivityPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.leaderboard,
            element: <LeaderboardPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.shop,
            element: <ShopPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.shopAvatars,
            element: <ShopAvatarsPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.shopCollectibles,
            element: <ShopCollectiblesPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.shopPurchased,
            element: <PurchasedPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.shopPurchasedAvatars,
            element: <PurchasedAvatarsPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.shopPurchasedCollectibles,
            element: <PurchasedCollectiblesPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.profile,
            element: <ProfilePage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.lessons,
            element: <LessonsPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.profileDetails,
            element: <DetailsPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.profileDetailsEditName,
            element: <EditNamePage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.changePassword,
            element: <ChangePasswordPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.profileAvatar,
            element: <SelectAvatarPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.profileDetailsEditParents,
            element: <EditParentsPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.students,
            element: <StudentsPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.prayerGame,
            element: <PrayerGamePage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.retosAnswer,
            element: <RetosAnswerPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.retosResponses,
            element: <RetosResponsesPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.retoResponseDetails,
            element: <RetoResponseDetailsPage />,
          },
          {
            path: PROTECTED_ROUTES_PATHS.lessonSummary,
            element: <LessonSummaryPage />,
          },
        ],
      },
    ],
    [isAuthenticated, t]
  );

  const router = useMemo(
    () => createBrowserRouter([...MAIN_ROUTES]),
    [MAIN_ROUTES]
  );

  useEffect(() => {
    initializeGA4();
  }, []);

  return <RouterProvider router={router} />;
};
