import { ActivitySubtype, ActivityType } from 'src/domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';

interface RenderImageSlideProps {
  title: string;
  imageURL: string;
  type: ActivityType;
  subtype: ActivitySubtype;
}

export const RenderImageSlide = ({
  title,
  imageURL,
  type,
  subtype,
}: RenderImageSlideProps) => {
  return (
    <SlideContentWrapper title={title} type={type} subtype={subtype}>
      <img src={imageURL!} alt={title} />
    </SlideContentWrapper>
  );
};
