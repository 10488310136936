import { ReactNode } from 'react';
import { Loader } from '@components/atoms';

interface TabLayoutProps {
  children: ReactNode;
  className?: string;
  isLoading?: boolean;
}

export const TabLayout = ({
  children,
  className,
  isLoading,
}: TabLayoutProps) => {
  return (
    <div
      className={`relative z-10 flex-1 bg-secondary-surface-200 p-4 py-6 ${className}`}
    >
      {isLoading ? (
        <div className='flex-1 place-content-center'>
          <Loader />
        </div>
      ) : (
        children
      )}
    </div>
  );
};
