import { Card, Typography } from '@components/atoms';
import { GameController } from '@assets/img';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { UserRole } from '@domain/constants';
import { PROTECTED_ROUTES_PATHS } from 'src/navigation/routes/RoutesPaths';
import { useNavigate } from 'react-router-dom';

export const GameCard = () => {
  const { t } = useTranslation('components/molecules/gameCard');
  const role = useSelector(UserSelectors.getRole);
  const displayCard = role === UserRole.STUDENT ? 'block' : 'hidden ';

  const navigate = useNavigate();
  const navigateToPrayerGame = () =>
    navigate(PROTECTED_ROUTES_PATHS.prayerGame);

  return (
    <Card
      className={`relative mb-4 overflow-hidden bg-gradient-to-r from-[#00ABAB] to-secondary-phasetwo-500 !shadow-none [&_*]:!text-secondary-buttons-600 ${displayCard}`}
      onClick={navigateToPrayerGame}
    >
      <div className='flex flex-col gap-2'>
        <Typography
          variant='span'
          className='text-[16px] font-medium leading-[20px]'
        >
          {t('title')}
        </Typography>
        <Typography variant='span'>{t('description')}</Typography>
        <Typography variant='span' className='mt-2 font-bold underline'>
          {t('letsPlay')}
        </Typography>
      </div>
      <img
        src={GameController}
        className='absolute -bottom-10 right-0 h-28'
      ></img>
    </Card>
  );
};
