import { ToastContainer, ToastContainerProps } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  TOAST_NOTIFICATION_DURATION,
  TOAST_NOTIFICATION_POSITION,
} from '@domain/constants';

const toastNotificationsConfiguration: ToastContainerProps = {
  position: TOAST_NOTIFICATION_POSITION,
  autoClose: TOAST_NOTIFICATION_DURATION,
  hideProgressBar: true,
  newestOnTop: false,
  closeOnClick: true,
  rtl: false,
  draggable: true,
  pauseOnHover: false,
  closeButton: false,
  toastClassName:
    'bg-secondary-surface-200 mx-4 mt-8 border border-primary-200 rounded-lg p-2',
};

export const ToastNotificationsProvider = () => {
  return <ToastContainer {...toastNotificationsConfiguration} />;
};
