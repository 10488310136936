import { Outlet, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { PUBLIC_ROUTES_PATHS } from './RoutesPaths';
import { UserSelectors } from '@store/slices/userSlice';
import { UserRole, AUTH_TOKEN_STORAGE_KEY } from '@domain/constants';

const useAuth = () => {
  const role = useSelector(UserSelectors.getRole);
  const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);

  return authToken || (role && role !== UserRole.NOT_LOGGED);
};

const ProtectedRoutes = () => {
  const isAuth = useAuth();

  return isAuth ? <Outlet /> : <Navigate to={PUBLIC_ROUTES_PATHS.login} />;
};

export default ProtectedRoutes;
