import { Header, HeaderProps } from '@components/molecules';
import { Typography } from '@components/atoms';
import { MdArrowBack, MdClose, MdMenu } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { UserRole } from '@domain/constants';

export interface SlideHeaderProps extends Omit<HeaderProps, 'children'> {
  title: string;
  subtitle: string;
  clickCloseButton?: () => void | null;
  clickBackButton?: () => void;
  clickSlideButton?: () => void;
}

export const SlideHeader = ({
  title,
  subtitle,
  containerStyles,
  style,
  clickCloseButton,
  clickBackButton,
  clickSlideButton,
}: SlideHeaderProps) => {
  const userRole = useSelector(UserSelectors.getRole);

  const iconClassName = 'flex-shrink-0 cursor-pointer';

  const leftButton = clickBackButton ? (
    <MdArrowBack
      className={iconClassName}
      size={24}
      style={style}
      onClick={clickBackButton}
    />
  ) : clickCloseButton && userRole === UserRole.CATECHIST ? (
    <MdClose
      className={iconClassName}
      size={24}
      style={style}
      onClick={clickCloseButton}
    />
  ) : null;

  const rightButton =
    clickSlideButton && userRole === UserRole.CATECHIST ? (
      <MdMenu
        className={iconClassName}
        size={24}
        style={style}
        onClick={clickSlideButton}
      />
    ) : null;

  return (
    <Header
      containerStyles={`fixed top-0 z-20 flex justify-between items-center gap-2 ${containerStyles}`}
      style={style}
    >
      {leftButton ? leftButton : <span className='w-6'></span>}
      <div className='mx-4 grid w-full place-items-center [&_*]:w-full [&_*]:text-center'>
        <Typography variant='h1' style={style} className='line-clamp-1'>
          {title}
        </Typography>
        <Typography
          variant='span'
          style={style}
          className=' line-clamp-2 text-[16px] font-medium leading-[20px]'
        >
          {subtitle}
        </Typography>
      </div>
      {rightButton ? rightButton : <span className='w-6'></span>}
    </Header>
  );
};
