import { useState } from 'react';
import { Button, Input, Typography } from '@components/atoms';
import { useForm } from 'react-hook-form';
import { InputType } from '@domain/constants';
import { useRetos } from '@hooks/index';
import { numberPattern } from '@data/utils';
import { useTranslation } from 'react-i18next';
import { Modal } from '@components/molecules';
import { useParams } from 'react-router-dom';

type Inputs = {
  points: number;
};

interface ModalRetoGradingProps {
  studentName: string;
  points: number;
}

export const ModalRetoGrading = ({
  studentName,
  points,
}: ModalRetoGradingProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { gradeRetoResponseMutation, navigateToRetosResponses } = useRetos();
  const { retoId } = useParams();

  const { t } = useTranslation(
    'pages/home/home/classes/retos/retoResponseDetailsPage'
  );

  const handleGradingSubmit = async (inputs: Inputs) => {
    try {
      await gradeRetoResponseMutation.mutateAsync({
        points: inputs.points,
      });

      setIsOpen(false);

      navigateToRetosResponses(retoId);
    } catch (error) {
      throw error;
    }
  };

  const {
    handleSubmit,
    control,
    formState: { isValid, isSubmitting },
  } = useForm<Inputs>({
    defaultValues: {
      points: 0,
    },
    mode: 'onChange',
  });

  return !isOpen ? (
    <Button
      style='primary'
      text={t('buttons.markAsCompleted')}
      type='button'
      onClick={() => setIsOpen(true)}
    />
  ) : (
    <Modal
      open={isOpen}
      title={t('gradeModal.title')}
      onClose={() => setIsOpen(false)}
    >
      <div className='grid gap-4'>
        <Typography variant='span' className='font-bold'>
          {t('gradeModal.student', { fullName: studentName })}
        </Typography>

        <Typography variant='span'>
          {t('gradeModal.message', { points: points.toString() })}
        </Typography>

        <form id='grade-form' onSubmit={handleSubmit(handleGradingSubmit)}>
          <Input
            leftLabel={t('gradeModal.inputs.leftLabel')}
            type={InputType.number}
            control={control}
            name='points'
            rules={{
              required: true,
              min: {
                value: 0,
                message: t('gradeModal.errors.positiveValues'),
              },
              max: {
                value: points,
                message: t('gradeModal.errors.maxPoints', {
                  maxPoints: points,
                }),
              },
              pattern: {
                value: numberPattern,
                message: t('gradeModal.errors.wholeNumber'),
              },
            }}
          />
        </form>

        <Button
          style='primary'
          text={t('gradeModal.buttons.gradeNow')}
          type='submit'
          form='grade-form'
          disabled={!isValid}
          loading={isSubmitting}
        />

        <Button
          type='button'
          style='secondary'
          text={t('gradeModal.buttons.cancel')}
          onClick={() => setIsOpen(false)}
        />
      </div>
    </Modal>
  );
};
