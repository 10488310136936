import {
  LESSON_BACKGROUND_COLOR_STORAGE_KEY,
  LESSON_TITLE_STORAGE_KEY,
  LESSON_DESCRIPTION_STORAGE_KEY,
  LESSON_TEXT_COLOR_STORAGE_KEY,
} from '@domain/constants';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);
const surfaceColor = fullConfig.theme.colors.secondary.surface[200];
const textColor = fullConfig.theme.colors.secondary.text[300];

export const setLessonStorage = (
  title: string,
  description: string,
  color: string,
  backgroundColor: string
) => {
  localStorage.setItem(LESSON_TITLE_STORAGE_KEY, title);
  localStorage.setItem(LESSON_DESCRIPTION_STORAGE_KEY, description);
  localStorage.setItem(LESSON_TEXT_COLOR_STORAGE_KEY, color);
  localStorage.setItem(LESSON_BACKGROUND_COLOR_STORAGE_KEY, backgroundColor);
};

export const getLessonStorage = () => {
  const title: string = localStorage.getItem(LESSON_TITLE_STORAGE_KEY) || '';
  const description: string =
    localStorage.getItem(LESSON_DESCRIPTION_STORAGE_KEY) || '';
  const color: string =
    localStorage.getItem(LESSON_TEXT_COLOR_STORAGE_KEY) || textColor;
  const backgroundColor: string =
    localStorage.getItem(LESSON_BACKGROUND_COLOR_STORAGE_KEY) || surfaceColor;

  return { title, description, color, backgroundColor };
};

export const removeLessonStorage = () => {
  localStorage.removeItem(LESSON_TITLE_STORAGE_KEY);
  localStorage.removeItem(LESSON_DESCRIPTION_STORAGE_KEY);
  localStorage.removeItem(LESSON_TEXT_COLOR_STORAGE_KEY);
  localStorage.removeItem(LESSON_BACKGROUND_COLOR_STORAGE_KEY);
};
