import { Controller, ControllerProps, FieldValues } from 'react-hook-form';
import { InputProps } from '@forms/index';

interface BasicFieldProps<T extends FieldValues>
  extends Omit<InputProps<T>, 'control'>,
    ControllerProps<T> {}

const BasicField = <T extends FieldValues>({
  containerStyle,
  rules,
  control,
  name,
  render,
  ...props
}: BasicFieldProps<T>) => {
  return (
    <div style={containerStyle}>
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={render}
        {...props}
      />
    </div>
  );
};

export default BasicField;
