import { Input } from '@components/atoms';
import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { SlideAnswerSubmitted } from '../SlideAnswerSubmitted/SlideAnswerSubmitted';
import { everythingPattern, oneWordPattern } from '@data/utils';
import { ActivitySubtype, InputType } from '@domain/constants';
import { SendButtonContext } from '@context/index';

interface SlideTextInputProps {
  questionText?: string;
  onSubmit: (answer: string) => void;
  activitySubtype?: ActivitySubtype;
}

type Inputs = {
  response: string;
};

export const SlideTextInput = ({
  onSubmit,
  activitySubtype,
}: SlideTextInputProps) => {
  const { t } = useTranslation('components/molecules/slideTextInput');

  const { setSendButtonState, sendButtonState } = useContext(SendButtonContext);

  const handleSubmitResponse = async (data: Inputs) => {
    try {
      setSendButtonState(state => ({
        ...state,
        loading: true,
      }));
      await onSubmit(data.response);
      setSendButtonState({
        loading: false,
        disabled: true,
        answered: true,
      });
      reset();
    } catch (error) {
      console.log(error);
      setSendButtonState({
        loading: false,
        disabled: false,
        answered: false,
      });
      throw error;
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm<Inputs>({
    defaultValues: {
      response: '',
    },
    mode: 'onChange',
  });

  useEffect(() => {
    setSendButtonState(state => ({
      ...state,
      disabled: !isValid,
    }));
  }, [isValid, setSendButtonState]);

  return (
    <div>
      <form id='slide-form' onSubmit={handleSubmit(handleSubmitResponse)}>
        <Input
          control={control}
          label=''
          name='response'
          type={InputType.text}
          disabled={sendButtonState.answered}
          rules={{
            required: {
              value: true,
              message: t('errors.required'),
            },
            pattern: {
              value:
                activitySubtype === ActivitySubtype.WORD_CLOUD
                  ? oneWordPattern
                  : everythingPattern,
              message: t('errors.oneWord'),
            },
          }}
        />
      </form>

      {sendButtonState.answered && <SlideAnswerSubmitted />}
    </div>
  );
};
