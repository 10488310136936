import {AnimatePresence, motion} from 'framer-motion';

export const PathRight = ({ fillColor, animate, index }: { fillColor: string, animate: boolean, index: number }) => {
  return (
    <svg
      width='330'
      height='120'
      viewBox='0 0 330 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <filter id='normal-shadow' x='-50%' y='-50%' width='200%' height='200%'>
          <feDropShadow
            dx='0'
            dy='4'
            stdDeviation='4'
            floodColor='rgba(0,0,0,0.25)'
          />
        </filter>
        <filter id='inset-shadow' x='-50%' y='-50%' width='200%' height='200%'>
          <feComponentTransfer in='SourceAlpha'>
            <feFuncA type='table' tableValues='1 0' />
          </feComponentTransfer>
          <feGaussianBlur stdDeviation='4' />
          <feOffset dx='0' dy='4' result='offsetblur' />
          <feFlood floodColor='rgba(0,0,0,0.25)' result='color' />
          <feComposite in2='offsetblur' operator='in' />
          <feComposite in2='SourceAlpha' operator='in' />
          <feMerge>
            <feMergeNode in='SourceGraphic' />
            <feMergeNode />
          </feMerge>
        </filter>
      </defs>
      <AnimatePresence mode='wait'>
      <motion.path
        d='M0 0C0 39.07 28.99 71.34 66.59 76.46V76.71H68.56C71.35 77.02 74.18 77.19 77.06 77.19H247.99C268.36 80.26 283.97 97.86 283.97 119.12H329.16C329.16 80.05 300.17 47.78 262.57 42.66V42.41H260.6C257.81 42.1 254.98 41.93 252.11 41.93H81.16C60.8 38.86 45.19 21.26 45.19 0H0Z'
        style={{ fill: fillColor }}
        initial={ animate ? { clipPath: 'inset(0 400% 0 0)' } : {}} // Start from top (empty)
        animate={animate ? { clipPath: 'inset(0 0 0 0)' } : {}} // Fill from top to bottom
        transition={{ duration: 1.0, delay: (index == 0) ? 0 : index - 0.4 }}
        filter='url(#normal-shadow)'
      />
      </AnimatePresence>
    </svg>
  );
};
