import { Typography } from '@components/atoms';
import { MdCheck, MdStar } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import { STATES_STR } from '@domain/constants';

interface StatusPillProps {
  state: STATES_STR;
}

export const StatusPill = ({ state }: StatusPillProps) => {
  const { t } = useTranslation('components/molecules/statusPill');

  const className = {
    [STATES_STR.finished]: '!text-secondary-semantic-success-400',
    [STATES_STR.inProgress]: '!text-secondary-semantic-warning-400',
    [STATES_STR.notStarted]: '!text-secondary-text-200',
  };

  const Icon =
    state === STATES_STR.inProgress
      ? MdStar
      : state === STATES_STR.finished
        ? MdCheck
        : null;

  const text =
    state === STATES_STR.inProgress
      ? t('start')
      : state === STATES_STR.finished
        ? t('completed')
        : t('comingSoon');

  return (
    <div className='flex w-fit items-center justify-center gap-1 rounded-full bg-primary-100 px-2'>
      {Icon && (
        <Icon size={14} className={`flex-shrink-0 ${className[state]}`} />
      )}

      <Typography
        variant='span'
        className={`text-[10px] font-bold ${className[state]}`}
      >
        {text}
      </Typography>
    </div>
  );
};
