import { useCallback, useEffect, useState } from 'react';
import { usePubNub } from 'pubnub-react';
import {
  ActivityPayload,
  ActivitySubtype,
  SlideActionType,
  UseActivityProps,
  CustomMessageEvent,
  SlideBodyType,
} from '@domain/constants';
import { StudentsSelectors, UserSelectors } from '@store/slices';
import { useSelector } from 'react-redux';

export const useOpenQuestion = ({ channel, slideId }: UseActivityProps) => {
  const [responses, setResponse] = useState<Record<string, string>[]>([]);
  const students = useSelector(StudentsSelectors.getWaitingRoom);

  const pubnub = usePubNub();

  const userId = useSelector(UserSelectors.getId);

  const handleOpenQuestionResponse = useCallback(
    (event: CustomMessageEvent) => {
      const body = event.message.body;

      if (
        body.type === SlideBodyType.ACTIVITY &&
        body.activity_type === ActivitySubtype.OPEN_QUESTION &&
        body.activity_id === slideId
      ) {
        const studentId = students.find(
          student => student.id.toString() === event.publisher
        );
        const studentName = `${studentId?.firstName} ${studentId?.lastName}`;

        setResponse(responses => [
          ...responses,
          {
            username: studentName,
            response: body.answer!,
          },
        ]);
      }
    },
    [slideId, students]
  );
  const sendOpenQuestionResponse = async (answer: string) => {
    if (answer) {
      const messagePayload: ActivityPayload = {
        uuid: userId,
        action: SlideActionType.ACTIVITY,
        body: {
          type: SlideBodyType.ACTIVITY,
          activity_type: ActivitySubtype.OPEN_QUESTION,
          answer,
          activity_id: slideId,
        },
        subscribed_channel: channel,
        actual_channel: channel,
      };

      await pubnub.publish({
        channel: channel,
        message: messagePayload,
      });
    }
  };

  useEffect(() => {
    const listenerParams = {
      message: handleOpenQuestionResponse,
    };

    pubnub.addListener(listenerParams);
    pubnub.subscribe({ channels: [channel] });

    return () => {
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, channel, handleOpenQuestionResponse]);

  useEffect(() => {
    setResponse([]);
  }, [slideId]);

  return {
    responses,
    sendOpenQuestionResponse,
  };
};
