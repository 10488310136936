import { WebClientRest } from '@data/api/clients';
import { AUTH_TOKEN_STORAGE_KEY } from '@domain/constants';

class ShopApi {
  private client: WebClientRest;

  constructor(client: WebClientRest) {
    this.client = client;
  }

  getFreeAvatars() {
    return this.client.get(`/shop/free_avatars`, {
      headers: {
        Accept: 'application/json',
      },
    });
  }

  getShopLanding(source: string) {
    return this.client.get(`/shop/landing/${source}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getShopItemsByType(type: 'avatar' | 'collectible') {
    return this.client.get(`/shop/${type}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getShopPurchasedItemsByType(type: 'avatar' | 'collectible') {
    return this.client.get(`/shop/purchased/${type}`, {
      headers: {
        Accept: 'application/json',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  purchaseShopItem(id: string) {
    return this.client.post(
      `/shop/${id}/purchase`,
      {},
      {
        headers: {
          Accept: 'application/json',
          Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }
}

export { ShopApi };
