import { ShopItem } from '@components/molecules';
import {
  ModalConfirmation,
  ModalConfirmationProps,
} from '@components/organisms';
import { useState } from 'react';
import { TacoIcon } from '@assets/svg';
import { BorderContainer, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { useProfile, useShop } from '@hooks/index';
import { NotificationsDispatcher } from '@data/utils';
import { ToastNotificationType } from '@domain/constants';

interface ModalAvatarPurchaseProps extends ModalConfirmationProps {
  id: number;
  image: string;
  name: string;
  price: number;
  studentPoints: number;
  refetch: () => void;
  setAvatar: (value: Record<string, string | number> | null) => void;
}

export const ModalAvatarPurchase = ({
  id,
  image,
  name,
  price,
  studentPoints,
  handleCancel,
  refetch,
  open,
  setAvatar,
}: ModalAvatarPurchaseProps) => {
  const [profileCheckbox, setProfileCheckbox] = useState<boolean>(false);

  const disabled = Number(price) > Number(studentPoints);

  const { t } = useTranslation('components/organisms/modalAvatarPurchase');

  const { purchaseShopItemMutation } = useShop();
  const { editAvatarMutation } = useProfile();

  const handlePurchaseAvatar = async () => {
    try {
      await purchaseShopItemMutation.mutateAsync(id);

      if (profileCheckbox) {
        await editAvatarMutation.mutateAsync(id);
      }

      NotificationsDispatcher({
        type: ToastNotificationType.success,
        title: t('toastNotifications.success.title'),
        message: t('toastNotifications.success.message'),
      });

      setAvatar(null);
      refetch();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ModalConfirmation
      open={open}
      handleConfirmDisabled={disabled}
      title={t('title')}
      handleConfirm={handlePurchaseAvatar}
      handleConfirmLoading={
        purchaseShopItemMutation.isPending || editAvatarMutation.isPending
      }
      handleCancel={handleCancel}
    >
      <div className='flex w-full flex-col items-center '>
        <ShopItem id={id} name={name} avatarImage={image} price={null} />
        <BorderContainer containerStyles='bg-secondary-surface-100 flex justify-center gap-2 py-4 mt-4 items-center'>
          <img src={TacoIcon} alt='taco icon' />

          <Typography variant='span' className='font-bold'>
            {price === 0 ? t('free') : price?.toString()}
          </Typography>
        </BorderContainer>
        <div className='form-control mb-2 mt-4 self-start'>
          <label className='label flex cursor-pointer gap-2'>
            <input
              type='checkbox'
              checked={profileCheckbox}
              onChange={() => setProfileCheckbox(!profileCheckbox)}
              className='checkbox [--chkbg:#6E2AB1] [--chkfg:#FBFBFB]'
            />
            <Typography variant='span'>{t('setAsProfile')}</Typography>
          </label>
        </div>
      </div>
    </ModalConfirmation>
  );
};
