import { useTranslation } from 'react-i18next';
import { useLogout, useProfile } from '@hooks/index';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { UserRole } from '@domain/constants';
import { Button, Loader } from '@components/atoms';
import { MdChevronRight } from 'react-icons/md';

interface Button {
  id: string;
  text: string;
  onClick: () => void;
  isLoading: boolean;
}

export const ProfileButtonsNavigation = () => {
  const { navigateToProfileDetails, navigateToChangePassword } = useProfile();
  const { logoutQuery } = useLogout();
  const { refetch: logoutRefetch, isLoading: logoutLoading } = logoutQuery;

  const userRole = useSelector(UserSelectors.getRole);

  const { t } = useTranslation('pages/home/profile/profilePage');

  const getUserButtons = (): Button[] => {
    const buttons: Button[] = [
      {
        id: 'changePassword',
        text: t('buttons.changePassword'),
        isLoading: false,
        onClick: navigateToChangePassword,
      },
      {
        id: 'logOut',
        text: t('buttons.logOut'),
        isLoading: logoutLoading,
        onClick: logoutRefetch,
      },
    ];

    if (userRole === UserRole.STUDENT) {
      buttons.unshift({
        id: 'personalDetails',
        text: t('buttons.personalDetails'),
        onClick: navigateToProfileDetails,
        isLoading: false,
      });
    }

    return buttons;
  };

  const BUTTONS = getUserButtons();

  return (
    <div className='flex flex-col gap-4 pt-10'>
      {BUTTONS.map(({ id, text, onClick, isLoading }) => (
        <Button
          key={id}
          text={text}
          onClick={onClick}
          disabled={isLoading}
          iconRight={
            isLoading ? (
              <Loader
                containerClassName='!flex-none'
                className='w-6 flex-shrink-0 text-secondary-buttons-500'
              />
            ) : (
              <MdChevronRight size={24} className='flex-shrink-0' />
            )
          }
          type='button'
          style='profile'
        />
      ))}
    </div>
  );
};
