import { CardList, GradedRetoCard } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { MdTask } from 'react-icons/md';

interface GradedRetosListProps {
  retos: any[];
}

export const GradedRetosList = ({ retos }: GradedRetosListProps) => {
  const { t } = useTranslation('components/organisms/gradedRetosList');

  return (
    <CardList
      data={retos}
      CardComponent={GradedRetoCard}
      getKey={(reto: any) => reto.id}
      getCardProps={(reto: any) => ({
        reto,
      })}
      icon={<MdTask size={24} className='flex-shrink-0 text-primary-400' />}
      label={t('emptyState')}
    />
  );
};
