import React from 'react';
import { PrayerText, DropZone } from '@components/atoms';
import { ANSWER_STATUS } from '@domain/constants/enums/states/States';

interface PrayerTextWithDropZonesProps {
  text: string;
  onDrop: (index: number) => (word: string) => void;
  filledWords: (string | null)[];
  onRemoveWord: (index: number) => void;
  answerStatus: (ANSWER_STATUS | null)[];
  blankPositions: number[];
  isSubmitted: boolean;
}

export const PrayerTextWithDropZones: React.FC<PrayerTextWithDropZonesProps> = ({
  text,
  onDrop,
  filledWords,
  onRemoveWord,
  answerStatus,
  blankPositions,
  isSubmitted,
}) => {
  const renderDropZone = (index: number) => {

    return (
      <span className="inline-block my-1 mx-0.5 sm:my-0">
        <DropZone
          onDrop={onDrop(index)}
          onRemove={() => onRemoveWord(index)}
          status={answerStatus[index]}
          isSubmitted={isSubmitted}
          isFilled={!!filledWords[index]}
          onClick={() => onRemoveWord(index)}
        >
          {filledWords[index] || ''}
        </DropZone>
      </span>
    );
  };

  return (
    <div className="space-x-2">
      <PrayerText text={text} renderDropZone={renderDropZone} blankPositions={blankPositions} />
    </div>
  );
};