import { WebClientRest } from '@data/api/clients';

class SetupApi {
  private client: WebClientRest;

  constructor(client: WebClientRest) {
    this.client = client;
  }

  getPointsValues() {
    return this.client.get('/setups', {
      headers: {
        Accept: '*/*',
      },
    });
  }
}

export { SetupApi };
