import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

interface StudentState {
  students: Student[];
}

interface Student {
  id: number;
  firstName: string;
  lastName: string;
  username: string;
  score: string;
  profilePictureURL: string;
  points: Points;
}

interface Points {
  available: number;
  lifetime: number;
}

const initialState: StudentState = {
  students: [],
};

const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    setStudents: (state, action) => {
      state.students = action.payload;
    },
  },
});

export const StudentsSelectors = {
  getStudents: (state: RootState) => state.students.students,
  getWaitingRoom: (state: RootState) => {
    return state.students.students.map(student => ({
      id: student.id,
      firstName: student.firstName,
      lastName: student.lastName,
      profilePictureURL: student.profilePictureURL,
    }));
  },
};

export const { setStudents } = studentsSlice.actions;

export const StudentsReducer = studentsSlice.reducer;
