import { Button, Input, Layout, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { MdChevronLeft } from 'react-icons/md';
import { InputType, PAGE_NAMES, PASSWORD_MIN_LENGTH } from '@domain/constants';
import { passwordRules, trackGA4PageView } from '@data/utils';
import { useForm } from 'react-hook-form';
import { useForgotPassword } from '@hooks/index';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

type Inputs = {
  password: string;
  confirmPassword: string;
};

export const ResetPasswordPage = () => {
  const { t } = useTranslation([
    'pages/auth/forgotPassword/resetPasswordPage',
    'common',
  ]);

  const { navigateToLogin, resetPasswordMutation } = useForgotPassword();
  const { resetCode } = useParams();

  const handleFormSubmit = async (data: Inputs) => {
    try {
      await resetPasswordMutation.mutateAsync({
        token: resetCode!,
        password: data.password,
        confirmPassword: data.confirmPassword,
      });
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    watch,
    trigger,
    formState: { isValid },
  } = useForm<Inputs>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });

  const { password, confirmPassword } = watch();

  useEffect(() => {
    if (confirmPassword) {
      trigger('confirmPassword');
    }
  }, [password, trigger, confirmPassword]);

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: '/reset-password',
      title: PAGE_NAMES.resetPassword,
    });
  }, []);

  return (
    <Layout isFlex containerStyles='justify-between'>
      <div className='flex-1'>
        <MdChevronLeft
          size={24}
          onClick={navigateToLogin}
          className='mb-6 flex-shrink-0 cursor-pointer rounded-full text-secondary-buttons-500'
        />
        <Typography variant='h1' className='mb-2'>
          {t('title')}
        </Typography>
        <Typography variant='span'>{t('description')}</Typography>

        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className='mb-4 mt-6'
          id='reset-password-form'
        >
          <Input
            label={t('inputs.newPassword')}
            type={InputType.password}
            control={control}
            name='password'
            rules={passwordRules(
              t('inputs.errors.invalid', {
                inputName: 'password',
                ns: 'common',
              }),
              t('errors.minLengthPassword', {
                minLength: PASSWORD_MIN_LENGTH,
              }),
              t('inputs.errors.invalid', {
                inputName: 'password',
                ns: 'common',
              })
            )}
          />
          <Input
            label={t('inputs.confirmPassword')}
            type={InputType.password}
            control={control}
            name='confirmPassword'
            rules={{
              required: {
                value: true,
                message: t('errors.confirmPassword'),
              },
              validate: {
                matchPassword: (value: string) =>
                  value === watch('password')
                    ? true
                    : t('errors.confirmPassword'),
              },
            }}
          />
        </form>
      </div>

      <Button
        type='submit'
        text={t('button.saveChanges')}
        style='primary'
        form='reset-password-form'
        disabled={!isValid || resetPasswordMutation.isPending}
        loading={resetPasswordMutation.isPending}
      />
    </Layout>
  );
};
