export const PointsAnalytics = {
  // ACTIONS
  actions: {
    points: 'Points',
  },

  // CATEGORIES
  categories: {
    earnedPoints: 'Earned Points',
  },

  // LABELS
  labels: {
    earnedPoints: ':date; :userID;',
  },
};

export const earnedPointsLabel = (date: string, userId: string) => {
  return PointsAnalytics.labels.earnedPoints
    .replace(':date', date)
    .replace(':userID', userId);
};
