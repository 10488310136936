import { Button, Typography } from '@components/atoms';

interface TitleWithActionProps {
  label: string;
  onClick: () => void;
  buttonText: string;
}

export const TitleWithAction = ({
  label,
  buttonText,
  onClick,
}: TitleWithActionProps) => {
  return (
    <div className='flex items-center justify-between'>
      <Typography variant='h2'>{label}</Typography>
      <Button
        style='link'
        onClick={onClick}
        className='h-fit min-h-fit px-2 py-2'
        text={buttonText}
        type='button'
      />
    </div>
  );
};
