import { ReactNode } from 'react';

export interface HeaderProps {
  children: ReactNode;
  containerStyles?: string;
  style?: React.CSSProperties;
}

export const Header = ({ children, containerStyles, style }: HeaderProps) => {
  return (
    <header
      className={`h-[100px] w-full rounded-b-xl px-6 py-4 shadow-general ${containerStyles}`}
      style={style}
    >
      {children}
    </header>
  );
};
