import { useNavigate, useParams } from 'react-router-dom';
import { WebClientRest } from '@data/api/clients';
import { Api } from '@data/api';
import {
  LESSON_STATES_STR,
  SlideBodyType,
  ToastNotificationType,
} from '@domain/constants';
import { HttpStatusCode } from '@domain/api';
import { NotificationsDispatcher } from 'src/data/utils';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@tanstack/react-query';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { usePubNub } from 'pubnub-react';
import { useCallback, useEffect } from 'react';
import {
  ActivityPayload,
  SlideActionType,
  CustomMessageEvent,
} from '@domain/constants';
import { useDispatch, useSelector } from 'react-redux';
import {
  setExitingModalCloseLesson,
  setWaitingRoom,
  UserSelectors,
} from '@store/slices';

interface CloseLessonParams {
  state: LESSON_STATES_STR;
}

interface useCloseLessonProps {
  channel: string;
}

export const useCloseLesson = ({ channel }: useCloseLessonProps) => {
  const { lessonId, classId, moduleId } = useParams();
  const navigate = useNavigate();

  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });

  const userId = useSelector(UserSelectors.getId);
  const { t } = useTranslation('common');
  const api = new Api(apiInstance);
  const pubnub = usePubNub();
  const dispatch = useDispatch();

  const navigateToLessons = useCallback(() => {
    navigate(
      PROTECTED_ROUTES_PATHS.lessons
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
    );
  }, [classId, moduleId, navigate]);

  const handleCloseLesson = useCallback(
    async (event: CustomMessageEvent) => {
      const actionType = event.message.action;

      if (actionType === SlideActionType.EXIT_LESSON) {
        await dispatch(setExitingModalCloseLesson(true));

        pubnub.unsubscribeAll();
        await navigateToLessons();
        await dispatch(setExitingModalCloseLesson(false));
      }
    },
    [navigateToLessons, pubnub, dispatch]
  );

  const sendCloseLesson = () => {
    try {
      const messagePayload: ActivityPayload = {
        uuid: userId,
        action: SlideActionType.EXIT_LESSON,
        body: {
          type: SlideBodyType.SLIDE,
          slide_id: 0,
        },
        subscribed_channel: channel,
        actual_channel: channel,
      };

      pubnub.publish({
        channel: channel,
        message: messagePayload,
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    const listenerParams = {
      message: handleCloseLesson,
    };

    pubnub.addListener(listenerParams);
    pubnub.subscribe({ channels: [channel] });

    return () => {
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, channel, handleCloseLesson]);

  const closeLesson = async ({ state }: CloseLessonParams) => {
    try {
      const response = await api.lessons.updateLessonState(lessonId!, state);

      if (response.status === HttpStatusCode.OK) {
        dispatch(setWaitingRoom(true));
        sendCloseLesson();
        return response.data;
      }
    } catch (error: unknown) {
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.closeLesson.title'),
        message: t('toastNotifications.errors.closeLesson.message'),
      });
      throw error;
    }
  };

  const closeLessonMutation = useMutation({
    mutationFn: ({ state }: CloseLessonParams) => closeLesson({ state }),
    mutationKey: ['closeLesson', lessonId],
    retry: false,
  });

  return {
    closeLessonMutation,
    navigateToLessons,
  };
};
