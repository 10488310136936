import { useDispatch } from 'react-redux';
import { Api } from '@data/api';
import { WebClientRest } from '@data/api/clients';
import { useNavigate } from 'react-router-dom';
import {
  AUTH_TOKEN_STORAGE_KEY,
  ToastNotificationType,
  LAST_PLAYED_DAILY_PRAYER_GAME_STORAGE_KEY,
  DAILY_PRAYER_GAME_STORAGE_KEY,
} from '@domain/constants';
import { NotificationsDispatcher } from '@data/utils';
import { logoutUser } from '@store/slices';
import { HttpStatusCode } from '@domain/api';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { PUBLIC_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { queryClient } from 'src/main';

export const useLogout = () => {
  const dispatch = useDispatch();
  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });
  const api = new Api(apiInstance);
  const navigate = useNavigate();

  const { t } = useTranslation('common');

  const logout = async () => {
    try {
      const response = await api.users.logoutUser();

      if (response.status === HttpStatusCode.NO_CONTENT) {
        localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
        localStorage.removeItem(LAST_PLAYED_DAILY_PRAYER_GAME_STORAGE_KEY);
        localStorage.removeItem(DAILY_PRAYER_GAME_STORAGE_KEY);
        dispatch(logoutUser());
        queryClient.clear();
        navigate(PUBLIC_ROUTES_PATHS.login);
        return true;
      }
    } catch (error) {
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.logout.title'),
        message: t('toastNotifications.errors.logout.message'),
      });
    }
  };

  const logoutQuery = useQuery({
    queryKey: ['logout'],
    queryFn: logout,
    enabled: false,
    retry: false,
  });

  return {
    logoutQuery,
  };
};
