export const challengesAdapter = (response: any) => {
  const adaptedChallenges = response.retos.map((reto: any) => ({
    id: reto.id,
    title: reto.title,
    description: reto.description,
    type: reto.challenge_type,
    subtitle: reto.subtitle,
    dueDate: reto.due_date,
    reminder: reto.reminder,
    pointsAwarded: reto.points_awarded,
    position: reto.position,
    lesson: {
      title: reto.lesson.title,
      publicUid: reto.lesson.public_uid,
      state: reto.lesson.state,
    },
  }));

  return adaptedChallenges;
};

export const challengeAdapter = (response: any) => {
  const { reto } = response;
  const adaptedChallenge = {
    id: reto.id,
    title: reto.title,
    description: reto.description,
    type: reto.challenge_type,
    subtitle: reto.subtitle,
    dueDate: reto.due_date,
    reminder: reto.reminder,
    pointsAwarded: reto.points_awarded,
    position: reto.position,
    youtubeURL: reto.youtube_url,
    studentResponse: {
      id: reto.student_response.id,
      points: reto.student_response.points,
      imageURL: reto.student_response.image_url,
      state: reto.student_response.state,
      answer: reto.student_response.answer,
    },
  };

  return adaptedChallenge;
};

export const retoResponsesAdapter = (response: any) => {
  const { reto } = response;
  const adaptedRetoResponses = {
    id: reto.id,
    title: reto.title,
    description: reto.description,
    type: reto.challenge_type,
    subtitle: reto.subtitle,
    dueDate: reto.due_date,
    reminder: reto.reminder,
    pointsAwarded: reto.points_awarded,
    position: reto.position,
    retoResponses: reto.reto_responses.map((retoResponse: any) => ({
      id: retoResponse.id,
      points: retoResponse.points,

      state: retoResponse.state,
      student: {
        firstName: retoResponse.student.first_name,
        lastName: retoResponse.student.last_name,
        profilePictureURL: retoResponse.student.profile_picture_url,
      },
    })),
  };

  return adaptedRetoResponses;
};

export const retoResponseAdapter = (response: any) => {
  const { reto } = response;
  const adaptedRetoResponse = {
    id: reto.id,
    points: reto.points,
    answer: reto.answer,
    state: reto.state,
    imageURL: reto.image_url,
    student: {
      firstName: reto.student.first_name,
      lastName: reto.student.last_name,
      profilePictureURL: reto.student.profile_picture_url,
    },
  };

  return adaptedRetoResponse;
};

export const submitRetoResponseAdapter = (response: any) => {
  const { achievement } = response;
  const adaptedSubmitRetoResponse = {
    earlyBird: achievement.early_bird,
    milestone: achievement.milestone,
    retosCompleted: achievement.retos_completed
      ? {
          id: achievement.retos_completed.id,
          title: achievement.retos_completed.title,
          description: achievement.retos_completed.description,
          achievementType: achievement.retos_completed.achievement_type,
          state: achievement.retos_completed.state,
          imageURL: achievement.retos_completed.image_url,
          levels: achievement.retos_completed.levels,
          level: {
            id: achievement.retos_completed.level.id,
            level: achievement.retos_completed.level.level,
            valueRequirement:
              achievement.retos_completed.level.value_requirement,
          },
        }
      : null,
    streak: achievement.streak,
  };

  return adaptedSubmitRetoResponse;
};

export const submittedRetosAdapter = (response: any) => {
  const { reto_responses } = response;
  if (reto_responses.length <= 0) return [];

  const adaptedSubmittedRetos = reto_responses.map((retoResponse: any) => {
    return {
      id: retoResponse.id,
      points: retoResponse.points,
      answer: retoResponse.answer,
      state: retoResponse.state,
      reto: {
        id: retoResponse.reto.id,
        title: retoResponse.reto.title,
        description: retoResponse.reto.description,
        type: retoResponse.reto.challenge_type,
        subtitle: retoResponse.reto.subtitle,
        dueDate: retoResponse.reto.due_date,
        reminder: retoResponse.reto.reminder,
        pointsAwarded: retoResponse.reto.points_awarded,
      },
    };
  });

  return adaptedSubmittedRetos;
};
