import { MdPushPin } from 'react-icons/md';
import { motion } from 'framer-motion';

export const PinMarker = () => {
  return (
    <motion.div
      initial={{ scale: 0.5 }} // Initial scale before animation starts
      animate={{ scale: 1 }} // Animate to normal size
      transition={{
        type: 'spring', // Use spring type for a natural bounce effect
        stiffness: 300, // Controls the "strength" of the spring
        damping: 20, // Controls how much bounce the animation has
        mass: 0.5, // Lower mass gives more bounce
        bounce: 0.4, // Adjusts the bounce effect
        duration: 0.5, // Duration of the animation
      }}
    >
    <MdPushPin
      size={24}
      className='flex-shrink-0 -translate-y-3 text-secondary-semantic-error-400'
    />
    </motion.div>
  );
};
