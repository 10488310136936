import { useBlocker } from 'react-router-dom';
import { ModalConfirmation } from '@components/organisms';
import { useNavigate } from 'react-router-dom';
import { PUBLIC_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useTranslation } from 'react-i18next';

export const OnboardingBlocker = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(['pages/auth/signup/common']);
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname
  );

  const handleConfirm = () => {
    blocker.proceed!();
    navigate(PUBLIC_ROUTES_PATHS.login);
  };
  return (
    <ModalConfirmation
      open={blocker.state === 'blocked'}
      title={t('onboardingBlocker.title')}
      handleConfirm={handleConfirm}
      handleCancel={() => blocker.reset!()}
      text={t('onboardingBlocker.description')}
    />
  );
};
