import { ReactNode } from 'react';

export interface ButtonProps {
  type?: 'submit' | 'button' | 'reset';
  style:
    | 'primary'
    | 'secondary'
    | 'ghost'
    | 'outline'
    | 'link'
    | 'poll'
    | 'profile';
  text: string;
  onClick?: () => void;
  className?: string;
  iconRight?: ReactNode;
  iconLeft?: ReactNode;
  loading?: boolean;
  disabled?: boolean;
  form?: string;
}

const Button = ({
  type = 'button',
  text,
  onClick,
  className = '',
  style,
  iconLeft,
  iconRight,
  loading,
  disabled,
  form,
  ...rest
}: ButtonProps) => {
  const baseStyles =
    'btn btn-ghost h-12 w-full rounded-xl border-0 focus:border-2 mobile:w-72 focus:outline-0';
  const typeStyles = {
    primary:
      'bg-secondary-buttons-100 shadow-button active:bg-secondary-buttons-300 text-secondary-text-100 disabled:bg-secondary-buttons-200 hover:bg-primary-300 focus:border-secondary-buttons-600 disabled:text-secondary-buttons-600',
    secondary:
      'bg-secondary-surface-200 shadow-button !border border-secondary-buttons-100 active:!border-2 text-secondary-buttons-100 disabled:text-secondary-buttons-400 disabled:border-secondary-buttons-400 focus:border-secondary-buttons-200 focus-within:border-secondary-buttons-200 hover:bg-primary-100 hover:border hover:border-secondary-buttons-100',
    ghost:
      'text-secondary-text-400 active:text-secondary-buttons-200 focus:text-secondary-buttons-300 disabled:text-secondary-buttons-400 hover:text-secondary-buttons-100 hover:bg-transparent',
    outline:
      '!border border-secondary-buttons-600 active:!border-2 text-secondary-buttons-600 disabled:text-secondary-buttons-400 disabled:border-secondary-buttons-400 focus:border-secondary-buttons-600 focus-within:border-secondary-buttons-600 hover:bg-transparent hover:border hover:border-secondary-buttons-600',
    link: 'btn-link w-max text-primary-400 hover:text-primary-300 px-0 underline-offset-2 disabled:bg-transparent disabled:text-primary-300',
    poll: 'bg-primary-100 hover:bg-primary-100 font-normal text-secondary-text-300 disabled:bg-primary-100 disabled:text-secondary-text-200 !border hover:!border border-primary-300 hover:!border-primary-300',
    profile:
      'btn content-center rounded-xl border-none bg-secondary-surface-200 px-6 py-4 shadow-xsInner hover:border-none hover:bg-secondary-surface-200 hover:shadow-xsInner active:bg-secondary-surface-200 active:shadow-xsInner disabled:bg-secondary-surface-200 disabled:shadow-xsInner',
  };
  return (
    <button
      className={`${baseStyles} ${typeStyles[style]} ${className}`}
      type={type}
      onClick={onClick}
      disabled={disabled}
      form={form}
      {...rest}
    >
      <div
        className={`flex w-full items-center justify-center gap-4 ${
          iconLeft || iconRight ? '!justify-between' : ''
        }`}
      >
        {iconLeft ? iconLeft : null}
        {loading ? <span className='loading loading-spinner'></span> : null}
        {!loading && text}
        {iconRight ? iconRight : null}
      </div>
    </button>
  );
};

export default Button;
