import { createSlice } from '@reduxjs/toolkit';
import { UserRole } from '@domain/constants';
import { RootState } from '@store/index';

interface UserState {
  avatar: string;
  email: string;
  id: number;
  firstName: string;
  lastName: string;
  role: (typeof UserRole)[keyof typeof UserRole];
  username: string;
  parents: Parent[];
  phoneNumber: string;
  points: Points;
}

interface Parent {
  id: number;
  relationship: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

interface Points {
  available: number;
  lifetime: number;
}

const initialState: UserState = {
  avatar: '',
  email: '',
  id: 0,
  firstName: '',
  lastName: '',
  role: UserRole.NOT_LOGGED,
  username: '',
  phoneNumber: '',
  points: {
    available: 0,
    lifetime: 0,
  },
  parents: [],
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    logoutUser(state) {
      state.avatar = '';
      state.email = '';
      state.id = 0;
      state.firstName = '';
      state.lastName = '';
      state.role = UserRole.NOT_LOGGED;
      state.username = '';
      state.parents = [];
      state.points = {
        available: 0,
        lifetime: 0,
      };
      state.phoneNumber = '';
    },
    updatePoints: (state, action) => {
      state.points = action.payload;
    },
    updateName: (state, action) => {
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
    },
    updateAvatar: (state, action) => {
      state.avatar = action.payload;
    },
    loginSuccess(state, action) {
      state.avatar = action.payload.avatar;
      state.email = action.payload.email;
      state.id = action.payload.id;
      state.firstName = action.payload.firstName;
      state.lastName = action.payload.lastName;
      state.role = action.payload.role;
      state.username = action.payload.username;
      state.parents = action.payload.parents;
      state.points = action.payload.points;
      state.phoneNumber = action.payload.phoneNumber;
    },
    addParent: state => {
      state.parents.push({
        relationship: 0,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
      });
    },
    updateParent: (state, action) => {
      const { index, field, value } = action.payload;
      state.parents[index][field] = value;
    },
    deleteParent: (state, action) => {
      state.parents.splice(action.payload, 1);
    },
  },
});

export const UserSelectors = {
  getAvatar: (state: RootState) => state.user.avatar,
  getEmail: (state: RootState) => state.user.email,
  getId: (state: RootState) => state.user.id,
  getFirstName: (state: RootState) => state.user.firstName,
  getLastName: (state: RootState) => state.user.lastName,
  getFullName: (state: RootState) =>
    `${state.user.firstName} ${state.user.lastName}`,
  getRole: (state: RootState) => state.user.role,
  getUser: (state: RootState) => state.user,
  getUsername: (state: RootState) => state.user.username,
  getParents: (state: RootState) => state.user.parents,
  getPhoneNumber: (state: RootState) => state.user.phoneNumber,
  getPoints: (state: RootState) => state.user.points,
  getAvailablePoints: (state: RootState) => state.user.points.available,
  getLifetimePoints: (state: RootState) => state.user.points.lifetime,
};

export const {
  loginSuccess,
  logoutUser,
  addParent,
  updateParent,
  deleteParent,
  updateName,
  updateAvatar,
  updatePoints,
} = userSlice.actions;

export const UserReducer = userSlice.reducer;
