import { ReactNode } from 'react';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { Typography } from '@components/atoms';

interface BottomNavigationButtonProps {
  icon: ReactNode;
  label: string;
  route: string;
  isHome?: boolean;
  isProfile?: boolean;
}

export const BottomNavigationButton = ({
  icon,
  label,
  route,
  isHome,
  isProfile,
}: BottomNavigationButtonProps) => {
  const resolved = useResolvedPath(route);
  const match = useMatch({ path: resolved.pathname, end: true });
  const isHomeActive = match || window.location.pathname.startsWith('/classes');
  const isProfileActive =
    match || window.location.pathname.startsWith('/profile');

  return (
    <div className='flex w-full flex-col items-center justify-center gap-1'>
      <NavLink
        to={route}
        className={({ isActive }) =>
          `flex items-center justify-center rounded-[100%] ${
            isProfile && isProfileActive
              ? 'border-2 border-secondary-text-100 [&_*]:!text-secondary-buttons-100'
              : (isHome && isHomeActive) || isActive
                ? 'bg-secondary-text-100 p-2 [&_*]:!text-secondary-buttons-100 '
                : ''
          }`
        }
      >
        {icon}
      </NavLink>
      <Typography
        variant='span'
        className='font-medium !text-secondary-buttons-600'
      >
        {label}
      </Typography>
    </div>
  );
};
