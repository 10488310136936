import { ModalSheet } from '@components/molecules';
import { arcVitral } from '@assets/svg';
import { Button, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { useState, useEffect, useMemo } from 'react';
import { MdClose } from 'react-icons/md';
import { GameController } from '@assets/img';
import { GlowingCountdown } from '@components/molecules/GlowingCountdown/GlowingCountdown';
import { getDailyPrayerGameStorage } from '@data/utils/home/localStorage';

interface ModalSheetDailyPrayerProps {
  isOpen: boolean;
  onClose: () => void;
  onStartGame: () => void;
  isHalfPoints: boolean;
}

export const ModalSheetDailyPrayer: React.FC<ModalSheetDailyPrayerProps> = ({
  isOpen,
  onClose,
  onStartGame,
  isHalfPoints,
}) => {
  const { t } = useTranslation('components/templates/modalSheetDailyPrayer');
  const [countdown, setCountdown] = useState(5);

  const gameData = useMemo(() => getDailyPrayerGameStorage(), []);

  // Countdown logic COMMENT WHEN WORKING ON THIS COMPONENT
  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isOpen && countdown > 0) {
      timer = setTimeout(() => setCountdown(countdown - 1), 1000);
    } else if (countdown === 0) {
      onStartGame();
    }
    return () => clearTimeout(timer);
  }, [countdown, isOpen, onStartGame]);

  const handleClose = () => {
    onClose();
    setCountdown(5);
  };

  const instructionsText = useMemo(() => {
    if (!gameData) return '';
    return isHalfPoints
      ? t('halfPointsInstructions', {
          minPoints: Math.floor(gameData.min_points * 0.5),
          maxPoints: Math.floor(gameData.max_points * 0.5),
        })
      : t('instructions', {
          timer: gameData.timer,
          minPoints: gameData.min_points,
          maxPoints: gameData.max_points,
        });
  }, [gameData, t, isHalfPoints]);

  return (
    <ModalSheet
      isOpen={isOpen}
      onClose={handleClose}
      className={`bg-gradient-radial from-[#6E2AB1] to-[#8B3ED8]`}
    >
      <img
        src={arcVitral}
        alt='arc'
        className='absolute -top-7 left-0 right-0 -z-10 w-full select-none opacity-100 user-drag-none'
      />
      <div className='flex w-full flex-col items-center py-4 pt-4 [&>*]:text-center [&>*]:!text-secondary-text-100'>
        <MdClose
          size={24}
          className='absolute right-4 top-4 cursor-pointer text-secondary-text-100'
          onClick={handleClose}
        />
        <div className='mb-6 mt-10'>
          <GlowingCountdown count={countdown} />
        </div>

        <img
          src={GameController}
          alt='Game Controller'
          className='mt-6 h-36 w-36 object-contain'
        />

        <Typography variant='h2' className='mb-4 text-[24px] leading-[28px]'>
          {isHalfPoints ? t('alreadyPlayed') : t('letsPlay')}
        </Typography>

        <div className='w-[294px] rounded-lg bg-secondary-surface-100 p-4'>
          <Typography variant='p' className='text-[16px] leading-[20px]'>
            {instructionsText}
          </Typography>
        </div>

        <Button
          className='mt-8 border-2 border-2'
          type='button'
          onClick={onStartGame}
          text={isHalfPoints ? t('playAgain') : t('startGame')}
          style='outline'
        />
      </div>
    </ModalSheet>
  );
};
