export const shopLandingAdapter = (response: any) => {
  const transformItem = item => ({
    id: item.id,
    title: item.title,
    description: item.description,
    type: item.item_type,
    requiredPoints: item.required_points,
    imageURL: item.image_url,
    purchasedByStudent: item.purchased_by_student,
  });

  const avatars = response.avatars.map(transformItem);
  const collectibles = response.collectibles.map(transformItem);

  return {
    avatars,
    collectibles,
  };
};
