import {
  RETO_TYPE_STORAGE_KEY,
  RETO_TITLE_STORAGE_KEY,
  RETO_POINTS_STORAGE_KEY,
} from '@domain/constants';

interface RetoStorage {
  title: string;
  retoType: string;
}

export const setRetoPointsStorage = (points: string) => {
  localStorage.setItem(RETO_POINTS_STORAGE_KEY, points);
};

export const getRetoPointsStorage = () => {
  const points: string = localStorage.getItem(RETO_POINTS_STORAGE_KEY) || '';

  return points;
};

export const removeRetoPointsStorage = () => {
  localStorage.removeItem(RETO_POINTS_STORAGE_KEY);
};

export const setRetoStorage = ({ title, retoType }: RetoStorage) => {
  localStorage.setItem(RETO_TITLE_STORAGE_KEY, title);
  localStorage.setItem(RETO_TYPE_STORAGE_KEY, retoType);
};

export const getRetoStorage = (): RetoStorage => {
  const title: string = localStorage.getItem(RETO_TITLE_STORAGE_KEY) || '';
  const retoType: string = localStorage.getItem(RETO_TYPE_STORAGE_KEY) || '';

  return { title, retoType };
};

export const removeRetoStorage = () => {
  localStorage.removeItem(RETO_TITLE_STORAGE_KEY);
  localStorage.removeItem(RETO_TYPE_STORAGE_KEY);
};
