import React from 'react';

interface TypographyProps {
  children: React.ReactNode;
  variant: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span' | 'a' | 'body1';
  className?: string;
  style?: React.CSSProperties;
}

export const Typography: React.FC<TypographyProps> = ({
  children,
  variant,
  className,
  style,
  ...rest
}) => {
  const Component = variant === 'body1' ? 'p' : variant;

  const textStyles: Record<string, string> = {
    h1: 'text-[24px] leading-7 font-heading',
    h2: 'text-[16px] leading-[18px] font-heading',
    span: 'text-[14px] leading-[18px] font-body',
    p: 'text-[14px] leading-[18px] font-body',
    a: 'text-[14px] leading-[18px] font-body !text-primary-400 underline',
    body1: 'text-[14px] leading-[18px] font-body',
  };

  return (
    <Component
      className={`text-secondary-text-300 ${textStyles[variant] || ''} ${className || ''}`}
      style={style}
      {...rest}
    >
      {children}
    </Component>
  );
};
