import { LessonTemplate, StudentCardList } from '@components/templates';
import { getLessonStorage } from '@data/utils';
import { useQuery } from '@tanstack/react-query';
import { BottomCard, Button, Typography } from '@components/atoms';
import { useLessons } from '@hooks/index';
import { useTranslation } from 'react-i18next';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useNavigate } from 'react-router-dom';

export const LeaderboardPage = () => {
  const { title, description, color, backgroundColor } = getLessonStorage();
  const navigate = useNavigate();
  const { getLeaderboard } = useLessons();
  const { t } = useTranslation(
    'pages/home/home/classes/modules/lessons/leaderboard/leaderboardPage'
  );

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['leaderboard'],
    queryFn: getLeaderboard,
    retry: false,
  });

  const navigateToHome = () => {
    navigate(PROTECTED_ROUTES_PATHS.home);
  };

  return (
    <LessonTemplate
      title={title}
      subtitle={description}
      style={{ backgroundColor, color }}
      childrenClassName='!mx-0 !pt-0'
      isLoading={isLoading || isPending}
    >
      <div className='mt-[100px] h-14 w-full bg-secondary-surface-100'></div>
      <BottomCard containerStyles='shadow-inner bg-secondary-surface-200 justify-between flex-1 !p-6'>
        <Typography variant='h1' className='mb-4'>
          {t('leaderboard')}
        </Typography>
        <StudentCardList students={data} showScores showBadges />

        <div className='fixed bottom-4 left-0 right-0 z-10 mt-4 flex w-full items-center justify-center'>
          <Button
            type='button'
            className='!w-[90%]'
            style='primary'
            onClick={navigateToHome}
            text={t('buttons.continue')}
          />
        </div>
      </BottomCard>
    </LessonTemplate>
  );
};
