import { ReactNode } from 'react';

interface CardProps {
  children: ReactNode;
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
  id?: string;
}

export const Card = ({
  children,
  className,
  onClick,
  style,
  id,
}: CardProps) => {
  return (
    <div
      id={id}
      className={`flex w-full cursor-pointer items-center justify-between rounded-xl p-4 shadow-inner ${className}`}
      style={style}
      role='button'
      onClick={onClick}
    >
      {children}
    </div>
  );
};
