import { WebClientRest } from '@data/api/clients';
import { AUTH_TOKEN_STORAGE_KEY } from '@domain/constants';

class ModulesApi {
  private client: WebClientRest;

  constructor(client: WebClientRest) {
    this.client = client;
  }

  getLessons(id: string) {
    return this.client.get(`/modules/${id}/lessons`, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }
}

export { ModulesApi };
