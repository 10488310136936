import { Content, UserRole } from '@domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';
import {
  PollActivityInput,
  PollActivityResponses,
} from '@components/molecules';
import { useQuiz } from '@hooks/index';
import { useState } from 'react';

interface RenderQuizActivityProps {
  slide: Content;
  role: UserRole;
  channel: string;
  showResults: boolean;
}

export const RenderQuizActivity = ({
  slide,
  role,
  channel,
  showResults,
}: RenderQuizActivityProps) => {
  const { quizResponses, sendQuizResponse } = useQuiz({
    channel,
    slideId: slide.id,
  });
  const [selectedOption, setSelectedOption] = useState<string>('');

  return (
    <SlideContentWrapper
      title={slide.quiz!.question}
      type={slide.type}
      subtype={slide.subtype}
    >
      {role === UserRole.CATECHIST ? (
        <PollActivityResponses
          data={quizResponses}
          options={slide.quiz!.options}
          correctAnswer={slide.quiz?.options[slide.quiz?.correctOption]}
        />
      ) : !showResults ? (
        <PollActivityInput
          options={slide.quiz!.options}
          correctAnswer={slide.quiz?.options[slide.quiz?.correctOption]}
          onClickOption={sendQuizResponse}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      ) : (
        <PollActivityResponses
          data={quizResponses}
          options={slide.quiz!.options}
          correctAnswer={slide.quiz?.options[slide.quiz?.correctOption]}
        />
      )}
    </SlideContentWrapper>
  );
};
