import { usePubNub } from 'pubnub-react';
import { useState, useEffect, useCallback } from 'react';
import {
  ActivityPayload,
  CustomMessageEvent,
  SlideActionType,
  SlideBodyType,
} from '@domain/constants';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';

interface ShowResultsProps {
  channel: string;
  slideId?: number;
}

export const useShowResults = ({ channel }: ShowResultsProps) => {
  const [showResults, setShowResults] = useState<boolean>(false);

  const pubnub = usePubNub();

  const userId = useSelector(UserSelectors.getId);

  const handleShowResults = useCallback((event: CustomMessageEvent) => {
    const actionType = event.message.action;
    const body = event.message.body;

    if (actionType === SlideActionType.SHOW_RESULTS) {
      setShowResults(body.show_results);
    }
  }, []);

  const sendShowResults = (showResults: boolean) => {
    try {
      const messagePayload: ActivityPayload = {
        uuid: userId,
        action: SlideActionType.SHOW_RESULTS,
        body: {
          type: SlideBodyType.SLIDE,
          show_results: showResults,
          slide_id: 0,
        },
        subscribed_channel: channel,
        actual_channel: channel,
      };

      pubnub.publish({
        channel: channel,
        message: messagePayload,
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    const listenerParams = {
      message: handleShowResults,
    };

    pubnub.addListener(listenerParams);
    pubnub.subscribe({ channels: [channel] });

    return () => {
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, channel, handleShowResults]);

  return {
    showResults,
    sendShowResults,
    setShowResults,
  };
};
