import { WebClientRest } from '@data/api/clients';
import { AUTH_TOKEN_STORAGE_KEY } from '@domain/constants';

interface User {
  username: string | null;
  email: string | null;
  password: string;
  password_confirmation: string;
  role: string;
  first_name: string;
  last_name: string;
  course_id: string;
  shop_id: number;
  parents_attributes: Parent[];
}

interface LoginUser {
  login: string;
  password: string;
}

interface CheckUserExists {
  email: string;
  username: string;
}

interface Parent {
  relationship: number;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
}

interface ResetPassword {
  token: string;
  password: string;
  confirm_password: string;
}

interface ChangePassword {
  old_password: string;
  password: string;
  confirm_password: string;
}

interface EditUser {
  first_name?: string;
  last_name?: string;
  shop_id?: string;
  parents_attributes?: Parent[];
}

class UsersApi {
  private client: WebClientRest;

  constructor(client: WebClientRest) {
    this.client = client;
  }

  loginUser(user: LoginUser) {
    return this.client.post('/login', { user });
  }

  checkUserExists(params: CheckUserExists) {
    return this.client.get('/users/check', {
      params,
    });
  }

  createStudent(user: User) {
    return this.client.post<User>('/signup', {
      user,
    });
  }

  getUser() {
    return this.client.get('/users', {
      headers: {
        Accept: 'application/json',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  logoutUser() {
    return this.client.get('/logout', {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  forgotPassword(query: string) {
    return this.client.post('/passwords/forgot', {
      query,
    });
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.client.post<ResetPassword>('/passwords/reset', {
      ...resetPassword,
    });
  }

  changePassword(changePassword: ChangePassword) {
    return this.client.post<ChangePassword>(
      '/passwords/change',
      {
        ...changePassword,
      },
      {
        headers: {
          Accept: 'application/json',
          Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  editUser(user: EditUser) {
    return this.client.patch<User>(
      '/users',
      {
        user,
      },
      {
        headers: {
          Accept: 'application/json',
          Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
        },
      }
    );
  }

  getAchievedBadges() {
    return this.client.get('/users/badges', {
      headers: {
        Accept: 'application/json',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }
}
export { UsersApi };
