export const shopItemsByTypeAdapter = (response: any) => {
  const adaptedItems = response.map(item => ({
    id: item.id,
    title: item.title,
    description: item.description,
    type: item.item_type,
    requiredPoints: item.required_points,
    imageURL: item.image_url,
    link: item.link,
    purchasedByStudent: item.purchased_by_student,
  }));

  return adaptedItems;
};
