import { ProgressBar, Typography, BadgeImage } from '@components/atoms';
import { assignLevelColors, getAchievementLevels } from '@data/utils';
import { ACHIEVEMENT_STATES, RETO_STATES_STR } from 'src/domain/constants';

interface BadgeProps {
  badge: any;
  onClick?: () => void;
  requiredValue: number;
  currentStat: number;
}

export const Badge = ({
  badge,
  onClick,
  currentStat,
  requiredValue,
}: BadgeProps) => {
  const { maxLevel, currentLevel } = getAchievementLevels(badge.levels);

  const studentHasBadge = currentLevel >= 1;
  const studentHasMaxLevel = currentLevel >= maxLevel;

  return (
    <div className='space-y-4' role='button' onClick={onClick}>
      <BadgeImage
        src={badge.imageURL}
        color={assignLevelColors(badge.levels)}
      />
      <div className='flex flex-col items-center space-y-2'>
        <Typography
          variant='span'
          className='font-bold !text-secondary-text-200'
        >
          {badge.title}
        </Typography>
        {badge.levels &&
          studentHasBadge &&
          !studentHasMaxLevel &&
          badge.state === ACHIEVEMENT_STATES.active && (
            <ProgressBar value={currentStat} max={requiredValue} />
          )}
      </div>
    </div>
  );
};
