import { ReactNode } from 'react';
import { MdHomeFilled, MdPerson } from 'react-icons/md';
import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';

interface ButtonNavigationProps {
  icon: ReactNode;
  route: string;
  isProfile?: boolean;
  isHome?: boolean;
}

const ButtonNavigation = ({ icon, route, isHome }: ButtonNavigationProps) => {
  const resolved = useResolvedPath(route);
  const match = useMatch({ path: resolved.pathname, end: true });
  const isHomeActive = match || window.location.pathname.startsWith('/classes');

  return (
    <NavLink
      to={route}
      className={({ isActive }) =>
        `flex items-center justify-center rounded-[100%] ${
          (isHome && isHomeActive) || isActive
            ? 'bg-secondary-text-100 p-2 [&_*]:!text-secondary-buttons-100 '
            : ''
        }`
      }
    >
      {icon}
    </NavLink>
  );
};

export const HeaderNavigation = () => {
  const NAVIGATION_BUTTONS = [
    {
      id: 1,
      icon: <MdHomeFilled size={24} />,
      route: PROTECTED_ROUTES_PATHS.home,
      isHome: true,
    },
    {
      id: 2,
      icon: <MdPerson size={24} />,
      route: PROTECTED_ROUTES_PATHS.profile,
    },
  ];

  return (
    <div className='flex gap-2 [&_*]:!flex-shrink-0'>
      {NAVIGATION_BUTTONS.map(({ icon, route, id, isHome }) => (
        <ButtonNavigation route={route} key={id} icon={icon} isHome={isHome} />
      ))}
    </div>
  );
};
