import { TabLayout } from '@components/atoms';
import { TabNavigation, TabSelector } from '@components/organisms';
import { HomeTemplate } from '@components/templates';
import { useClasses } from '@hooks/index';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { trackGA4PageView } from '@data/utils';
import { useLocation, useParams } from 'react-router-dom';
import { PAGE_NAMES } from '@domain/constants';
import { useTranslation } from 'react-i18next';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { GradedRetosList } from 'src/ui/components/organisms/GradedRetosList/GradedRetosList';

export const GradedRetosPage = () => {
  const { getSubmittedRetos } = useClasses();
  const location = useLocation();
  const { classId } = useParams();

  const { t } = useTranslation(['components/organisms/tabSelector']);

  const retoTabs = useMemo(
    () => [
      {
        id: 'active',
        label: t('active', { ns: 'components/organisms/tabSelector' }),
        route: PROTECTED_ROUTES_PATHS.retos.replace(':classId', classId!),
      },
      {
        id: 'submitted',
        label: t('submitted', { ns: 'components/organisms/tabSelector' }),
        route: PROTECTED_ROUTES_PATHS.gradedRetos.replace(':classId', classId!),
      },
    ],
    [t, classId]
  );

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['getSubmittedRetos'],
    queryFn: getSubmittedRetos,
    retry: false,
  });

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: location.pathname,
      title: PAGE_NAMES.retos,
    });
  }, [location.pathname]);

  return (
    <HomeTemplate
      childrenClassname='!px-0'
      layoutClassname='!bg-secondary-surface-100'
    >
      <TabNavigation />
      <TabLayout
        className='flex flex-col gap-4'
        isLoading={isLoading || isPending}
      >
        <TabSelector tabs={retoTabs} />

        <GradedRetosList retos={data} />
      </TabLayout>
    </HomeTemplate>
  );
};
