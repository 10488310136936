import { MdOutlineCancel, MdCheckCircleOutline } from 'react-icons/md';
import { Typography } from '@components/atoms';
import { ToastNotificationType } from '@domain/constants';

interface ToastMessageProps {
  title: string;
  message: string;
}

interface ToastIconProps {
  type: ToastNotificationType;
}

export interface ToastNotificationProps
  extends ToastIconProps,
    ToastMessageProps {}

const ToastIcon = ({ type }: ToastIconProps) => {
  let icon;
  const iconProps = {
    size: 24,
    className: 'flex-shrink-0 text-secondary-surface-100',
  };
  if (type === ToastNotificationType.success) {
    icon = <MdCheckCircleOutline {...iconProps} />;
  } else if (type === ToastNotificationType.error) {
    icon = <MdOutlineCancel {...iconProps} />;
  }

  const backgroundColor =
    type === ToastNotificationType.success
      ? 'bg-secondary-semantic-success-400'
      : 'bg-secondary-semantic-error-400';

  return (
    <div
      className={`${backgroundColor} grid place-items-center rounded-lg p-2`}
    >
      {icon}
    </div>
  );
};

const ToastMessage = ({ title, message }: ToastMessageProps) => {
  return (
    <div className='flex flex-col'>
      <Typography variant='span' className='font-bold'>
        {title}
      </Typography>
      <Typography variant='span'>{message}</Typography>
    </div>
  );
};

export const ToastNotification = ({
  type,
  title,
  message,
}: ToastNotificationProps) => {
  return (
    <div className='flex w-full items-center gap-4'>
      <ToastIcon type={type} />
      <ToastMessage title={title} message={message} />
    </div>
  );
};
