import {
  AnimatedCounter,
  AnimatedButton,
  Layout,
  Typography,
} from '@components/atoms';
import { LoginImage, TacoImage } from '@components/molecules';
import { Vitral } from '@assets/svg';
import { useTranslation } from 'react-i18next';
import { useBlocker } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { motion } from 'framer-motion';

interface CongratulationsProps {
  title: string;
  description: string;
  descriptionTwo?: string;
  studentName?: string;
  tacoPoints: number;
  onClick: () => void;
  buttonText?: string;
}

export const Congratulations = ({
  tacoPoints = 0,
  title,
  description,
  descriptionTwo,
  onClick,
  studentName,
  buttonText,
}: CongratulationsProps) => {
  const { t } = useTranslation(['common']);
  const [isContinueClicked, setIsContinueClicked] = useState<boolean>(false);

  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      !isContinueClicked && currentLocation.pathname !== nextLocation.pathname
  );

  const handleClick = useCallback(() => {
    setIsContinueClicked(true);
  }, []);

  useEffect(() => {
    if (isContinueClicked) {
      onClick();
    }
  }, [isContinueClicked, onClick]);

  return (
    <Layout
      isFlex
      containerStyles='!p-0 bg-gradient-radial !from-primary-350 !to-primary-400'
    >
      <LoginImage image={Vitral} />

      <section className='mx-4 mb-6 flex flex-1 flex-col items-center justify-between text-center [&_*]:!text-secondary-text-100'>
        <motion.div
          initial={{ scale: 0.1, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 300,
            damping: 20,
            delay: 0.3,
          }}
        >
          <Typography variant='h1' className='text-[32px] leading-9 '>
            {title}
          </Typography>
        </motion.div>

        <motion.div
          className='flex flex-col [&_*]:!text-[16px]'
          initial={{ scale: 0.1, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 300,
            damping: 20,
            delay: 0.5,
          }}
        >
          <div>
            <Typography variant='span'>{description}</Typography>
            {studentName && (
              <Typography variant='span' className='font-bold'>
                {studentName}.
              </Typography>
            )}
          </div>
          {descriptionTwo && (
            <Typography variant='span'>{descriptionTwo}</Typography>
          )}
        </motion.div>

        <motion.div
          initial={{ scale: 0.1, opacity: 0, rotate: 90 }}
          animate={{ scale: 1, opacity: 1, rotate: 0 }}
          transition={{
            type: 'spring',
            stiffness: 200,
            damping: 15,
            delay: 0.7,
          }}
        >
          <TacoImage />
        </motion.div>

        <motion.div
          className='flex flex-col [&_*]:!text-[16px]'
          initial={{ scale: 0.1, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 300,
            damping: 20,
            delay: 0.9,
          }}
        >
          <Typography variant='span' className='text-xl font-bold'>
            {t('labels.earnedTacoPoints')}
          </Typography>
        </motion.div>

        <motion.div
          className='[&>*]:!text-secondary-text-100 '
          initial={{ scale: 0.1, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            type: 'spring',
            stiffness: 300,
            damping: 20,
            duration: 1,
            delay: 0.9,
          }}
        >
          <AnimatedCounter
            targetValue={tacoPoints}
            duration={2}
            className='text-[48px] leading-[52px] text-white'
          />
        </motion.div>

        <AnimatedButton
          className='w-full'
          type='button'
          onClick={handleClick}
          text={buttonText || t('button.continue')}
          style='outline'
        />
        {blocker.state === 'blocked' && null}
      </section>
    </Layout>
  );
};
