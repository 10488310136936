export interface AvatarProps {
  containerStyle?: string;
  className?: string;
  isActive?: boolean;
  onClick?: () => void;
  image?: string;
  text?: string;
}

export const Avatar = ({
  containerStyle,
  className,
  isActive,
  onClick,
  text,
  image,
}: AvatarProps) => {
  const activeClassname = isActive
    ? 'ring ring-offset-4 ring-4 ring-primary-400'
    : '';
  return (
    <div onClick={onClick} className={`avatar placeholder ${containerStyle}`}>
      <div
        className={`bg-primary w-24 rounded-full text-neutral-content before:absolute before:left-0 before:top-0 before:h-full before:w-full before:rounded-full before:shadow-inner ${activeClassname} ${className} `}
      >
        {text ? <span className='text-3xl'>{text}</span> : null}
        {image ? <img src={image} alt='avatar' /> : null}
      </div>
    </div>
  );
};
