import { Typography } from '@components/atoms';
import { ReminderCard, ReminderCardProps } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { MdSupervisorAccount } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';

interface ReminderListProps {
  reminder: ReminderCardProps;
}

const RemindersOptions = () => {
  return (
    <div className='mt-4 flex w-full justify-center gap-2 pt-1'>
      <a href='#reminder1' className='btn btn-xs'>
        1
      </a>
      <a href='#reminder2' className='btn btn-xs'>
        2
      </a>
    </div>
  );
};

export const ReminderList = ({ reminder }: ReminderListProps) => {
  const parents = useSelector(UserSelectors.getParents);

  const { t } = useTranslation('components/organisms/reminderList');

  if (reminder || !parents.length)
    return (
      <article className='mt-4 flex w-full flex-col'>
        <Typography variant='h1'>{t('title')}</Typography>
        <div className='carousel mt-4 w-full gap-4 rounded-box'>
          {reminder && <ReminderCard {...reminder} id='reminder1' />}
          {!parents.length && (
            <ReminderCard
              title={t('parentsTitle')}
              subtitle={t('parentsDescription')}
              id='reminder2'
              icon={
                <MdSupervisorAccount
                  size={24}
                  className='flex-shrink-0 !text-primary-400'
                />
              }
            />
          )}
        </div>
        {reminder && !parents.length && <RemindersOptions />}
      </article>
    );
};
