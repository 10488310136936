import { ReactNode } from 'react';
import { Sheet } from 'react-modal-sheet';

export interface ModalSheetProps {
  isOpen: boolean;
  onClose: () => void;
  children: ReactNode;
  onOpenStart?: () => void;
  onCloseEnd?: () => void;
  className?: string;
}

const SheetHeader = () => {
  return (
    <div className='flex items-center justify-center pb-4 pt-2'>
      <div className='h-[5px] w-1/3 rounded-full bg-[#121319]' />
    </div>
  );
};

export const ModalSheet = ({
  isOpen,
  onClose,
  children,
  onOpenStart,
  onCloseEnd,
  className,
}: ModalSheetProps) => {
  return (
    <Sheet
      isOpen={isOpen}
      onClose={onClose}
      detent='content-height'
      onOpenStart={onOpenStart}
      onCloseEnd={onCloseEnd}
    >
      <Sheet.Container className={`!rounded-t-xl !shadow-inner ${className}`}>
        <Sheet.Header>
          <SheetHeader />
        </Sheet.Header>
        <Sheet.Content className='w-full px-6'>{children}</Sheet.Content>
      </Sheet.Container>
      <Sheet.Backdrop />
    </Sheet>
  );
};
