interface LoaderProps {
  containerClassName?: string;
  className?: string;
}

export const Loader = ({ containerClassName, className }: LoaderProps) => {
  return (
    <div
      className={`flex flex-1 items-center justify-center ${containerClassName}`}
    >
      <span
        className={`loading loading-spinner loading-lg text-primary-400 ${className}`}
      ></span>
    </div>
  );
};
