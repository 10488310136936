export const studentsAdapter = (response: any) => {
  return response.students.map(student => ({
    id: student.id,
    firstName: student.first_name,
    lastName: student.last_name,
    username: student.username,
    score: student.score || 0,
    profilePictureURL: student.profile_picture_url,
    points: {
      lifetime: student.points.lifetime || 0,
      available: student.points.available || 0,
    },
  }));
};

export const studentAdapter = (response: any) => {
  const student = response.student;
  return {
    id: student.id,
    firstName: student.first_name,
    lastName: student.last_name,
    username: student.username,
    score: student.score || 0,
    lessonsDone: student.lessons_done || 0,
    retosDone: student.retos_done || 0,
    profilePictureURL: student.profile_picture_url,
    points: {
      lifetime: student.points.lifetime || 0,
      available: student.points.available || 0,
    },
    parents: student.parents
      ? student.parents.map(parent => {
          return {
            id: parent.id,
            firstName: parent.first_name,
            lastName: parent.last_name,
            relationship: parent.relationship,
            phoneNumber: parent.phone_number,
            email: parent.email,
          };
        })
      : [],
  };
};

export const pointsAdapter = (response: any) => {
  const points = {
    available: response.points.available || 0,
    lifetime: response.points.lifetime || 0,
  };
  return points;
};
