import { PositionedAvatar, UserInfo } from '@components/molecules';
import { Loader, ProfileCard, Typography } from '@components/atoms';
import { HomeTemplate } from '@components/templates';
import { MdEdit, MdEmail, MdPerson } from 'react-icons/md';
import { useProfile } from '@hooks/useProfile';
import { ParentsInformation } from '@components/organisms';
import { useQuery } from '@tanstack/react-query';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { trackGA4PageView } from '@data/utils';
import { useEffect } from 'react';
import { PAGE_NAMES } from '@domain/constants';

export const DetailsPage = () => {
  const { navigateToEditName, navigateToProfile, getUser } = useProfile();

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['userDetails'],
    queryFn: getUser,
  });

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.profileDetails,
      title: PAGE_NAMES.profileDetails,
    });
  }, []);

  return (
    <HomeTemplate isProfilePage onBackButtonClick={navigateToProfile}>
      <PositionedAvatar isEdit />

      <div className='flex flex-1 flex-col gap-5 pt-12'>
        {isLoading || isPending ? (
          <Loader />
        ) : (
          <>
            <ProfileCard>
              <div className='mb-3 flex items-center justify-between'>
                <Typography variant='h2' className='text-[22px] leading-[26px]'>
                  {data?.firstName} {data?.lastName}
                </Typography>
                <MdEdit
                  size={24}
                  className='flex-shrink-0 cursor-pointer rounded-full  text-secondary-buttons-100'
                  onClick={navigateToEditName}
                />
              </div>
              <div className='space-y-2'>
                {data?.email && <UserInfo text={data?.email} icon={MdEmail} />}
                {data?.username && (
                  <UserInfo text={data?.username} icon={MdPerson} />
                )}
              </div>
            </ProfileCard>

            <ParentsInformation parents={data?.parents} />
          </>
        )}
      </div>
    </HomeTemplate>
  );
};
