import { WebClientRest } from '@data/api/clients';
import { AUTH_TOKEN_STORAGE_KEY } from '@domain/constants';

class ClassesApi {
  private client: WebClientRest;

  constructor(client: WebClientRest) {
    this.client = client;
  }

  getClassById(id: string) {
    return this.client.get(`/courses/${id}`, {
      headers: {
        Accept: '*/*',
      },
    });
  }

  getModules(id: string) {
    return this.client.get(`/courses/${id}/modules`, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getStudents(classId: string) {
    return this.client.get(`/courses/${classId}/students`, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getStudentById(classId: string, id: string) {
    return this.client.get(`/courses/${classId}/students/${id}`, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getRetos(classId: string) {
    return this.client.get(`/courses/${classId}/retos`, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getSubmittedRetos(classId: string) {
    return this.client.get(`/courses/${classId}/submitted_retos`, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }
}

export { ClassesApi };
