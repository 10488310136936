import { Typography, Lottie } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { StudentCardList } from '@components/templates';
import { motion } from 'framer-motion';
import { ClockAnimation } from '@assets/animations';

interface WaitingRoomContentProps {
  students: Record<string, string | number>[];
}

interface WaitingRoomEmptyStateProps {
  title: string;
  description: string;
}

const EmptyState = ({ title, description }: WaitingRoomEmptyStateProps) => {
  return (
    <div className='flex w-full flex-col items-center gap-4 rounded-xl bg-primary-100 px-8 py-12 text-center [&_*]:!text-primary-400'>
      <Lottie
        animationData={ClockAnimation}
        style={{ margin: 'auto', width: 90, height: 90 }}
      />
      <Typography variant='span' className='font-bold'>
        {title}
      </Typography>
      <Typography variant='span'>{description}</Typography>
    </div>
  );
};

export const WaitingRoomContent = ({ students }: WaitingRoomContentProps) => {
  const { t } = useTranslation('components/organisms/waitingRoomContent');

  const isStudentsEmpty = students.length === 0;

  return (
    <div>
      <motion.div
        initial={{ y: '-150%', opacity: 0 }} // Start off-screen to the left
        animate={{ y: 0, opacity: 1 }} // Slide to the original position
        transition={{
          duration: 0.3,
          ease: 'easeInOut',
        }}
      >
        <div className='mb-8'>
          <Typography variant='h1'>{t('title')}</Typography>
          <Typography variant='span' className='text-secondary-buttons-700'>
            {t('description')}
          </Typography>
        </div>
      </motion.div>
      {!isStudentsEmpty ? (
        <StudentCardList students={students} containerStyles='!h-full' />
      ) : (
        <motion.div
          initial={{ scale: 0, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{
            delay: 0.3,
            duration: 0.5,
            ease: 'easeInOut',
            type: 'spring',
            damping: 20,
            stiffness: 300,
          }}
        >
          <div className='grid place-items-center'>
            <EmptyState
              title={t('emptyState.title')}
              description={t('emptyState.description')}
            />
          </div>
        </motion.div>
      )}
    </div>
  );
};
