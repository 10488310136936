import { FieldValues } from 'react-hook-form';
import { InputProps as Props } from '@forms/index';
import { BasicField } from '..';
import { InputHTMLAttributes, useState } from 'react';
import { MdVisibilityOff, MdVisibility } from 'react-icons/md';
import InputMask from 'react-input-mask';
import { InputType, PHONE_NUMBER_MASK } from '@domain/constants';

interface InputProps<T extends FieldValues> extends Props<T> {
  placeholder?: string;
  label?: string;
  type?: InputType;
  message?: string;
  disabled?: boolean;
  leftLabel?: string;
  inputMode?: InputHTMLAttributes<HTMLInputElement>['inputMode'];
}

const DISABLED_LABEL_CLASS = '!text-secondary-text-150';

const ERROR_INPUT_CLASS = '!border-secondary-semantic-error-400';
const ERROR_LABEL_CLASS = '!text-secondary-semantic-error-400';

const Input = <T extends FieldValues>({
  placeholder,
  label,
  control,
  name,
  containerStyle,
  rules,
  type,
  message,
  disabled,
  leftLabel,
  inputMode,
  ...rest
}: InputProps<T>) => {
  const disabledStyles = {
    label: disabled ? DISABLED_LABEL_CLASS : '',
    message: disabled ? DISABLED_LABEL_CLASS : '',
  };

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <BasicField
      containerStyle={containerStyle}
      name={name}
      control={control}
      rules={rules}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { invalid, error },
      }) => {
        const invalidStyles = {
          input: invalid
            ? `${ERROR_INPUT_CLASS} focus:${ERROR_INPUT_CLASS}`
            : '',
          label: invalid ? ERROR_LABEL_CLASS : '',
        };
        const isPassword = type === InputType.password;

        const handleMaskedChange = (e: React.ChangeEvent<HTMLInputElement>) => {
          const rawValue = e.target.value.replace(/\D/g, '');
          onChange(rawValue);
        };

        return (
          <div className='space-y-1'>
            <span
              className={`text-secondary-text-400 ${invalidStyles.label} ${disabledStyles.label}`}
            >
              {label}
            </span>
            <label
              className={`border-1 input flex w-full items-center gap-2 rounded-xl border-primary-200 px-2 pr-4 text-secondary-text-300 shadow-inner focus-within:border-primary-400 focus-within:shadow-inner focus-within:outline-0 disabled:border-primary-200 ${invalidStyles.input}`}
            >
              {leftLabel && (
                <span className='w-max text-[14px] font-bold text-[#14171B]'>
                  {leftLabel}
                </span>
              )}
              {type === InputType.phoneNumber ? (
                <InputMask
                  mask={PHONE_NUMBER_MASK}
                  value={value}
                  onChange={handleMaskedChange}
                  className='grow'
                  {...rest}
                  inputMode={inputMode}
                />
              ) : (
                <input
                  type={
                    isPassword
                      ? showPassword
                        ? InputType.text
                        : InputType.password
                      : type
                  }
                  onChange={onChange}
                  value={value}
                  placeholder={placeholder}
                  disabled={disabled}
                  className='grow'
                  onBlur={onBlur}
                  {...rest}
                  inputMode={inputMode}
                />
              )}

              {isPassword &&
                (showPassword ? (
                  <MdVisibilityOff
                    size={24}
                    className='cursor-pointer'
                    onClick={handleShowPassword}
                  />
                ) : (
                  <MdVisibility
                    className='cursor-pointer'
                    size={24}
                    onClick={handleShowPassword}
                  />
                ))}
            </label>

            <div className='h-6'>
              {message && !invalid && (
                <span
                  className={`text-secondary-text-200 ${disabledStyles.message}`}
                >
                  {message}
                </span>
              )}
              {invalid && !disabled && (
                <span className='text-secondary-semantic-error-400'>
                  {error?.message}
                </span>
              )}
            </div>
          </div>
        );
      }}
    />
  );
};

export default Input;
