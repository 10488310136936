import { Avatar, Typography } from '@components/atoms';
import { ReactNode } from 'react';
import { TacoIcon } from '@assets/svg';

export interface StudentCardProps {
  id: number;
  avatar: string;
  name: string;
  nameStyle?: string;
  badge?: ReactNode;
  showBadge?: boolean;
  onClick?: (id: number) => void;
  score?: string;
  showTacoPointsIcon: boolean;
}

export const StudentCard = ({
  id,
  avatar,
  name,
  nameStyle,
  badge,
  onClick,
  showBadge,
  score,
  showTacoPointsIcon,
}: StudentCardProps) => {

  return (
    <div
      className='flex items-center justify-between '
      onClick={() => onClick && onClick(id)}
    >
      <div className='flex items-center gap-4'>
        {showBadge &&
          (badge ? (
            <div className='h-6 w-6'>{badge}</div>
          ) : (
            <div className='w-6'></div>
          ))}

        <Avatar containerStyle='!w-14' image={avatar} />
        <Typography
          variant='span'
          className={`text-base font-medium ${nameStyle}`}
        >
          {name}
        </Typography>
      </div>
      {(Number(score) !== null || score) && (
        <div className='flex items-center gap-2'>
          {showTacoPointsIcon && (
            <img src={TacoIcon} alt='taco icon' className='h-6 w-6'></img>
          )}
          <Typography variant='span' className='font-bold'>
            {score}
          </Typography>
        </div>
      )}
    </div>
  );
};
