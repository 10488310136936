import { useEffect, useState } from 'react';
import { Button, Input, Select, Typography, Divider } from '@components/atoms';
import { SignupProps } from './index';
import { useForm, SubmitHandler, useFieldArray } from 'react-hook-form';
import { useSelector } from 'react-redux';
import {
  OnboardingSelectors,
  deleteGuardian,
} from '@store/slices/onboardingSlice';
import { useDispatch } from 'react-redux';
import { addGuardian, updateGuardian } from '@store/slices';
import { OnboardingBlocker, SignupTemplate } from '@components/templates';
import { InputType, PAGE_NAMES, SIGNUP_STEPS_VALUES } from '@domain/constants';
import { MdDelete, MdAdd } from 'react-icons/md';
import { useTranslation } from 'react-i18next';
import {
  nameRules,
  phoneNumberRules,
  emailRules,
  trackGA4PageView,
} from '@data/utils';
import { ModalConfirmation } from '@components/organisms';

export const SignupGuardianPage = ({
  handleNextStep,
  handlePrevStep,
}: SignupProps) => {
  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

  const guardians = useSelector(OnboardingSelectors.getGuardians);
  const dispatch = useDispatch();

  type Inputs = {
    guardians: Guardian[];
  };

  type Guardian = {
    relationship: number;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  };

  const { t } = useTranslation(['pages/auth/signup/signupGuardianPage']);

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs): void => {
    data.guardians.forEach((guardian, index) => {
      dispatch(
        updateGuardian({
          index,
          field: 'relationship',
          value: guardian.relationship,
        })
      );
      dispatch(
        updateGuardian({ index, field: 'firstName', value: guardian.firstName })
      );
      dispatch(
        updateGuardian({ index, field: 'lastName', value: guardian.lastName })
      );
      dispatch(
        updateGuardian({
          index,
          field: 'phoneNumber',
          value: guardian.phoneNumber,
        })
      );
      dispatch(
        updateGuardian({ index, field: 'email', value: guardian.email })
      );
    });
  };

  const handleSubmitForm = (data: Inputs): void => {
    onSubmit(data);
    handleNextStep();
  };

  const {
    handleSubmit,
    control,
    formState: { isValid },
  } = useForm<Inputs>({
    defaultValues: {
      guardians: guardians,
    },
    mode: 'onChange',
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'guardians',
  });

  const handleAddGuardian = (): void => {
    append({
      relationship: 0,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
    });
    dispatch(addGuardian());
  };

  const handleDeleteGuardian = (index: number): void => {
    remove(index);
    dispatch(deleteGuardian(index));
  };

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: '/signup',
      title: PAGE_NAMES.signupParentsPage,
    });
  }, []);

  return (
    <>
      <SignupTemplate
        handleBack={handlePrevStep}
        currentStep={SIGNUP_STEPS_VALUES.GUARDIAN_INFORMATION}
        maxSteps={Object.keys(SIGNUP_STEPS_VALUES).length}
        formId='submit-guardian-form'
        disabled={!isValid}
        skipButton={handleNextStep}
      >
        <Typography variant='h1'>{t('title')}</Typography>
        <Typography variant='span'>{t('subtitleOne')}</Typography>
        <Typography variant='p'>{t('subtitleTwo')}</Typography>
        <form
          className='w-full space-y-2'
          onSubmit={handleSubmit(handleSubmitForm)}
          id='submit-guardian-form'
        >
          {fields.map((_, index) => (
            <div key={index}>
              {index !== 0 && (
                <div className='mb-6 flex flex-col justify-between gap-4'>
                  <Divider />
                  <div className='flex justify-between'>
                    <Typography variant='h2'>{t('aditionalParent')}</Typography>
                    <MdDelete
                      size={24}
                      onClick={() => setDeleteModalOpen(true)}
                      className='text-secondary-semantic-error-400 hover:cursor-pointer'
                    />
                  </div>
                </div>
              )}
              <Select
                control={control}
                name={`guardians.${index}.relationship`}
                options={[
                  { value: 0, label: 'Mother' },
                  { value: 1, label: 'Father' },
                  { value: 2, label: 'Grandparent' },
                  { value: 3, label: 'Legal Guardian' },
                  { value: 4, label: 'Other' },
                ]}
                rules={{
                  required: {
                    value: true,
                    message: t('errors.relationship'),
                  },
                }}
                textLabel={t('inputs.relationship')}
              />
              <Input
                control={control}
                name={`guardians.${index}.firstName`}
                label={t('inputs.name')}
                rules={nameRules(
                  t('errors.name'),
                  t('inputs.errors.invalid', {
                    inputName: 'first name',
                    ns: 'common',
                  })
                )}
              />
              <Input
                control={control}
                name={`guardians.${index}.lastName`}
                label={t('inputs.lastName')}
                rules={nameRules(
                  t('errors.lastName'),
                  t('inputs.errors.invalid', {
                    inputName: 'last name',
                    ns: 'common',
                  })
                )}
              />
              <Input
                control={control}
                name={`guardians.${index}.phoneNumber`}
                label={t('inputs.phoneNumber')}
                type={InputType.phoneNumber}
                rules={phoneNumberRules(
                  t('errors.phoneNumber'),
                  t('inputs.errors.invalid', {
                    inputName: 'phone number',
                    ns: 'common',
                  })
                )}
                inputMode='numeric'
              />
              <Input
                control={control}
                name={`guardians.${index}.email`}
                label={t('inputs.email')}
                rules={emailRules(
                  t('errors.email'),
                  t('inputs.errors.invalid', {
                    inputName: 'email',
                    ns: 'common',
                  })
                )}
                inputMode='email'
              />

              <ModalConfirmation
                handleCancel={() => setDeleteModalOpen(false)}
                handleConfirm={() => handleDeleteGuardian(index)}
                open={deleteModalOpen}
                text={t('modals.delete.text')}
                title={t('modals.delete.title')}
              />
            </div>
          ))}

          {guardians.length <= 1 && (
            <Button
              iconLeft={<MdAdd size={24} className='text-primary-400' />}
              type='button'
              style='link'
              onClick={handleAddGuardian}
              text={t('buttons.addParent')}
            />
          )}
        </form>
      </SignupTemplate>
      <OnboardingBlocker />
    </>
  );
};
