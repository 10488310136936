import React from 'react';
import { Typography } from '@components/atoms';

interface GlowingCountdownProps {
  count: number;
}

export const GlowingCountdown: React.FC<GlowingCountdownProps> = ({ count }) => {
  return (
    <div className="relative inline-block">
      <div className="absolute inset-0 bg-gradient-radial from-yellow-200 via-yellow-300 to-transparent opacity-70 blur-2xl"></div>
      <div className="absolute inset-0 bg-gradient-radial from-yellow-100 to-transparent opacity-50 blur-xl"></div>
      <div className="relative z-10 flex items-center justify-center w-36 h-36 bg-white rounded-full shadow-lg">
        <Typography variant='h1' className='text-[78px] leading-[52px] !text-primary-400'>
          {count}
        </Typography>
      </div>
    </div>
  );
};
