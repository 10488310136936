import { MdHomeFilled, MdShoppingBag } from 'react-icons/md';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { BottomNavigationButton } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { Avatar } from '@components/atoms';

export const HomeBottomNavigation = () => {
  const { t } = useTranslation('components/organisms/homeBottomNavigation');
  const userAvatar = useSelector(UserSelectors.getAvatar);

  const BOTTOM_NAVIGATION_BUTTONS = useMemo(
    () => [
      {
        id: 'home',
        icon: (
          <MdHomeFilled
            className='flex-shrink-0 text-secondary-buttons-600'
            size={24}
          />
        ),
        label: t('home'),
        route: PROTECTED_ROUTES_PATHS.home,
      },
      {
        id: 'profile',
        icon: <Avatar image={userAvatar} className='!h-10 !w-10' />,
        label: t('profile'),
        route: PROTECTED_ROUTES_PATHS.profile,
      },
      {
        id: 'shop',
        icon: (
          <MdShoppingBag
            className='flex-shrink-0 text-secondary-buttons-600'
            size={24}
          />
        ),
        label: t('shop'),
        route: PROTECTED_ROUTES_PATHS.shop,
      },
    ],
    [t, userAvatar]
  );

  return (
    <nav className='fixed bottom-0 z-10 flex h-20 w-full items-center justify-around rounded-t-xl bg-primary-400 shadow-general'>
      {BOTTOM_NAVIGATION_BUTTONS.map(({ id, icon, label, route }) => (
        <BottomNavigationButton
          key={id}
          icon={icon}
          label={label}
          route={route}
          isHome={route === PROTECTED_ROUTES_PATHS.home}
          isProfile={route === PROTECTED_ROUTES_PATHS.profile}
        />
      ))}
    </nav>
  );
};
