import { HomeTemplate } from '@components/templates';
import { ProfileButtonsNavigation } from '@components/organisms';
import { Card, ProfileCard, Typography } from '@components/atoms';
import { UserInfo } from '@components/molecules';
import { MdEmail, MdPhone, MdInfo } from 'react-icons/md';
import { formatPhoneNumber } from '@data/utils';
import { useTranslation } from 'react-i18next';

export const CatechistPage = ({ user }: { user: any }) => {
  const { t } = useTranslation('pages/home/profile/profilePage');

  return (
    <HomeTemplate>
      <div className='flex flex-1 flex-col justify-between'>
        <ProfileCard className='space-y-3'>
          <Typography variant='h1' className='text-[22px] leading-[26px]'>
            {user?.firstName} {user?.lastName}
          </Typography>

          <UserInfo
            text={formatPhoneNumber(user?.phoneNumber)}
            icon={MdPhone}
          />
          <UserInfo text={user?.email} icon={MdEmail} />

          <Card className='gap-2 bg-secondary-surface-200'>
            <MdInfo size={24} className='flex-shrink-0 text-primary-400' />
            <Typography variant='span'>{t('updateInformation')}</Typography>
          </Card>
        </ProfileCard>

        <ProfileButtonsNavigation />
      </div>
    </HomeTemplate>
  );
};
