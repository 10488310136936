import { Button, Layout, Typography } from '@components/atoms';
import { NoWifiTaco } from '@assets/img';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AUTH_TOKEN_STORAGE_KEY } from '@domain/constants';
import {
  PROTECTED_ROUTES_PATHS,
  PUBLIC_ROUTES_PATHS,
} from '@navigation/routes/RoutesPaths';

interface NotFoundPageProps {
  descriptionOne: string;
  descriptionTwo: string;
}

export const NotFoundPage = ({
  descriptionOne,
  descriptionTwo,
}: NotFoundPageProps) => {
  const navigate = useNavigate();

  const navigateBack = () => {
    const authToken = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);

    if (authToken) {
      navigate(PROTECTED_ROUTES_PATHS.home);
    } else {
      navigate(PUBLIC_ROUTES_PATHS.login);
    }
  };

  const { t } = useTranslation('pages/common/notFoundPage/notFoundPage');

  return (
    <Layout
      isFlex
      isCentered
      containerStyles='px-12 bg-gradient-radial justify-between py-28 !from-[#909090] !to-[#434343] [&_*]:!text-white [&_*]:!text-center'
    >
      <Typography variant='h1' className='text-[32px] leading-[36px]'>
        {t('title')}
      </Typography>
      <img src={NoWifiTaco} className='w-[190px]'></img>
      <div className='grid place-items-center gap-2'>
        <Typography
          variant='span'
          className='mt-4 text-[20px] font-bold leading-[24px]'
        >
          {descriptionOne}
        </Typography>
        <Typography
          variant='span'
          className='text-[16px] font-medium leading-[20px]'
        >
          {descriptionTwo}
        </Typography>
      </div>
      <Button
        text={t('button')}
        style='outline'
        type='button'
        onClick={navigateBack}
      />
    </Layout>
  );
};
