import { useBlocker } from 'react-router-dom';
import { ModalConfirmation } from '@components/organisms';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { LessonSelectors, ModalCloseLessonSelectors } from '@store/slices';

export const ActivityBlocker = () => {
  const isExitingLesson = useSelector(ModalCloseLessonSelectors.getIsExiting);
  const isWaitingRoom = useSelector(LessonSelectors.getIsWaitingRoom);
  const { t } = useTranslation(['components/templates/activityBlocker']);
  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (isExitingLesson) return false;
    if (isWaitingRoom) return false;
    return currentLocation.pathname !== nextLocation.pathname;
  });

  return (
    <ModalConfirmation
      open={blocker.state === 'blocked'}
      title={t('title')}
      handleCancel={() => blocker.reset!()}
      hideCancelButton
      handleConfirm={() => blocker.reset!()}
      handleConfirmText={t('buttons.continueActivity')}
      text={t('description')}
    />
  );
};
