import { Modal, ModalProps } from '@components/molecules';
import { Button, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { ReactNode } from 'react';

export interface ModalConfirmationProps extends ModalProps {
  text?: string;
  handleConfirm?: () => void;
  handleCancel?: () => void;
  children?: ReactNode;
  handleConfirmDisabled?: boolean;
  handleConfirmLoading?: boolean;
  handleConfirmText?: string;
  hideCancelButton?: boolean;
}

export const ModalConfirmation = ({
  text,
  handleConfirm,
  handleCancel,
  handleConfirmDisabled,
  handleConfirmLoading,
  handleConfirmText,
  children,
  hideCancelButton = false,
  ...rest
}: ModalConfirmationProps) => {
  const { t } = useTranslation('common');

  return (
    <Modal onClose={handleCancel} {...rest}>
      <Typography variant='p'>{text!}</Typography>

      {children}

      <div className='mt-4 flex flex-col gap-4'>
        {handleConfirm && (
          <Button
            style='primary'
            type='button'
            disabled={!handleConfirm || handleConfirmDisabled}
            loading={handleConfirmLoading}
            onClick={async () => {
              await handleConfirm();
              handleCancel?.();
            }}
            text={handleConfirmText || t('button.confirm')}
          />
        )}

        {handleCancel && !hideCancelButton && (
          <Button
            style='secondary'
            type='button'
            onClick={handleCancel}
            text={t('button.cancel')}
          />
        )}
      </div>
    </Modal>
  );
};
