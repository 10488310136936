import React, { useEffect, useState } from 'react';
import { BottomCard, Typography, DraggableWord } from '@components/atoms';
import { SlideControls, SlideControlsProps } from '@components/organisms';
import { EndingPrayerGame as EndingPrayerGameComponent } from '@components/molecules';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { UserRole, EndingPrayerGame } from '@domain/constants';
import { useLessons } from '@hooks/index';
import { useTranslation } from 'react-i18next';

interface EndingPrayerSlideProps extends SlideControlsProps {
  endingPrayerGame: EndingPrayerGame;
  onComplete: (
    filledPrayer: string,
    points: number,
    allCorrect: boolean
  ) => void;
  lessonId: string;
}

export const EndingPrayerSlide: React.FC<EndingPrayerSlideProps> = ({
  nextButtonText,
  onClickNext,
  endingPrayerGame,
  onComplete,
  lessonId,
}) => {
  const role = useSelector(UserSelectors.getRole);
  const [isGameCompleted, setIsGameCompleted] = useState(false);
  const { gradeEndingPrayerGame } = useLessons();
  const { t } = useTranslation([
    'components/templates/endingPrayerSlide',
    'common',
  ]);


  if (!endingPrayerGame || !endingPrayerGame.prayerText || !endingPrayerGame.correctAnswers || !endingPrayerGame.blankPositions) {
    return null;
  }

  const handleComplete = async (
    filledPrayer: string,
    points: number,
    allCorrect: boolean
  ) => {
    setIsGameCompleted(true);

    if (role === UserRole.STUDENT) {
      try {
        await gradeEndingPrayerGame(
          lessonId,
          endingPrayerGame.id.toString(),
          points
        );
      } catch (error) {
        console.error('Error grading ending prayer game:', error);
      }
    }

    onComplete(filledPrayer, points, allCorrect);
  };

  const renderCatechistView = () => {
    const words = endingPrayerGame.prayerText.split(/(\s+)/);
    let blankIndex = 0;

    return (
      <Typography variant="body1" className="text-base sm:text-lg md:text-xl leading-relaxed">
        {words.map((word, index) => {
          if (word.startsWith('#blank')) {
            const answer = endingPrayerGame.correctAnswers[blankIndex];
            blankIndex++;
            return (
              <React.Fragment key={index}>
                {' '}
                <DraggableWord
                  word={answer || ''}
                  className="inline-flex items-center justify-center px-2 py-1 rounded-full bg-base-100 border border-primary-200 text-sm"
                />
              </React.Fragment>
            );
          }
          return <React.Fragment key={index}>{word}</React.Fragment>;
        })}
      </Typography>
    );
  };

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  return (
    <div className='flex h-dvh flex-col justify-between pt-[100px]'>
      <BottomCard containerStyles='bg-secondary-surface-200 shadow-inner overflow-hidden'>
      <div className='grid place-items-center gap-2'>
        <Typography
          variant='h1'
          className='text-secondary-text-300 text-[24px] leading-7 font-heading mb-2 mt-2 !text-2xl !font-bold !text-primary-400'
        >
          {t('endingPrayer')}: {endingPrayerGame.prayerTitle}
        </Typography>
      </div>
        <div className='overflow-hidden'>
          {role === UserRole.CATECHIST ? (
            renderCatechistView()
          ) : (
            <EndingPrayerGameComponent
              prayerText={endingPrayerGame.prayerText}
              words={endingPrayerGame.correctAnswers}
              onComplete={handleComplete}
              blankPositions={endingPrayerGame.blankPositions}
              pointsAwarded={endingPrayerGame.pointsAwarded}
              isCompleted={isGameCompleted}
            />
          )}
          {role === UserRole.CATECHIST && (
            <div className='fixed bottom-4 left-0 right-0 z-10 mt-4 flex w-full items-center justify-center'>
              <SlideControls nextButtonText={nextButtonText} onClickNext={onClickNext} />
            </div>
          )}
        </div>
      </BottomCard>
    </div>
  );
};
