import {
  emailPattern,
  namePattern,
  phoneNumberPattern,
  passwordPattern,
} from '@data/utils';
import { PASSWORD_MIN_LENGTH } from '@domain/constants';

export const loginRules = (requiredMessage: string) => {
  return {
    required: {
      value: true,
      message: requiredMessage,
    },
  };
};

export const usernameRules = () => {
  return {
    required: {
      value: false,
    },
  };
};

export const nameRules = (requiredMessage: string, patternMessage: string) => {
  return {
    required: {
      value: true,
      message: requiredMessage,
    },
    pattern: {
      value: namePattern,
      message: patternMessage,
    },
  };
};

export const studentEmailRules = (patternMessage: string) => {
  return {
    pattern: {
      value: emailPattern,
      message: patternMessage,
    },
  };
};

export const emailRules = (requiredMessage: string, patternMessage: string) => {
  return {
    required: {
      value: true,
      message: requiredMessage,
    },
    pattern: {
      value: emailPattern,
      message: patternMessage,
    },
  };
};

export const passwordRules = (
  requiredMessage: string,
  minLengthMessage: string,
  patternMessage: string
) => {
  return {
    required: {
      value: true,
      message: requiredMessage,
    },
    pattern: {
      value: passwordPattern,
      message: patternMessage,
    },
    minLength: {
      value: PASSWORD_MIN_LENGTH,
      message: minLengthMessage,
    },
  };
};

export const phoneNumberRules = (
  requiredMessage: string,
  patternMessage: string
) => {
  return {
    required: {
      value: true,
      message: requiredMessage,
    },
    pattern: {
      value: phoneNumberPattern,
      message: patternMessage,
    },
  };
};
