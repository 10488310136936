import { PAGE_NAMES, UserRole } from '@domain/constants';
import { CatechistPage, StudentPage } from '@pages/index';
import { Loader } from '@components/atoms';
import { useProfile } from '@hooks/index';
import { HomeTemplate } from '@components/templates';
import { useQuery } from '@tanstack/react-query';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { trackGA4PageView } from '@data/utils';
import { useEffect } from 'react';

export const ProfilePage = () => {
  const { getUser } = useProfile();

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['user'],
    queryFn: getUser,
  });

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.profile,
      title: PAGE_NAMES.profile,
    });
  }, []);

  return (
    <>
      {isLoading || isPending ? (
        <HomeTemplate isProfilePage>
          <Loader />
        </HomeTemplate>
      ) : (data && data?.role) === UserRole.STUDENT ? (
        <StudentPage user={data} />
      ) : (
        <CatechistPage user={data} />
      )}
    </>
  );
};
