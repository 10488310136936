import { useTranslation } from 'react-i18next';
import { ActivitySubtype, ActivityType } from '@domain/constants';
import { Typography } from '@components/atoms';
import { getLessonTextSubtype } from '@data/utils';

export interface SlideSubtitleProps {
  type: ActivityType;
  subtype: ActivitySubtype;
}

export const SlideSubtitle = ({ type, subtype }: SlideSubtitleProps) => {
  const { t } = useTranslation('components/templates/slide');

  return (
    <Typography variant='span' className='font-bold !text-secondary-text-300'>
      {getLessonTextSubtype(type, subtype, t)}
    </Typography>
  );
};
