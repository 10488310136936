import { useCallback, useEffect, useRef, useState } from 'react';
import { PinField, Typography } from '@components/atoms';
import { SignupProps } from './index';
import { useSignup } from '@hooks/index';
import { useTranslation } from 'react-i18next';
import { SignupTemplate } from '@components/templates';
import { PAGE_NAMES, SIGNUP_STEPS_VALUES } from '@domain/constants';
import { OnboardingSelectors } from '@store/slices';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { trackGA4PageView } from '@data/utils';

export const SignupClassCodePage = ({
  handleNextStep,
  handlePrevStep,
}: SignupProps) => {
  const classCodeRef = useRef<HTMLInputElement[] | null>(null);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const { setClassCodeInput, setClassNameInput, getClassNameByCodeMutation } =
    useSignup();
  const [classNameError, setClassNameError] = useState<boolean>(false);
  const [isClassCodeComplete, setIsClassCodeComplete] =
    useState<boolean>(false);

  const className = useSelector(OnboardingSelectors.getClassName);
  const { classCode } = useParams();

  const { t } = useTranslation('pages/auth/signup/signupClassCodePage');

  const handleChange = useCallback(
    (code: string) => {
      if (code.length !== 6) {
        setIsDisabled(true);
        setClassNameInput('');
      }
      code = code.toUpperCase();
      setClassCodeInput(code);
    },
    [setClassCodeInput, setClassNameInput]
  );

  useEffect(() => {
    if (classCode && classCodeRef.current) {
      const codeArray = classCode.split('');
      codeArray.forEach((char, index) => {
        if (classCodeRef.current && classCodeRef.current[index]) {
          classCodeRef.current[index].value = char;
        }
      });
      handleChange(classCode);
      if (classCode.length === 6 && !isClassCodeComplete) {
        setIsClassCodeComplete(true);
        handleComplete(classCode);
      }
    }
  }, [classCode]); // eslint-disable-line react-hooks/exhaustive-deps

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: '/signup',
      title: PAGE_NAMES.signupClassCodePage,
    });
  }, []);

  const handleComplete = async (code: string) => {
    try {
      const classNameResponse =
        await getClassNameByCodeMutation.mutateAsync(code);
      setClassNameError(false);
      setClassNameInput(classNameResponse);
      setIsDisabled(false);
    } catch (_) {
      setClassNameError(true);
    }
  };

  const handleNext = () => {
    handleNextStep();
  };

  return (
    <SignupTemplate
      handleBack={handlePrevStep}
      handleNext={handleNext}
      currentStep={SIGNUP_STEPS_VALUES.CLASS_CODE}
      maxSteps={Object.keys(SIGNUP_STEPS_VALUES).length}
      buttonLoading={getClassNameByCodeMutation.isPending}
      disabled={isDisabled}
    >
      <div className='flex flex-col gap-12'>
        <div className='flex flex-col gap-2'>
          <Typography variant='h1'>{t('title')}</Typography>
          <Typography variant='span'>{t('subtitle')}</Typography>
        </div>

        <div className='flex flex-col gap-4'>
          <PinField
            inputRef={classCodeRef}
            onChange={handleChange}
            onComplete={handleComplete}
            hasError={classNameError}
            inputMode='text'
          />
          {classNameError && (
            <Typography
              variant='span'
              className='!text-secondary-semantic-error-400'
            >
              {t('errors.className')}
            </Typography>
          )}
        </div>

        {className && (
          <div className='grid place-items-center gap-2 font-bold'>
            <Typography variant='span' className='text-base'>
              {t('className')}
            </Typography>
            <Typography variant='span' className='text-[20px] leading-[24x]'>
              {className}
            </Typography>
          </div>
        )}
      </div>
    </SignupTemplate>
  );
};
