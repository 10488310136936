import React from 'react';
import { Typography } from '@components/atoms';

interface CompleteDailyPrayerProps {
  text: string;
  correctAnswers: string[];
  imageUrl: string;
  title: string;
}

export const CompleteDailyPrayer: React.FC<CompleteDailyPrayerProps> = ({
  text,
  correctAnswers,
  imageUrl,
  title,
}) => {
  const renderCompletedText = () => {
    let answerIndex = 0;
    const parts = text.split(/(#blank)/);

    return parts.map((part, index) => {
      if (part === '#blank') {
        const answer = correctAnswers[answerIndex];
        answerIndex++;
        return (
          <span key={index} className="text-primary-400 font-bold">
            {answer}
          </span>
        );
      }
      return <span key={index}>{part}</span>;
    });
  };

  return (
    <div className="flex flex-col items-center space-y-4">
      <div className="w-full h-full rounded-lg overflow-hidden">
        <img
          src={imageUrl}
          alt="Prayer"
          className="w-full h-full object-cover"
        />
      </div>
      <div className="w-full h-[45vh] bg-white p-6 rounded-lg shadow-md flex flex-col">
        <Typography variant="h2" className="text-center text-xl font-bold mb-4">
          {title}
        </Typography>
        <Typography variant="p" className="text-center text-base leading-relaxed flex-grow overflow-y-auto">
          {renderCompletedText()}
        </Typography>
      </div>
    </div>
  );
};
