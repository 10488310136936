import { ReactNode } from 'react';
import { SlideHeader, SlideHeaderProps } from '@components/organisms';
import { Layout, Loader } from '@components/atoms';

interface LessonTemplateProps extends SlideHeaderProps {
  children: ReactNode;
  headerStyles?: string;
  childrenClassName?: string;
  isLoading?: boolean;
  layoutContainerStyles?: string;
}

export const LessonTemplate = ({
  children,
  title,
  subtitle,
  headerStyles,
  style,
  childrenClassName,
  clickCloseButton,
  isLoading,
  clickSlideButton,
  clickBackButton,
  layoutContainerStyles,
}: LessonTemplateProps) => {
  return (
    <Layout isFlex containerStyles={`${layoutContainerStyles} !p-0`}>
      <SlideHeader
        title={title}
        subtitle={subtitle}
        containerStyles={headerStyles}
        style={style}
        clickBackButton={clickBackButton}
        clickSlideButton={clickSlideButton}
        clickCloseButton={clickCloseButton}
      />
      {isLoading ? (
        <Loader />
      ) : (
        <section
          className={`z-10 mx-auto flex h-full flex-1 flex-col pt-[100px] ${childrenClassName}`}
        >
          {children}
        </section>
      )}
    </Layout>
  );
};
