import { TacoIcon } from '@assets/svg';
import { Typography } from '@components/atoms';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';

export const HeaderTacoPoints = () => {
  const studentTacoPoints = useSelector(UserSelectors.getAvailablePoints);

  return (
    <div className='flex gap-2 rounded-lg bg-secondary-buttons-300 p-2'>
      <img src={TacoIcon} alt='Taco points icon'></img>
      <Typography
        variant='span'
        className='text-[16px] font-medium leading-[20px] !text-secondary-text-100'
      >
        {studentTacoPoints?.toString()}
      </Typography>
    </div>
  );
};
