import { Typography } from '@components/atoms';

interface StatisticProps {
  title: string;
  value: string;
  Icon: JSX.Element;
}

export const Statistic = ({ title, value, Icon }: StatisticProps) => {
  return (
    <div className='flex flex-col items-center gap-4'>
      <div className='flex flex-col items-center gap-2'>
        {Icon}
        <Typography
          variant='span'
          className='text-center text-[12px] leading-[16px]'
        >
          {title}
        </Typography>
      </div>
      <Typography
        variant='span'
        className='text-[24px] font-bold leading-[28px] !text-primary-400'
      >
        {value}
      </Typography>
    </div>
  );
};
