import DOMPurify from 'dompurify';

interface RichTextTypographyProps {
  description: string;
}

export const RichTextTypography = ({
  description,
}: RichTextTypographyProps) => {
  return (
    <div
      className='[&>ul>ul]:list-circle [&>ol>ul]:list-circle [&>ul>ul>ul]:list-square [&>ol>ul>ul]:list-square [&>ol>ol>ul]:list-square [&>ol>ol]:list-lower-alpha [&>ul>ol]:list-lower-alpha [&>ol>ol>ol]:list-lower-roman [&>ul>ol>ol]:list-lower-roman [&>ul>ul>ol]:list-lower-roman [&>ol]:list-decimal [&>ol]:pl-10 [&>ul]:list-disc [&>ul]:pl-10'
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }}
    />
  );
};
