import { Avatar, Button, TabLayout, Typography } from '@components/atoms';
import { TabNavigation } from '@components/organisms';
import { HomeTemplate } from '@components/templates';
import { useRetos } from '@hooks/index';
import { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  convertToUTCMinus4,
  removeRetoStorage,
  setRetoStorage,
  trackGA4PageView,
} from '@data/utils';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { formatDateTime } from '@data/utils';
import { PAGE_NAMES, RETO_STATES_STR } from '@domain/constants';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

const RenderResponsesList = ({
  responses,
  handleClick,
  dueDate,
}: {
  responses: any[];
  handleClick: (id: string) => void;
  dueDate: string;
}) => {
  const getSubmittedResponses = useCallback(() => {
    return responses.filter(
      response => response.state !== RETO_STATES_STR.graded
    );
  }, [responses]);

  const getCompletedResponses = useCallback(() => {
    return responses.filter(
      response => response.state === RETO_STATES_STR.graded
    );
  }, [responses]);

  const submittedResponses = useMemo(
    () => getSubmittedResponses(),
    [getSubmittedResponses]
  );
  const completedResponses = useMemo(
    () => getCompletedResponses(),
    [getCompletedResponses]
  );

  const { t } = useTranslation(
    'pages/home/home/classes/retos/retoResponsesPage'
  );

  const location = useLocation();

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: location.pathname,
      title: PAGE_NAMES.retosResponses,
    });
  }, [location.pathname]);

  return (
    <div className='space-y-4'>
      {submittedResponses.length > 0 && (
        <div className='space-y-4'>
          <Typography variant='span' className='font-bold'>
            {t('finished')}
          </Typography>
          {getSubmittedResponses().map(response => (
            <RenderStudentCard
              key={response.id}
              response={response}
              dueDate={dueDate}
              onClick={() => handleClick(response.id)}
            />
          ))}
        </div>
      )}
      {completedResponses.length > 0 && (
        <div className='space-y-4'>
          <Typography variant='span' className='font-bold'>
            {t('completed')}
          </Typography>
          {getCompletedResponses().map(response => (
            <RenderStudentCard
              dueDate={dueDate}
              key={response.id}
              response={response}
              onClick={() => handleClick(response.id)}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const RenderStudentCard = ({
  response,
  dueDate,
  onClick,
}: {
  response: any;
  dueDate: string;
  onClick?: () => void;
}) => {
  const { t } = useTranslation(
    'pages/home/home/classes/retos/retoResponsesPage'
  );

  const student = response.student;
  const currentDate = new Date();
  const today = convertToUTCMinus4(currentDate);

  return (
    <div className='flex w-full items-center justify-between'>
      <div className='flex items-center gap-4'>
        <Avatar
          className='!h-14 !w-14'
          image={student.profilePictureURL}
          alt={student.firstName}
        />
        <Typography
          variant='span'
          className='text-[16px] font-bold leading-[20px]'
        >
          {student.firstName} {student.lastName}
        </Typography>
      </div>
      {today > dueDate && (
        <>
          {response.state === RETO_STATES_STR.graded ? (
            <div className='flex items-center gap-2'>
              <div className='rounded-xl bg-secondary-surface-100 px-4 py-2 [&>*]:!font-bold'>
                <Typography variant='span' className='text-primary'>
                  {response.points} {t('points')}
                </Typography>
              </div>
              <MdChevronRight
                size={24}
                className='!text-primary flex-shrink-0'
                onClick={onClick}
              />
            </div>
          ) : (
            <Button
              style='link'
              text='View response'
              type='button'
              onClick={onClick}
            />
          )}
        </>
      )}
    </div>
  );
};

export const RetosResponsesPage = () => {
  const { retoId } = useParams();

  const { getRetoResponses, navigateToRetoResponseDetails, navigateToRetos } =
    useRetos();
  const { data, isLoading, isPending } = useQuery({
    queryKey: ['retoResponses', retoId],
    queryFn: getRetoResponses,
    retry: false,
  });

  const today = new Date().toISOString();

  const { t } = useTranslation(
    'pages/home/home/classes/retos/retoResponsesPage'
  );

  const handleClick = useCallback(
    (id: string) => {
      removeRetoStorage();
      setRetoStorage({ title: data?.title, retoType: data?.type });

      navigateToRetoResponseDetails(id);
    },
    [navigateToRetoResponseDetails, data?.title, data?.type]
  );

  const getAnsweredResponse = useCallback(() => {
    return data?.retoResponses.filter(
      response => response.state === RETO_STATES_STR.graded
    );
  }, [data?.retoResponses]);

  return (
    <HomeTemplate
      childrenClassname='!px-0'
      layoutClassname='!bg-secondary-surface-100'
    >
      <TabNavigation />
      <TabLayout className='flex flex-col' isLoading={isLoading || isPending}>
        <MdChevronLeft
          size={24}
          onClick={navigateToRetos}
          className='mb-4 flex-shrink-0 cursor-pointer'
        />
        <div className='mb-8 grid gap-2'>
          <Typography variant='h1'>{data?.title}</Typography>
          <Typography variant='span' className='!text-secondary-buttons-700'>
            {t(`challengeType.${data?.type}`)}
          </Typography>
          {today < data?.dueDate ? (
            <Typography
              variant='span'
              className='text-[16px] font-bold leading-[18px] !text-primary-400'
            >
              {t('dueDateInfo', {
                dueDate: formatDateTime(data?.dueDate),
              })}
            </Typography>
          ) : (
            <Typography
              variant='span'
              className='text-[16px] font-bold leading-[18px] !text-secondary-buttons-700'
            >
              {t('totalResponses', {
                totalResponses: data?.retoResponses.length,
                responses: getAnsweredResponse()?.length,
              })}
            </Typography>
          )}
        </div>

        <RenderResponsesList
          responses={data?.retoResponses}
          handleClick={handleClick}
          dueDate={data?.dueDate}
        />
      </TabLayout>
    </HomeTemplate>
  );
};
