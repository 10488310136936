import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../tailwind.config';
import { AchievementsType } from '@domain/constants';

const fullConfig = resolveConfig(tailwindConfig);

const rankColors = [
  fullConfig.theme.colors.secondary.achievements[950], // maxRank
  fullConfig.theme.colors.secondary.achievements[900], // secondRank
  fullConfig.theme.colors.secondary.achievements[800], // thirdRank
  fullConfig.theme.colors.secondary.achievements[700], // fourthRank
  fullConfig.theme.colors.secondary.achievements[600], // fifthRank
  fullConfig.theme.colors.secondary.achievements[500], // sixthRank
  fullConfig.theme.colors.secondary.achievements[400], // seventhRank
  fullConfig.theme.colors.secondary.achievements[300], // eighthRank
  fullConfig.theme.colors.secondary.achievements[200], // ninthRank
  fullConfig.theme.colors.secondary.achievements[100], // tenthRank
  fullConfig.theme.colors.secondary.achievements[50], // notAcquiredColor
];

interface Stats {
  pointLandmarks: number;
  dailyPrayerStreak: number;
  lessonsCompleted: number;
  retosCompleted: number;
  modulesCompleted: number;
}

export const getAchievementLevels = (levels: any[]) => {
  const maxLevel = levels.length;
  const currentLevel = levels.filter(level => level.acquiredByStudent).length;
  return { maxLevel, currentLevel };
};

export const getLowestAcquiredLevel = (levels: any[]) => {
  const acquiredLevels = levels
    .filter(item => item.acquiredByStudent)
    .map(item => item.level);

  return acquiredLevels.length ? Math.min(...acquiredLevels) : 0;
};

export const getLevelRequiredValue = (
  levels: any[],
  stats: Stats,
  achievementType: AchievementsType
) => {
  let lowestLevel = getLowestAcquiredLevel(levels);
  if (lowestLevel === 1) 0;

  if (lowestLevel === 0) {
    lowestLevel = levels.length;
  } else {
    lowestLevel = lowestLevel - 1;
  }

  const futureLevel = levels.find((level: any) => level.level === lowestLevel);

  const requiredValue = futureLevel?.valueRequirement;

  let currentStat;

  switch (achievementType) {
    case AchievementsType.POINT_LANDMARKS:
      currentStat = stats.pointLandmarks;
      break;
    case AchievementsType.DAILY_PRAYER_STREAK:
      currentStat = stats.dailyPrayerStreak;
      break;
    case AchievementsType.LESSON_COMPLETION:
      currentStat = stats.lessonsCompleted;
      break;
    case AchievementsType.RETOS_COMPLETED:
      currentStat = stats.retosCompleted;
      break;
    case AchievementsType.MODULE_COMPLETION:
      currentStat = stats.modulesCompleted;
      break;
  }

  return { requiredValue, currentStat };
};

export const assignLevelColors = (levels: any[]) => {
  const currentLevel = getLowestAcquiredLevel(levels);

  return rankColors.at(currentLevel - 1);
};

export const assignLevelColorsByNumber = (currentLevel: number) => {
  return rankColors[currentLevel - 1];
};
