import { Button, ButtonProps, AnimatedButton } from '@components/atoms';
import { useTranslation } from 'react-i18next';

export interface SlideControlsProps
  extends Omit<ButtonProps, 'text' | 'type' | 'style'> {
  nextButtonText?: string;
  onClickNext: () => void;
  onShowResults?: (showResults: boolean) => void;
  showResults?: boolean;
}

export const SlideControls = ({
  onClickNext,
  nextButtonText,
  onShowResults,
  showResults,
  ...rest
}: SlideControlsProps) => {
  const { t } = useTranslation('components/organisms/slideControls');

  const handleClickNext = () => {
    onClickNext();
    if (onShowResults && showResults) onShowResults(false);
  };

  return (
    <div className='flex w-[90%] flex-row items-center gap-2'>
      {onShowResults && (
        <AnimatedButton
          style='secondary'
          type='button'
          text={showResults ? t('hideResults') : t('showResults')}
          className='max-w-fit !flex-1'
          onClick={() => onShowResults(!showResults)}
        />
      )}
      <AnimatedButton
        {...rest}
        style='primary'
        type='button'
        className='!flex-1'
        text={nextButtonText || t('continue')}
        onClick={handleClickNext}
      />
    </div>
  );
};
