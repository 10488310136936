import { ReactNode } from 'react';
import { BorderContainer, Typography } from '@components/atoms';

export interface EmptyStateProps {
  icon: ReactNode;
  label: string;
}

export const EmptyState = ({ icon, label }: EmptyStateProps) => {
  return (
    <BorderContainer containerStyles='flex w-full flex-col items-center p-4 mt-4 gap-4 text-center bg-secondary-surface-100'>
      {icon}
      <Typography variant='span' className='font-bold !text-primary-400'>
        {label}
      </Typography>
    </BorderContainer>
  );
};
