import { useEffect } from 'react';
import { Button, Input, Layout, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { MdChevronLeft } from 'react-icons/md';
import { InputType, PAGE_NAMES, PASSWORD_MIN_LENGTH } from '@domain/constants';
import { passwordRules, trackGA4PageView } from '@data/utils';
import { useForm } from 'react-hook-form';
import { useProfile } from '@hooks/index';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';

type Inputs = {
  oldPassword: string;
  password: string;
  confirmPassword: string;
};

export const ChangePasswordPage = () => {
  const { t } = useTranslation([
    'pages/home/profile/changePassword/changePasswordPage',
    'common',
  ]);

  const { navigateToProfile, changePasswordMutation } = useProfile();

  const handleFormSubmit = async (data: Inputs) => {
    try {
      await changePasswordMutation.mutateAsync(data);
    } catch (error) {
      console.log(error);
    }
  };

  const {
    handleSubmit,
    control,
    trigger,
    watch,
    formState: { isValid },
  } = useForm<Inputs>({
    defaultValues: {
      oldPassword: '',
      password: '',
      confirmPassword: '',
    },
    mode: 'onChange',
  });

  const { password, confirmPassword } = watch();

  useEffect(() => {
    if (confirmPassword) {
      trigger('confirmPassword');
    }
  }, [password, trigger, confirmPassword]);

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.changePassword,
      title: PAGE_NAMES.changePassword,
    });
  }, []);

  return (
    <Layout isFlex containerStyles='justify-between'>
      <div className='flex-1'>
        <MdChevronLeft
          size={24}
          onClick={navigateToProfile}
          className='mb-6 flex-shrink-0 cursor-pointer rounded-full text-secondary-buttons-500'
        />
        <Typography variant='h1' className='mb-2'>
          {t('title')}
        </Typography>
        <Typography variant='span'>{t('description')}</Typography>

        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className='mb-4 mt-6'
          id='change-password-form'
        >
          <Input
            label={t('inputs.labels.oldPassword')}
            type={InputType.password}
            control={control}
            name='oldPassword'
            rules={{
              required: {
                value: true,
                message: t('inputs.errors.invalid', {
                  inputName: 'old password',
                  ns: 'common',
                }),
              },
            }}
          />
          <Input
            label={t('inputs.labels.newPassword')}
            type={InputType.password}
            control={control}
            name='password'
            rules={passwordRules(
              t('inputs.errors.invalid', {
                inputName: 'password',
                ns: 'common',
              }),
              t('inputs.errors.minLengthPassword', {
                minLength: PASSWORD_MIN_LENGTH,
              }),
              t('inputs.errors.invalid', {
                inputName: 'password',
                ns: 'common',
              })
            )}
          />
          <Input
            label={t('inputs.labels.confirmPassword')}
            type={InputType.password}
            control={control}
            name='confirmPassword'
            rules={{
              required: {
                value: true,
                message: t('inputs.errors.passwordsNotMatch'),
              },
              validate: {
                matchPassword: (value: string) =>
                  value === watch('password')
                    ? true
                    : t('inputs.errors.passwordsNotMatch'),
              },
            }}
          />
        </form>
      </div>

      <Button
        type='submit'
        text={t('button.saveChanges', { ns: 'common' })}
        style='primary'
        form='change-password-form'
        loading={changePasswordMutation.isPending}
        disabled={!isValid || changePasswordMutation.isPending}
      />
    </Layout>
  );
};
