import { useCallback, useEffect } from 'react';
import { LessonsPath } from '@components/organisms';
import { LessonTemplate } from '@components/templates';
import { PAGE_NAMES, STATES_STR, UserRole } from '@domain/constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { resetLesson, UserSelectors } from '@store/slices';
import { getLessonStorage, trackGA4PageView } from '@data/utils';
import { useLessons } from '@hooks/index';
import { useQuery } from '@tanstack/react-query';
import AnimatedButton from 'src/ui/components/atoms/AnimatedButton/AnimatedButton';
import { useDispatch } from 'react-redux';

export const LessonsPage = () => {
  const { t } = useTranslation('common');
  const { classId, moduleId } = useParams();
  const navigate = useNavigate();
  const { getLessons } = useLessons();
  const location = useLocation();
  const { title, description, color, backgroundColor } = getLessonStorage();
  const { data, isLoading, isPending } = useQuery({
    queryKey: ['getLessons'],
    queryFn: getLessons,
    networkMode: 'always',
    retry: false,
  });
  const dispatch = useDispatch();

  const userRole = useSelector(UserSelectors.getRole);
  const buttonText: string =
    userRole === UserRole.STUDENT ? t('button.join') : t('button.start');

  const getNextActiveLesson = useCallback(() => {
    return data?.lessons.find(lesson => lesson.state === STATES_STR.notStarted);
  }, [data]);

  const getActiveLesson = useCallback(() => {
    return data?.lessons.find(lesson => lesson.state === STATES_STR.inProgress);
  }, [data]);

  const navigateToActivity = useCallback(async () => {
    const nextLesson = getNextActiveLesson();
    const activeLesson = getActiveLesson();

    const nextLessonId = nextLesson?.publicUid;
    const activeLessonId = activeLesson?.publicUid;

    if (userRole === UserRole.CATECHIST) {
      navigate(
        PROTECTED_ROUTES_PATHS.lessonSummary
          .replace(':classId', classId!)
          .replace(':moduleId', moduleId!)
          .replace(':lessonId', activeLessonId || nextLessonId)
      );
      return;
    }

    navigate(
      PROTECTED_ROUTES_PATHS.activity
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
        .replace(':lessonId', activeLessonId || nextLessonId)
    );
  }, [
    classId,
    moduleId,
    navigate,
    getNextActiveLesson,
    getActiveLesson,
    userRole,
  ]);

  const handleBackButton = () => {
    navigate(
      PROTECTED_ROUTES_PATHS.modules
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
    );
  };

  useEffect(() => {
    dispatch(resetLesson());
  }, [dispatch]);

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: location.pathname,
      title: PAGE_NAMES.lessons,
    });
  }, [location.pathname]);

  return (
    <LessonTemplate
      title={title}
      subtitle={description}
      style={{ backgroundColor, color }}
      isLoading={isLoading || isPending}
      clickBackButton={handleBackButton}
      layoutContainerStyles='!bg-secondary-surface-100'
    >
      <LessonsPath lessons={data?.lessons} />
      {(!!getNextActiveLesson() || !!getActiveLesson()) && (
        <div className='fixed bottom-4 left-0 right-0 z-50 flex w-full items-center justify-center'>
          <AnimatedButton
            text={buttonText}
            style='primary'
            className='flex !w-[90%]'
            type='button'
            onClick={navigateToActivity}
            disabled={
              userRole !== UserRole.STUDENT
                ? !getNextActiveLesson() && !getActiveLesson()
                : !getActiveLesson()
            }
          />
        </div>
      )}
    </LessonTemplate>
  );
};
