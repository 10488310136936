import { useMemo, useState, useEffect } from 'react';
import { ModuleCard, ModuleCardProps } from '@components/organisms';
import resolveConfig from 'tailwindcss/resolveConfig';
import { STATES_STR } from '@domain/constants';
import tailwindConfig from '../../../../../tailwind.config';
import { AnimatePresence, motion } from "framer-motion";

const fullConfig = resolveConfig(tailwindConfig);
const pink = fullConfig.theme.colors.secondary.phaseone[400];
const blue = fullConfig.theme.colors.secondary.phasetwo[500];
const yellow = fullConfig.theme.colors.secondary.phasethree[400];
const red = fullConfig.theme.colors.secondary.phasefour[400];
const orange = fullConfig.theme.colors.secondary.phasefive[400];
const green = fullConfig.theme.colors.secondary.phasesix[400];
const blackText = fullConfig.theme.colors.secondary.text[300];
const whiteText = fullConfig.theme.colors.secondary.text[100];
const disabled = fullConfig.theme.colors.secondary.buttons[400];

const cardColors = [yellow, blue, red, pink, orange, green];

interface ModulesListProps {
  modules: ModuleCardProps[];
}

const getCardAndTextColors = (modules: ModuleCardProps[]) => {
  return modules.map((lesson, index) => {
    let cardColor = cardColors[index % cardColors.length];
    if (lesson.state === STATES_STR.notStarted) {
      cardColor = disabled;
    }
    const textColor =
      cardColor === yellow || cardColor === green ? blackText : whiteText;
    return { cardColor, textColor };
  });
};

export const ModulesList = ({ modules }: ModulesListProps) => {
  const cardAndTextColors = useMemo(
    () => getCardAndTextColors(modules),
    [modules]
  );
  
  const isModuleInProgress = (module: ModuleCardProps) => {
    return module.state == STATES_STR.inProgress || module.state == STATES_STR.finished;
  }
  
  const [hasRendered, setHasRendered] = useState(false);
  useEffect(() => {
    const timer = setTimeout(() => {
      setHasRendered(true);
    }, 700); // Delay should match the duration of your initial animations
  
    // Cleanup timer if component unmounts before the timer completes
    return () => clearTimeout(timer);
  }, []);

  return (
    <div className='mt-4 space-y-4'>
      {modules.map((module, index) => (
        <AnimatePresence key={index}>
        <motion.div
          initial={{ x: '-130%', opacity:0 }} // Start off-screen to the left
          animate={{ x: 0, opacity:1 }}       // Slide to the original positi
          whileTap={{ scale: isModuleInProgress(module) ? 0.9 : 1 }}
          whileHover={{ scale: isModuleInProgress(module) ? 1.1 : 1}}
          transition={{
          type: "spring",
          damping: 40,
          stiffness: 500,
          delay: hasRendered ? 0 : 0.2 + index * 0.1,
        }}
        >
            <ModuleCard
              key={module.id}
              {...module}
              cardColor={cardAndTextColors[index].cardColor}
              textColor={cardAndTextColors[index].textColor}
            />
          </motion.div>
        </AnimatePresence>
      ))}
    </div>
  );
};
