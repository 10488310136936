import React from 'react';

interface CountdownBarProps {
  initialTime: number;
  currentTime: number;
  containerStyle?: string;
}

export const CountdownBar: React.FC<CountdownBarProps> = ({
  initialTime,
  currentTime,
  containerStyle = '',
}) => {
  const percentage = (currentTime / initialTime) * 100;

  const getBarColor = () => {
    if (percentage > 50) return 'bg-primary-400';
    if (percentage > 25) return 'bg-secondary-phasetwo-400';
    return 'bg-secondary-phasefour-400';
  };

  return (
    <div className={`w-full ${containerStyle}`}>
      <div className="w-full h-2 rounded-full bg-secondary-surface-200">
        <div
          className={`h-full rounded-full transition-all duration-1000 ease-linear ${getBarColor()}`}
          style={{ width: `${percentage}%` }}
        ></div>
      </div>
    </div>
  );
};
