import { ModalSheet, TacoImage } from '@components/molecules';
import { useDispatch, useSelector } from 'react-redux';
import { ModalSheetSelectors, closePointsModal } from '@store/slices';
import { Vitral } from '@assets/svg';
import { AnimatedCounter, Button, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { motion } from  'framer-motion';
import AnimatedButton from '../../atoms/AnimatedButton/AnimatedButton';

export const ModalSheetPointsGlobal = () => {
  const dispatch = useDispatch();
  const pointModals = useSelector(ModalSheetSelectors.getPointsModals);

  const { t } = useTranslation('common');

  return (
    <>
      {pointModals.map(({ id, title, isOpen, pointsEarned }) => {
        return (
          <ModalSheet
            key={id}
            isOpen={isOpen}
            onClose={() => dispatch(closePointsModal(id))}
            className={`bg-gradient-radial from-[#6E2AB1] to-[#8B3ED8]`}
          >
            <img
              src={Vitral}
              alt='arc'
              className='absolute -top-7 right-0 -z-10 select-none opacity-50 user-drag-none'
            />
            <div className='flex w-full flex-col py-8 pt-24 [&>*]:text-center [&>*]:!text-secondary-text-100'>
              <motion.div
                className='[&>*]:!text-secondary-text-100'
                initial={{ scale:0.1, opacity:0 }}
                animate={{ scale:1, opacity:1 }}
                transition={{
                  type: "spring", 
                  stiffness: 300, 
                  damping: 20,
                  delay: 0.3,
                }}
                >
                <Typography
                  variant='h1'
                  className='mb-12 text-[32px] leading-[36px]'
                >
                  {title}
                </Typography>
              </motion.div>

              <motion.div
              initial={{ scale:0.1, opacity:0, rotate: 90}}
              animate={{ scale:1, opacity:1, rotate: 0 }}
              transition={{
                type: "spring", 
                stiffness: 200, 
                damping: 15,
                delay: 0.5,
              }}
                >
                <TacoImage />
              </motion.div>

              <motion.div
                className='[&>*]:!text-secondary-text-100'
                initial={{ scale:0.1, opacity:0 }}
                animate={{ scale:1, opacity:1 }}
                transition={{
                  type: "spring", 
                  stiffness: 300, 
                  damping: 20,
                  duration: 10,
                  delay: 0.7,
                }}
                >
                <Typography
                  variant='span'
                  className='my-4 text-[20px] leading-[24px]'
                >
                  {t('labels.earnedTacoPoints')}
                </Typography>
              </motion.div>

              <motion.div
                className='[&>*]:!text-secondary-text-100 '
                initial={{ scale:0.1, opacity:0 }}
                animate={{ scale:1, opacity:1 }}
                transition={{
                  type: "spring", 
                  stiffness: 300, 
                  damping: 20,
                  duration: 1,
                  delay: 0.9,
                }}
                >
                <AnimatedCounter targetValue={pointsEarned} duration={2} className='text-[48px] leading-[52px] text-white'/>
              </motion.div>

              <motion.div
                className='[&>*]:!text-secondary-text-100 mt-12'
                initial={{ scale:0.1, opacity:0 }}
                animate={{ scale:1, opacity:1 }}
                transition={{
                  type: "spring", 
                  stiffness: 300, 
                  damping: 20,
                  duration: 1,
                  delay: 1,
                }}
                >
              <AnimatedButton
                className='mt-12'
                type='button'
                onClick={() => dispatch(closePointsModal(id))}
                text={t('button.finish')}
                style='outline'
              />
              </motion.div>
            </div>
          </ModalSheet>
        );
      })}
    </>
  );
};
