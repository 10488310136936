import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

interface OnboardingState {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
  guardians: GuardianState[];
  classCode: string;
  className: string;
  avatar: number | null;
}

interface GuardianState {
  relationship: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

const initialState: OnboardingState = {
  firstName: '',
  lastName: '',
  username: '',
  email: '',
  password: '',
  confirmPassword: '',
  guardians: [
    {
      relationship: 0,
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
    },
  ],
  classCode: '',
  className: '',
  avatar: null,
};

const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    resetOnboarding: () => initialState,
    setName: (state, action: PayloadAction<OnboardingState['firstName']>) => {
      state.firstName = action.payload;
    },
    setLastName: (
      state,
      action: PayloadAction<OnboardingState['lastName']>
    ) => {
      state.lastName = action.payload;
    },
    setUsername: (
      state,
      action: PayloadAction<OnboardingState['username']>
    ) => {
      state.username = action.payload;
    },
    setEmail: (state, action: PayloadAction<OnboardingState['email']>) => {
      state.email = action.payload;
    },
    setPassword: (
      state,
      action: PayloadAction<OnboardingState['password']>
    ) => {
      state.password = action.payload;
    },
    setConfirmPassword: (
      state,
      action: PayloadAction<OnboardingState['confirmPassword']>
    ) => {
      state.confirmPassword = action.payload;
    },
    setClassCode: (
      state,
      action: PayloadAction<OnboardingState['classCode']>
    ) => {
      state.classCode = action.payload;
    },
    setClassName: (
      state,
      action: PayloadAction<OnboardingState['className']>
    ) => {
      state.className = action.payload;
    },
    setAvatar: (state, action: PayloadAction<OnboardingState['avatar']>) => {
      state.avatar = action.payload;
    },
    addGuardian: state => {
      state.guardians.push({
        relationship: 0,
        firstName: '',
        lastName: '',
        phoneNumber: '',
        email: '',
      });
    },
    updateGuardian: (state, action) => {
      const { index, field, value } = action.payload;
      state.guardians[index][field] = value;
    },
    deleteGuardian: (state, action) => {
      state.guardians.splice(action.payload, 1);
    },
  },
});

export const OnboardingSelectors = {
  getFirstName: (state: RootState) => state.onboarding.firstName,
  getLastName: (state: RootState) => state.onboarding.lastName,
  getUsername: (state: RootState) => state.onboarding.username,
  getEmail: (state: RootState) => state.onboarding.email,
  getPassword: (state: RootState) => state.onboarding.password,
  getConfirmPassword: (state: RootState) => state.onboarding.confirmPassword,
  getClassCode: (state: RootState) => state.onboarding.classCode,
  getClassName: (state: RootState) => state.onboarding.className,
  getGuardians: (state: RootState) => state.onboarding.guardians,
  getAvatar: (state: RootState) => state.onboarding.avatar,
  getOnboarding: (state: RootState) => state.onboarding,
};

export const {
  resetOnboarding,
  setName,
  setLastName,
  setUsername,
  setEmail,
  setPassword,
  setConfirmPassword,
  addGuardian,
  setAvatar,
  updateGuardian,
  deleteGuardian,
  setClassCode,
  setClassName,
} = onboardingSlice.actions;

export const OnboardingReducer = onboardingSlice.reducer;
