export const ShopAnalytics = {
  // ACTIONS
  actions: {
    shop: 'Shop',
  },

  // CATEGORIES
  categories: {
    purchasedItem: 'Purchased Item',
  },

  // LABELS
  labels: {
    purchasedItem: ':date; :userID; :itemID;',
  },
};

export const purchasedItemLabel = (
  date: string,
  userId: string,
  itemId: string
) => {
  return ShopAnalytics.labels.purchasedItem
    .replace(':date', date)
    .replace(':userID', userId)
    .replace(':itemID', itemId);
};
