import { Typography, Input } from '@components/atoms';
import { SignupProps } from './index';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useSignup } from '@hooks/index';
import { SignupTemplate, OnboardingBlocker } from '@components/templates';
import {
  InputType,
  PAGE_NAMES,
  PASSWORD_MIN_LENGTH,
  SIGNUP_STEPS_VALUES,
} from '@domain/constants';
import { useTranslation } from 'react-i18next';
import {
  nameRules,
  usernameRules,
  passwordRules,
  studentEmailRules,
  trackGA4PageView,
} from '@data/utils';
import { useSelector } from 'react-redux';
import { OnboardingSelectors } from '@store/slices';
import { useEffect } from 'react';

type Inputs = {
  firstName: string;
  lastName: string;
  username: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export const SignupUserPage = ({
  handleNextStep,
  handlePrevStep,
}: SignupProps) => {
  const {
    setEmailInput,
    setPasswordInput,
    setNameInput,
    setConfirmPasswordInput,
    setLastNameInput,
    setUsernameInput,
    checkUserExistsMutation,
  } = useSignup();

  const className = useSelector(OnboardingSelectors.getClassName);
  const { t } = useTranslation(['pages/auth/signup/signupUserPage']);
  const userOnboarding = useSelector(OnboardingSelectors.getOnboarding);

  const onSubmit: SubmitHandler<Inputs> = (data: Inputs) => {
    setNameInput(data.firstName);
    setLastNameInput(data.lastName);
    setUsernameInput(data.username);
    setEmailInput(data.email);
    setPasswordInput(data.password);
    setConfirmPasswordInput(data.confirmPassword);
  };

  const handleSubmitForm = async (data: Inputs) => {
    onSubmit(data);
    await checkUserExistsMutation.mutateAsync({
      email: data.email,
      username: data.username,
    });
    handleNextStep();
  };

  const {
    handleSubmit,
    control,
    watch,
    trigger,
    getValues,
    formState: { isValid, touchedFields },
  } = useForm<Inputs>({
    defaultValues: {
      firstName: userOnboarding.firstName,
      lastName: userOnboarding.lastName,
      username: userOnboarding.username,
      email: userOnboarding.email,
      password: userOnboarding.password,
      confirmPassword: userOnboarding.confirmPassword,
    },
    mode: 'onChange',
  });

  const { password, confirmPassword, email, username } = watch();

  useEffect(() => {
    if (confirmPassword) {
      trigger('confirmPassword');
    }
  }, [password, trigger, confirmPassword]);

  useEffect(() => {
    if (touchedFields.email || touchedFields.username) {
      trigger('email');
      trigger('username');
    }
  }, [trigger, email, username, touchedFields.email, touchedFields.username]);

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: '/signup',
      title: PAGE_NAMES.signupUserPage,
    });
  }, []);

  const validateAtLeastOneField = () => {
    const { email, username } = getValues();
    if (email || username) {
      return true;
    }
    return t('errors.atLeastOne');
  };

  return (
    <>
      <SignupTemplate
        handleBack={handlePrevStep}
        currentStep={SIGNUP_STEPS_VALUES.USER_INFORMATION}
        maxSteps={Object.keys(SIGNUP_STEPS_VALUES).length}
        disabled={!isValid || checkUserExistsMutation.isPending}
        buttonLoading={checkUserExistsMutation.isPending}
        formId='signup-user-form'
      >
        <Typography variant='h1'>{t('title', { className })}</Typography>
        <Typography variant='span'>{t('subtitle')}</Typography>
        <form
          className='w-full space-y-2'
          onSubmit={handleSubmit(handleSubmitForm)}
          id='signup-user-form'
        >
          <Input
            control={control}
            name='firstName'
            label={t('inputs.name')}
            rules={nameRules(
              t('errors.name'),
              t('inputs.errors.invalid', {
                inputName: 'first name',
                ns: 'common',
              })
            )}
          />
          <Input
            control={control}
            name='lastName'
            label={t('inputs.lastName')}
            rules={nameRules(
              t('errors.lastName'),
              t('inputs.errors.invalid', {
                inputName: 'last name',
                ns: 'common',
              })
            )}
          />
          <Input
            control={control}
            name='username'
            label={t('inputs.username')}
            rules={{
              ...usernameRules(),
              validate: validateAtLeastOneField,
            }}
          />
          <Input
            control={control}
            name='email'
            label={t('inputs.email')}
            rules={{
              ...studentEmailRules(
                t('inputs.errors.invalid', {
                  inputName: 'email',
                  ns: 'common',
                })
              ),
              validate: validateAtLeastOneField,
            }}
            inputMode='email'
          />
          <Input
            control={control}
            name='password'
            label={t('inputs.password')}
            type={InputType.password}
            rules={passwordRules(
              t('errors.password'),
              t('errors.minLengthPassword', {
                minLength: PASSWORD_MIN_LENGTH,
              }),
              t('inputs.errors.invalid', {
                inputName: 'password',
                ns: 'common',
              })
            )}
          />
          <Input
            control={control}
            name='confirmPassword'
            label={t('inputs.confirmPassword')}
            type={InputType.password}
            rules={{
              required: {
                value: true,
                message: t('errors.confirmPassword'),
              },
              validate: {
                matchPassword: (value: string) =>
                  value === watch('password')
                    ? true
                    : t('errors.confirmPassword'),
              },
            }}
          />
        </form>
      </SignupTemplate>
      <OnboardingBlocker />
    </>
  );
};
