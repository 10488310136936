import { ReactNode } from 'react';
import { Loader } from '../Loader/Loader';

interface InnerShadowContainerProps {
  children: ReactNode;
  className?: string;
  isLoading?: boolean;
  onClick?: () => void;
}

export const InnerShadowContainer = ({
  children,
  className,
  isLoading,
  onClick,
}: InnerShadowContainerProps) => {
  return (
    <div
      className={`rounded-xl bg-secondary-surface-100 px-4 py-6 shadow-inner ${className}`}
      onClick={onClick}
    >
      {isLoading ? (
        <div className='flex h-44 items-center justify-center'>
          <Loader />
        </div>
      ) : (
        children
      )}
    </div>
  );
};
