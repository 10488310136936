import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

interface ModalCloseLessonState {
  isOpen: boolean;
  isShowing: boolean;
  isBackButton: boolean;
  isExiting: boolean;
}

const initialState: ModalCloseLessonState = {
  isOpen: false,
  isShowing: true,
  isBackButton: false,
  isExiting: false,
};

const modalCloseLesson = createSlice({
  name: 'modalCloseLesson',
  initialState,
  reducers: {
    openModalCloseLesson: state => {
      state.isOpen = true;
    },
    closeModalCloseLesson: state => {
      state.isOpen = false;
    },
    removeShowingModalCloseLesson: state => {
      state.isShowing = false;
    },
    setExitingModalCloseLesson: (
      state,
      action: PayloadAction<ModalCloseLessonState['isExiting']>
    ) => {
      state.isExiting = action.payload;
    },
    handleBackButton: (
      state,
      action: PayloadAction<ModalCloseLessonState['isBackButton']>
    ) => {
      state.isBackButton = action.payload;
    },
    resetModalCloseLesson: () => initialState,
  },
});

export const ModalCloseLessonSelectors = {
  getIsOpen: (state: RootState) => state.modalCloseLesson.isOpen,
  getIsShowing: (state: RootState) => state.modalCloseLesson.isShowing,
  getIsBackButton: (state: RootState) => state.modalCloseLesson.isBackButton,
  getIsExiting: (state: RootState) => state.modalCloseLesson.isExiting,
};

export const {
  openModalCloseLesson,
  closeModalCloseLesson,
  removeShowingModalCloseLesson,
  handleBackButton,
  setExitingModalCloseLesson,
} = modalCloseLesson.actions;

export const ModalCloseLessonReducer = modalCloseLesson.reducer;
