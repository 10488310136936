import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';
import { default as WordcloudGraph } from '@visx/wordcloud/lib/Wordcloud';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);
const pink = fullConfig.theme.colors.secondary.phaseone[400];
const blue = fullConfig.theme.colors.secondary.phasetwo[400];
const yellow = fullConfig.theme.colors.secondary.phasethree[400];
const red = fullConfig.theme.colors.secondary.phasefour[400];
const orange = fullConfig.theme.colors.secondary.phasefive[400];
const green = fullConfig.theme.colors.secondary.phasesix[400];
const purple = fullConfig.theme.colors.primary[400];

interface WordcloudProps {
  words: WordData[];
  width: number;
  height: number;
  colors?: string[];
  font?: string;
  fixedValueGenerator?: number;
  containerStyle?: string;
}

interface WordData {
  text: string;
  value: number;
}

export const Wordcloud = ({
  words,
  colors = [purple, pink, blue, yellow, red, orange, green],
  font = 'Freeman',
  width,
  height,
  fixedValueGenerator = 0,
  containerStyle,
}: WordcloudProps) => {
  const fontScale = scaleLog({
    domain: [
      Math.min(...words.map(w => w.value)),
      Math.max(...words.map(w => w.value)),
    ],
    range: [20, 50],
  });

  const fixedValueGeneratorFn = () => fixedValueGenerator;

  const fontSizeSetter = (datum: WordData) => fontScale(datum.value);

  return (
    <div className={containerStyle}>
      <WordcloudGraph
        width={width}
        height={height}
        words={words}
        fontSize={fontSizeSetter}
        font={font}
        padding={2}
        spiral='rectangular'
        rotate={0}
        random={fixedValueGeneratorFn}
      >
        {cloudWords =>
          cloudWords.map((w, i) => (
            <Text
              key={w.text}
              fill={colors[i % colors.length]}
              textAnchor={'middle'}
              transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
              fontSize={w.size}
              fontFamily={w.font}
            >
              {w.text}
            </Text>
          ))
        }
      </WordcloudGraph>
    </div>
  );
};
