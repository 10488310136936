import { IconType } from 'react-icons/lib';
import { Typography } from '@components/atoms';

interface UserInfoProps {
  text: string;
  icon: IconType;
  href?: string;
}

export const UserInfo = ({ text, icon, href }: UserInfoProps) => {
  const Icon = icon;

  return (
    <div className='flex items-center gap-2 [&>*]:text-secondary-text-300'>
      <Icon size={16} className='flex-shrink-0' />
      <Typography
        variant={href ? 'a' : 'span'}
        href={href ?? ''}
        className='text-wrap-ellipsis'
      >
        {text}
      </Typography>
    </div>
  );
};
