import { Typography } from '@components/atoms';
import { TacoIcon } from '@assets/svg';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserSelectors } from 'src/data/store/slices';

export const PointsCard = () => {
  const studentTotalPoints = useSelector(UserSelectors.getAvailablePoints);
  const { t } = useTranslation('pages/home/profile/profilePage');

  return (
    <div className='flex items-center justify-between rounded-xl bg-primary-400 px-4 py-2 [&_*]:!font-medium [&_*]:!text-secondary-text-100'>
      <div className='flex gap-2'>
        <img src={TacoIcon} />
        <Typography variant='span' className='font-bold'>
          {t('totalPoints')}
        </Typography>
      </div>
      <Typography variant='span'>{studentTotalPoints.toString()}</Typography>
    </div>
  );
};
