import { ActivitySubtype, ActivityType } from '@domain/constants';

export const getLessonTextSubtype = (
  type: ActivityType,
  subtype: ActivitySubtype,
  t: any
) => {
  switch (type) {
    case ActivityType.SLIDE:
      switch (subtype) {
        case ActivitySubtype.TEXT:
          return t('subtitles.text');
        case ActivitySubtype.VIDEO:
          return t('subtitles.video');
        case ActivitySubtype.IMAGE:
          return t('subtitles.image');
        default:
          return t('subtitles.default');
      }
    case ActivityType.ACTIVITY:
      switch (subtype) {
        case ActivitySubtype.WORD_CLOUD:
          return t('subtitles.wordCloud');
        case ActivitySubtype.PIN_IT:
          return t('subtitles.pinIt');
        case ActivitySubtype.POLL:
          return t('subtitles.poll');
        case ActivitySubtype.OPEN_QUESTION:
          return t('subtitles.openQuestion');
        case ActivitySubtype.QUIZ:
          return t('subtitles.quiz');
        default:
          return t('subtitles.default');
      }
  }
};
