import { usePubNub } from 'pubnub-react';
import { useState, useEffect, useCallback } from 'react';
import {
  ActivityPayload,
  CustomMessageEvent,
  SlideActionType,
  SlideBodyType,
} from '@domain/constants';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';

interface SlideControlProps {
  channel: string;
}

export const useSlideControls = ({ channel }: SlideControlProps) => {
  const [step, setStep] = useState(-1);

  const pubnub = usePubNub();

  const userId = useSelector(UserSelectors.getId);

  const handleStep = useCallback((event: CustomMessageEvent) => {
    const body = event.message.body;

    if (
      body.type === SlideBodyType.SLIDE &&
      event.message.action === SlideActionType.CHANGE_SLIDE
    ) {
      setStep(body.slide_id!);
    }
  }, []);

  const handleSlide = (slide: number) => {
    try {
      const messagePayload: ActivityPayload = {
        uuid: userId,
        action: SlideActionType.CHANGE_SLIDE,
        body: {
          type: SlideBodyType.SLIDE,
          slide_id: slide,
        },
        subscribed_channel: channel,
        actual_channel: channel,
      };

      pubnub.publish({
        channel: channel,
        message: messagePayload,
      });
    } catch (error) {
      console.log(error);
      throw error;
    }
  };

  useEffect(() => {
    const listenerParams = {
      message: handleStep,
    };

    pubnub.addListener(listenerParams);
    pubnub.subscribe({ channels: [channel] });

    return () => {
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, channel, handleStep]);

  return {
    step,
    handleSlide,
    setStep,
  };
};
