import PinFieldComponent, {
  PinFieldProps as PinFieldPropsType,
} from 'react-pin-field';

interface PinFieldProps extends PinFieldPropsType {
  containerStyle?: string;
  inputStyle?: string;
  inputRef?: React.RefObject<HTMLInputElement[]>;
  hasError?: boolean;
}

export const PinField = ({
  inputRef,
  containerStyle,
  inputStyle,
  hasError,
  length = 6,
  ...props
}: PinFieldProps) => {
  const errorStyle = hasError
    ? 'border-secondary-semantic-error-400 focus:secondary-semantic-error-400 focus:outline-0'
    : '';
  return (
    <div className={`flex w-full justify-between ${containerStyle}`}>
      <PinFieldComponent
        className={`h-[52px] w-[52px] rounded-xl border border-primary-200 bg-secondary-surface-200 text-center text-lg font-medium shadow-inner focus:border-primary-400 focus:outline-0 ${inputStyle} ${errorStyle}`}
        ref={inputRef}
        length={length}
        format={code => code.toUpperCase()}
        {...props}
      />
    </div>
  );
};
