import { UserInfo } from '@components/molecules';
import {
  MdEdit,
  MdEmail,
  MdPerson,
  MdPersonAddAlt1,
  MdPhone,
} from 'react-icons/md';
import { formatPhoneNumber } from '@data/utils';
import { useTranslation } from 'react-i18next';
import { ProfileCard, Divider, Typography, Button } from '@components/atoms';
import { useProfile } from '@hooks/index';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { UserRole } from '@domain/constants';

interface ParentsInformationProps {
  parents: Parent[];
  className?: string;
}

interface Parent {
  id: number;
  relationship: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

const ParentsInformationEmptyState = ({ onClick }: { onClick: () => void }) => {
  const { t } = useTranslation(
    'pages/home/profile/student/details/detailsPage'
  );

  const userRole = useSelector(UserSelectors.getRole);

  return (
    <div className='m-10 grid place-items-center gap-2'>
      <MdPersonAddAlt1 size={40} className='flex-shrink-0 text-primary-300' />
      <Typography variant='span' className='text-center'>
        {userRole === UserRole.STUDENT
          ? t('emptyState.noParents')
          : t('emptyState.noContacts')}
      </Typography>
      {userRole === UserRole.STUDENT && (
        <Button
          style='link'
          type='button'
          text={t('buttons.completeNow')}
          onClick={onClick}
        />
      )}
    </div>
  );
};

export const ParentsInformation = ({
  parents,
  className,
}: ParentsInformationProps) => {
  const { t } = useTranslation(
    'pages/home/profile/student/details/detailsPage'
  );

  const userRole = useSelector(UserSelectors.getRole);

  const { navigateToParents } = useProfile();

  return (
    <ProfileCard className={className}>
      <div className='mb-3 flex items-center justify-between'>
        <Typography
          variant='span'
          className='text-[16px] font-medium leading-[20px]'
        >
          {userRole === UserRole.STUDENT
            ? t('parentInformation')
            : t('contact')}
        </Typography>
        {parents && parents.length > 0 && userRole === UserRole.STUDENT && (
          <MdEdit
            size={24}
            className='flex-shrink-0 text-secondary-buttons-100'
            onClick={navigateToParents}
          />
        )}
      </div>
      <div>
        {parents && parents.length > 0 ? (
          parents.map(
            (
              { id, relationship, email, firstName, lastName, phoneNumber },
              index
            ) => (
              <div key={id} className='space-y-2'>
                {parents.length > 1 && index !== 0 && (
                  <Divider className='scale-y-50' />
                )}
                <Typography variant='span' className='font-medium capitalize'>
                  {relationship.replace('_', ' ')}
                </Typography>
                <UserInfo text={`${firstName} ${lastName}`} icon={MdPerson} />
                <UserInfo
                  href={`tel:${phoneNumber}`}
                  text={formatPhoneNumber(phoneNumber)}
                  icon={MdPhone}
                />
                <UserInfo
                  href={`mailto:${email}`}
                  text={email}
                  icon={MdEmail}
                />
              </div>
            )
          )
        ) : (
          <ParentsInformationEmptyState onClick={navigateToParents} />
        )}
      </div>
    </ProfileCard>
  );
};
