import { Avatar } from '@components/atoms';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { MdEdit } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useMemo } from 'react';

interface PositionedAvatarProps {
  isEdit?: boolean;
}

export const PositionedAvatar = ({ isEdit }: PositionedAvatarProps) => {
  const userAvatar = useSelector(UserSelectors.getAvatar);
  const navigate = useNavigate();

  const navigateToEditAvatar = useMemo(
    () => () => navigate(PROTECTED_ROUTES_PATHS.profileAvatar),
    [navigate]
  );

  return (
    <div className='fixed right-2/4 top-[130px] z-50 flex flex-col'>
      <Avatar
        containerStyle='absolute self-center -top-20 rounded-full shadow-general'
        className='border-[3px] border-secondary-buttons-600'
        image={userAvatar}
      />
      {isEdit && (
        <div className='absolute -top-3 left-5  rounded-full bg-white p-2 shadow-general'>
          <MdEdit
            size={24}
            className='flex-shrink-0 text-primary-400'
            role='button'
            onClick={navigateToEditAvatar}
          />
        </div>
      )}
    </div>
  );
};
