import { useTranslation } from 'react-i18next';
import { Typography } from '@components/atoms';

interface OpenQuestionResponsesProps {
  responses: Record<string, string>[];
}

interface ResponseContainerProps {
  username: string;
  response: string;
}

const ResponseContainer = ({ username, response }: ResponseContainerProps) => {
  return (
    <div className='flex w-fit flex-col gap-2 rounded-xl bg-secondary-surface-200 p-2.5'>
      <Typography variant='span' className='font-bold'>
        {username}
      </Typography>
      <Typography variant='span'>{response}</Typography>
    </div>
  );
};

export const OpenQuestionResponses = ({
  responses,
}: OpenQuestionResponsesProps) => {
  const { t } = useTranslation('components/molecules/openQuestionResponse');

  return (
    <div className='h-full space-y-2 overflow-auto rounded-xl bg-secondary-surface-100 p-4'>
      <Typography variant='span' className='font-bold'>
        {t('responses')}
      </Typography>
      {responses.map((response, index) => (
        <ResponseContainer
          key={index}
          username={response.username}
          response={response.response}
        />
      ))}
    </div>
  );
};
