import React, { useRef, useState, useEffect } from 'react';
import { useDrag } from 'react-dnd';
import { itemTypes } from '@domain/constants';

interface DraggableWordProps {
  word: string;
}

const DraggableWord: React.FC<DraggableWordProps> = ({ word }) => {
  const [isDragging, setIsDragging] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);
  const dragPreviewRef = useRef<HTMLDivElement>(null);

  const [, drag] = useDrag(() => ({
    type: itemTypes.WORD,
    item: { word },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [word]);

  const handleTouchStart = (e: React.TouchEvent) => {
    setIsDragging(true);
    if (dragPreviewRef.current) {
      dragPreviewRef.current.style.display = 'block';
      updateDragPreviewPosition(e.touches[0].clientX, e.touches[0].clientY);
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging) return;
    const touch = e.touches[0];
    updateDragPreviewPosition(touch.clientX, touch.clientY);

    const dropZones = document.querySelectorAll('.drop-zone');
    dropZones.forEach((zone) => {
      const rect = zone.getBoundingClientRect();
      if (
        touch.clientX >= rect.left &&
        touch.clientX <= rect.right &&
        touch.clientY >= rect.top &&
        touch.clientY <= rect.bottom
      ) {
        (zone as HTMLElement).style.backgroundColor = 'rgba(0, 255, 0, 0.2)';
      } else {
        (zone as HTMLElement).style.backgroundColor = '';
      }
    });
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    setIsDragging(false);
    if (dragPreviewRef.current) {
      dragPreviewRef.current.style.display = 'none';
    }
    const touch = e.changedTouches[0];
    const dropZones = document.querySelectorAll('.drop-zone');
    dropZones.forEach((zone) => {
      const rect = zone.getBoundingClientRect();
      if (
        touch.clientX >= rect.left &&
        touch.clientX <= rect.right &&
        touch.clientY >= rect.top &&
        touch.clientY <= rect.bottom
      ) {
        (zone as HTMLElement).dispatchEvent(new CustomEvent('drop', { detail: { word } }));
      }
      (zone as HTMLElement).style.backgroundColor = '';
    });
  };

  const updateDragPreviewPosition = (x: number, y: number) => {
    if (dragPreviewRef.current) {
      dragPreviewRef.current.style.left = `${x}px`;
      dragPreviewRef.current.style.top = `${y}px`;
    }
  };

  useEffect(() => {
    const handleTouchMove = (e: TouchEvent) => {
      if (isDragging) {
        e.preventDefault();
      }
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });
    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, [isDragging]);

  drag(ref);

  if (!word) {
    return null;
  }

  return (
    <>
      <span
        ref={ref}
        className={`
          inline-flex items-center justify-center
          px-4 py-2
          rounded-xl
          bg-base-100
          border border-primary-200
          mx-1
          cursor-move
          transition-opacity duration-200
          ${isDragging ? 'opacity-50' : 'opacity-100'}
        `}
        style={{
          minWidth: '69px',
          height: '40px',
        }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        {word}
      </span>
      <div
        ref={dragPreviewRef}
        className="
          fixed pointer-events-none z-50
          bg-base-100 bg-opacity-80
          px-4 py-2
          border border-primary-200 rounded-xl
          hidden
        "
        style={{
          transform: 'translate(-50%, -50%)',
        }}
      >
        {word}
      </div>
    </>
  );
};

export default DraggableWord;
