export const achievementsAdapter = (response: any) => {
  const adaptedAchievements = response.achievements.map((achievement: any) => {
    return {
      id: achievement.id,
      title: achievement.title,
      description: achievement.description,
      type: achievement.achievement_type,
      state: achievement.state,
      imageURL: achievement.image_url,
      levels: achievement.levels.map((level: any) => ({
        id: level.id,
        level: level.level,
        valueRequirement: level.value_requirement,
        acquiredByStudent: level.acquired_by_student,
      })),
    };
  });

  const adaptedStats = {
    dailyPrayerStreak: response.stats.daily_prayer_streak,
    lessonsCompleted: response.stats.lesson_completed,
    modulesCompleted: response.stats.module_completed,
    pointLandmarks: response.stats.point_landmarks,
    retosCompleted: response.stats.retos_completed,
  };

  return {
    achievements: adaptedAchievements,
    stats: adaptedStats,
  };
};

export const achievedBadgesAdapter = (response: any) => {
  const { achievement } = response;
  const adaptedLessonCompletion = achievement.lesson_completion
    ? {
        id: achievement.lesson_completion.id,
        title: achievement.lesson_completion.title,
        description: achievement.lesson_completion.description,
        achievementType: achievement.lesson_completion.achievement_type,
        state: achievement.lesson_completion.state,
        imageURL: achievement.lesson_completion.image_url,
        levels: achievement.lesson_completion.levels,
        level: {
          id: achievement.lesson_completion.level.id,
          level: achievement.lesson_completion.level.level,
          valueRequirement:
            achievement.lesson_completion.level.value_requirement,
        },
      }
    : null;

  const adaptedModuleCompletion = achievement.module_completion
    ? {
        id: achievement.module_completion.id,
        title: achievement.module_completion.title,
        description: achievement.module_completion.description,
        achievementType: achievement.module_completion.achievement_type,
        state: achievement.module_completion.state,
        imageURL: achievement.module_completion.image_url,
        levels: achievement.module_completion.levels,
        level: {
          id: achievement.module_completion.level.id,
          level: achievement.module_completion.level.level,
          valueRequirement:
            achievement.module_completion.level.value_requirement,
        },
      }
    : null;

  const adaptedPointLandmarks = achievement.point_landmarks
    ? {
        id: achievement.point_landmarks.id,
        title: achievement.point_landmarks.title,
        description: achievement.point_landmarks.description,
        achievementType: achievement.point_landmarks.achievement_type,
        state: achievement.point_landmarks.state,
        imageURL: achievement.point_landmarks.image_url,
        levels: achievement.point_landmarks.levels,
        level: {
          id: achievement.point_landmarks.level.id,
          level: achievement.point_landmarks.level.level,
          valueRequirement: achievement.point_landmarks.level.value_requirement,
        },
      }
    : null;

  const adaptedAchievedBadges = {
    lessonCompletion: adaptedLessonCompletion,
    pointLandmarks: adaptedPointLandmarks,
    moduleCompletion: adaptedModuleCompletion,
  };

  return adaptedAchievedBadges;
};
