import { HomeTemplate } from '@components/templates';
import { InnerShadowContainer, ShopContainer } from '@components/atoms';
import { TitleWithAction } from '@components/molecules';
import { useQuery } from '@tanstack/react-query';
import { useShop } from '@hooks/index';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { TabSelector } from '@components/organisms';
import { useTranslation } from 'react-i18next';
import { ShopItemList } from '@components/organisms';
import { useEffect, useMemo } from 'react';
import { trackGA4PageView } from '@data/utils';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { PAGE_NAMES } from '@domain/constants';

export const ShopPage = () => {
  const studentAvailablePoints = useSelector(UserSelectors.getAvailablePoints);
  const {
    getShopLanding,
    navigateToShopAvatars,
    navigateToShopCollectibles,
    getUserPoints,
  } = useShop();
  const { t } = useTranslation([
    'pages/home/shop/shopPage',
    'components/organisms/tabSelector',
  ]);

  const shopTabs = useMemo(
    () => [
      {
        id: 'shop',
        label: t('shop', { ns: 'components/organisms/tabSelector' }),
        route: PROTECTED_ROUTES_PATHS.shop,
      },
      {
        id: 'purchased',
        label: t('purchased', { ns: 'components/organisms/tabSelector' }),
        route: PROTECTED_ROUTES_PATHS.shopPurchased,
      },
    ],
    [t]
  );

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['shopLanding'],
    queryFn: () => getShopLanding('available'),
  });

  const getUserPointsQuery = useQuery({
    queryKey: ['userPoints'],
    queryFn: getUserPoints,
  });

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.shop,
      title: PAGE_NAMES.shop,
    });
  }, []);

  return (
    <HomeTemplate>
      <ShopContainer
        childrenClassname='space-y-4'
        isLoading={isLoading || isPending}
      >
        <TabSelector tabs={shopTabs} />
        <InnerShadowContainer onClick={navigateToShopAvatars}>
          <TitleWithAction
            label={t('avatars')}
            onClick={navigateToShopAvatars}
            buttonText={t('buttons.viewAll')}
          />
          <ShopItemList
            items={data?.avatars}
            studentPoints={studentAvailablePoints}
          />
        </InnerShadowContainer>
        <InnerShadowContainer onClick={navigateToShopCollectibles}>
          <TitleWithAction
            label={t('collectibles')}
            onClick={navigateToShopCollectibles}
            buttonText={t('buttons.viewAll')}
          />
          <ShopItemList
            items={data?.collectibles}
            studentPoints={studentAvailablePoints}
          />
        </InnerShadowContainer>
      </ShopContainer>
    </HomeTemplate>
  );
};
