import { useEffect, useMemo } from 'react';
import { Loader } from '@components/atoms';
import {
  ActivityBlocker,
  ActivityController,
  LessonTemplate,
} from '@components/templates';
import PubNub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { useDispatch, useSelector } from 'react-redux';
import { ModalCloseLessonSelectors, UserSelectors } from '@store/slices';
import { useClasses, useLessons } from '@hooks/index';
import { getLessonStorage, trackGA4PageView } from '@data/utils';
import { useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { openModalCloseLesson } from '@store/slices';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { PAGE_NAMES } from '@domain/constants';

export const ActivityPage = () => {
  const userId = useSelector(UserSelectors.getId);
  const dispatch = useDispatch();
  const isShowing = useSelector(ModalCloseLessonSelectors.getIsShowing);
  const { lessonId } = useParams();
  const isBackButton = useSelector(ModalCloseLessonSelectors.getIsBackButton);
  const navigate = useNavigate();
  const { moduleId, classId } = useParams();
  const location = useLocation();

  const { getLessonDetails } = useLessons();
  const { getStudents } = useClasses();
  const { data, isLoading, isPending } = useQuery({
    queryKey: ['getLessonDetails', lessonId],
    queryFn: () => getLessonDetails(lessonId!),
    retry: false,
  });
  const { isLoading: studentsLoading, isPending: studentsPending } = useQuery({
    queryKey: ['getStudents'],
    queryFn: getStudents,
    retry: false,
  });

  const isAnyLoading =
    isLoading || isPending || studentsLoading || studentsPending;

  const pubnub = useMemo(
    () =>
      new PubNub({
        publishKey: import.meta.env.VITE_PUBNUB_PUBLISH_KEY,
        subscribeKey: import.meta.env.VITE_PUBNUB_SUBSCRIBE_KEY,
        secretKey: import.meta.env.VITE_PUBNUB_SECRET_KEY,
        userId: userId.toString(),
      }),
    [userId]
  );

  const { title, description, color, backgroundColor } = getLessonStorage();

  const handleBackButton = () => {
    navigate(
      PROTECTED_ROUTES_PATHS.lessons
        .replace(':classId', classId!)
        .replace(':moduleId', moduleId!)
    );
    pubnub.unsubscribeAll();
  };

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: location.pathname,
      title: PAGE_NAMES.activity,
    });
  }, [location.pathname]);

  useEffect(() => {
    const unloadCallback = event => {
      event.preventDefault();
      event.returnValue = '';
      return '';
    };

    window.addEventListener('beforeunload', unloadCallback);
    return () => window.removeEventListener('beforeunload', unloadCallback);
  }, []);

  return (
    <>
      <PubNubProvider client={pubnub}>
        <LessonTemplate
          title={title}
          subtitle={description}
          style={{ backgroundColor, color }}
          clickBackButton={isBackButton && handleBackButton}
          childrenClassName='!mx-0 !pt-0'
          clickCloseButton={
            isShowing ? () => dispatch(openModalCloseLesson()) : null
          }
        >
          {isAnyLoading ? (
            <Loader />
          ) : (
            <ActivityController activity={data?.lesson} />
          )}
        </LessonTemplate>
      </PubNubProvider>
      <ActivityBlocker />
    </>
  );
};
