import { useState, useLayoutEffect, useCallback } from 'react';
import { loginSuccess, logoutUser } from '@store/slices';
import { useDispatch } from 'react-redux';
import { AUTH_TOKEN_STORAGE_KEY } from '@domain/constants';
import { Api } from '@data/api';
import { WebClientRest } from '@data/api/clients';
import { HttpStatusCode } from '@domain/api';
import { loginAdapter, setupAdapter } from '@data/adapters';

export const useAuth = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
  const [isAuthChecked, setIsAuthChecked] = useState<boolean>(false);
  const dispatch = useDispatch();

  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });

  const api = new Api(apiInstance);

  const fetchUser = useCallback(async () => {
    const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
    if (token) {
      try {
        const response = await api.users.getUser();

        if (response.status === HttpStatusCode.OK) {
          const user = loginAdapter(response.data);
          dispatch(
            loginSuccess({
              ...user,
            })
          );
        }
      } catch (error) {
        if (error?.response?.status === HttpStatusCode.UNAUTHORIZED) {
          dispatch(logoutUser());
          localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
          throw error;
        }
      }
    }
  }, [dispatch, api.users]);

  const fetchPointsSetup = useCallback(async () => {
    const response = await api.setup.getPointsValues();

    if (response.status === HttpStatusCode.OK) {
      const setup = setupAdapter(response.data);

      setup.forEach(setup => {
        sessionStorage.setItem(`${setup.key}`, setup.value.toString());
      });
    }
  }, [api.setup]);

  useLayoutEffect(() => {
    if (!isAuthChecked) {
      const checkAuth = async () => {
        const token = localStorage.getItem(AUTH_TOKEN_STORAGE_KEY);
        if (token) {
          try {
            await fetchUser();
            setIsAuthenticated(true);
          } catch (error) {
            if (error?.response?.status === HttpStatusCode.UNAUTHORIZED) {
              setIsAuthenticated(false);
            }
          }
        } else {
          setIsAuthenticated(false);
        }
        setIsAuthChecked(true);
      };

      checkAuth();
      fetchPointsSetup();
    }
  }, [isAuthChecked, fetchUser, fetchPointsSetup]);

  return {
    isAuthenticated,
  };
};
