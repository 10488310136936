export const SignupAnalytics = {
  // ACTIONS
  actions: {
    createAccount: 'Create Account',
  },

  // CATEGORIES
  categories: {
    signup: 'Signup',
  },

  // LABELS
  labels: {
    createAccount: 'Student has created an account successfully',
  },
};
