import { PinActivity } from '@components/molecules';
import { SlideContentWrapper } from '../SlideContentWrapper';
import { Content } from '@domain/constants';
import { usePin } from '@hooks/index';

interface RenderPinItActivityProps {
  slide: Content;
  channel: string;
}

export const RenderPinItActivity = ({
  slide,
  channel,
}: RenderPinItActivityProps) => {
  const { markers, sendPinMarker } = usePin({ channel, slideId: slide.id });

  return (
    <SlideContentWrapper
      title={slide.pinIt!.question}
      type={slide.type}
      subtype={slide.subtype}
    >
      <PinActivity
        image={slide.pinIt!.imageURL}
        markers={markers}
        setMarkers={sendPinMarker}
        slideId={slide.id}
      />
    </SlideContentWrapper>
  );
};
