import {
  Header,
  HeaderNavigation,
  HeaderTacoPoints,
} from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { Typography } from '@components/atoms';
import { UserRole } from '@domain/constants';
import { MdArrowBack } from 'react-icons/md';

export interface HomeHeaderProps {
  isProfilePage?: boolean;
  onBackButtonClick?: () => void;
}

export const HomeHeader = ({
  isProfilePage,
  onBackButtonClick,
}: HomeHeaderProps) => {
  const { t } = useTranslation(['components/organisms/homeHeader']);
  const firstName = useSelector(UserSelectors.getFirstName);
  const role = useSelector(UserSelectors.getRole);

  return (
    <Header containerStyles='fixed z-20 bg-primary-400 flex items-end [&>*]:!text-secondary-text-100 '>
      <div className='flex w-full items-center justify-between'>
        {!isProfilePage && (
          <>
            <Typography
              variant='h1'
              className='font-bold !text-secondary-text-100'
            >
              {t('helloName', { firstName })}
            </Typography>
            {role === UserRole.STUDENT ? (
              <HeaderTacoPoints />
            ) : (
              <HeaderNavigation />
            )}
          </>
        )}
        {isProfilePage && onBackButtonClick && (
          <MdArrowBack
            size={24}
            className='flex-shrink-0 cursor-pointer rounded-full text-secondary-buttons-600'
            onClick={onBackButtonClick}
          />
        )}
      </div>
    </Header>
  );
};
