import {
  Typography,
  BorderContainer,
  RichTextTypography,
} from '@components/atoms';
import { Challenge } from '@assets/img';
import { DueDate } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { Reto } from '@domain/constants';

interface ChallengeContentProps {
  challenge: Reto;
}

export const ChallengeContent = ({ challenge }: ChallengeContentProps) => {
  const { t } = useTranslation('components/organisms/challengeContent');

  return (
    <div className='flex h-full w-full flex-col items-center justify-between'>
      <div className='grid place-items-center gap-2'>
        <Typography
          variant='h3'
          className='text-[40px] font-medium leading-[44px] !text-primary-400'
        >
          {t('reto')}
        </Typography>
        <Typography
          variant='span'
          className='text-[16px] font-medium leading-[20px] !text-primary-400'
        >
          {t(`types.${challenge.type}`)}
        </Typography>
      </div>

      <BorderContainer containerStyles='flex p-4 !w-fit items-center border-primary-200 justify-center border !border-primary-200'>
        <Typography
          variant='span'
          className='text-[18px] font-bold !text-primary-400 '
        >
          {t('winPoints', { points: challenge.pointsAwarded })}
        </Typography>
      </BorderContainer>

      <img src={Challenge} className='h-auto w-64'></img>

      <div className='flex flex-col gap-4'>
        <Typography
          variant='span'
          className='text-[18px] font-medium leading-[22px]'
        >
          {challenge.title}
        </Typography>

        {challenge.description && (
          <RichTextTypography description={challenge.description} />
        )}
      </div>

      <DueDate date={challenge.dueDate} />
    </div>
  );
};
