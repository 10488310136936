import { useState } from 'react';
import { Badge, Modal } from '@components/molecules';
import { BadgeImage, ProgressBar, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import {
  assignLevelColors,
  getAchievementLevels,
  getLevelRequiredValue,
} from '@data/utils';
import { ACHIEVEMENT_STATES } from '@domain/constants';
import { AnimatePresence, motion } from 'framer-motion';
import { AnimatedProgressBar } from '../../atoms/Animated/AnimatedProgressBar';

interface BadgeWithModalProps {
  badge: any;
  stats: any;
}

export const BadgeWithModal = ({ badge, stats }: BadgeWithModalProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const { t } = useTranslation('components/organisms/badgeWithModal');
  const { title, imageURL, description, state } = badge;
  const { maxLevel, currentLevel } = getAchievementLevels(badge.levels);

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleOpen = () => {
    setIsAnimating(false);
    setIsOpen(true);
  };

  const studentHasMaxLevel = currentLevel >= maxLevel;
  const { currentStat, requiredValue } = getLevelRequiredValue(
    badge.levels,
    stats,
    badge.type
  );

  const handleBadgeClick = () => {
    setIsAnimating(true);
  };

  return (
    <>
      <motion.div
        whileHover={{ scale: 1.2 }}
        whileTap={{ scale: 0.9 }}
        transition={{ type: 'spring', stiffness: 400, damping: 17 }}
        onClick={handleBadgeClick}
        onAnimationComplete={isAnimating ? handleOpen : undefined} // Only navigate when animation is done
      >
        <Badge
          badge={badge}
          onClick={handleOpen}
          currentStat={currentStat}
          requiredValue={requiredValue}
        />
      </motion.div>
      {isOpen && (
        <AnimatePresence>
          <Modal
            open={isOpen}
            onClose={handleClose}
            title={t('title', { title, level: currentLevel })}
          >
            <div className='flex flex-col items-center gap-4 [&_*]:text-center'>
              <BadgeImage
                className='h-24 w-24'
                src={imageURL}
                color={assignLevelColors(badge.levels)}
              />

              {!studentHasMaxLevel && state === ACHIEVEMENT_STATES.active && (
                <div className='flex w-full items-center justify-between gap-4'>
                  <AnimatedProgressBar
                    value={currentStat}
                    max={requiredValue}
                    animationDelay={0.5}
                  />
                  <Typography variant='span' className='font-bold'>
                    {currentStat.toString()}/{requiredValue.toString()}
                  </Typography>
                </div>
              )}
              <Typography variant='span'>{description}</Typography>
              {state == ACHIEVEMENT_STATES.archived ? (
                <Typography variant='span' className='font-bold'>
                  {t('limitedEdition')}
                </Typography>
              ) : !studentHasMaxLevel ? (
                <Typography variant='span' className='font-bold'>
                  {t('aimForGold', { level: maxLevel })}
                </Typography>
              ) : (
                <Typography variant='span' className='font-bold'>
                  {t('achieved')}
                </Typography>
              )}
            </div>
          </Modal>
        </AnimatePresence>
      )}
    </>
  );
};
