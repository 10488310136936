import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Api } from '@data/api';
import { WebClientRest } from '@data/api/clients';
import { NotificationsDispatcher, trackGA4Events } from '@data/utils';
import {
  purchasedItemLabel,
  ShopAnalytics,
  ToastNotificationType,
} from '@domain/constants';
import { HttpStatusCode } from '@domain/api';
import {
  loginAdapter,
  pointsAdapter,
  shopItemsByTypeAdapter,
  shopLandingAdapter,
} from '@data/adapters';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useMutation } from '@tanstack/react-query';
import {
  loginSuccess,
  updatePoints,
  UserSelectors,
} from 'src/data/store/slices';
import { useDispatch, useSelector } from 'react-redux';

export const useShop = () => {
  const navigate = useNavigate();

  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });
  const dispatch = useDispatch();
  const userId = useSelector(UserSelectors.getId);

  const { t } = useTranslation('common');

  const api = new Api(apiInstance);

  const getShopLanding = async (source: string) => {
    try {
      const response = await api.shop.getShopLanding(source);

      if (response.status === HttpStatusCode.OK) {
        return shopLandingAdapter(response.data);
      }
    } catch (error: unknown) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
    }
  };

  const getShopItemsByType = async (type: 'avatar' | 'collectible') => {
    try {
      const response = await api.shop.getShopItemsByType(type);

      if (response.status === HttpStatusCode.OK) {
        return shopItemsByTypeAdapter(response.data);
      }
    } catch (error: unknown) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
    }
  };

  const getShopPurchasedItemsByType = async (
    type: 'avatar' | 'collectible'
  ) => {
    try {
      const response = await api.shop.getShopPurchasedItemsByType(type);

      if (response.status === HttpStatusCode.OK) {
        return shopItemsByTypeAdapter(response.data);
      }
    } catch (error: unknown) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
    }
  };

  const getUserPoints = async () => {
    try {
      const response = await api.users.getUser();

      if (response.status === HttpStatusCode.OK) {
        dispatch(loginSuccess(loginAdapter(response.data)));
      }

      return true;
    } catch (error: unknown) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });

      throw error;
    }
  };

  const purchaseShopItem = async (id: string) => {
    try {
      const response = await api.shop.purchaseShopItem(id);

      if (response.status === HttpStatusCode.OK) {
        dispatch(updatePoints(pointsAdapter(response.data)));

        trackGA4Events(
          ShopAnalytics.categories.purchasedItem,
          ShopAnalytics.actions.shop,
          purchasedItemLabel(
            new Date().toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }),
            userId.toString(),
            id
          )
        );

        return response.data;
      }
    } catch (error: unknown) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
    }
  };

  const purchaseShopItemMutation = useMutation({
    mutationKey: ['purchaseShopItem'],
    mutationFn: shopId => purchaseShopItem(shopId),
  });
  const navigateToShopAvatars = () => {
    navigate(PROTECTED_ROUTES_PATHS.shopAvatars);
  };

  const navigateToShopCollectibles = () => {
    navigate(PROTECTED_ROUTES_PATHS.shopCollectibles);
  };

  const navigateToShopPurchasedAvatars = () => {
    navigate(PROTECTED_ROUTES_PATHS.shopPurchasedAvatars);
  };

  const navigateToShopPurchasedCollectibles = () => {
    navigate(PROTECTED_ROUTES_PATHS.shopPurchasedCollectibles);
  };

  const navigateToShop = () => {
    navigate(PROTECTED_ROUTES_PATHS.shop);
  };

  const navigateToShopPurchased = () => {
    navigate(PROTECTED_ROUTES_PATHS.shopPurchased);
  };

  return {
    getShopLanding,
    navigateToShopAvatars,
    navigateToShopCollectibles,
    navigateToShopPurchasedAvatars,
    navigateToShopPurchasedCollectibles,
    getShopItemsByType,
    navigateToShop,
    getShopPurchasedItemsByType,
    navigateToShopPurchased,
    purchaseShopItemMutation,
    getUserPoints,
  };
};
