import { Navigation } from '@navigation/index';
import {
  ModalSheetInfoGlobal,
  ModalSheetPointsGlobal,
} from '@components/templates';

export const App = () => {
  return (
    <>
      <Navigation />
      <ModalSheetInfoGlobal />
      <ModalSheetPointsGlobal />
    </>
  );
};

export default App;
