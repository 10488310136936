import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

interface LessonSlice {
  isWaitingRoom: boolean;
}

const initialState: LessonSlice = {
  isWaitingRoom: true,
};

const lessonSlice = createSlice({
  name: 'lesson',
  initialState,
  reducers: {
    setWaitingRoom: (
      state,
      action: PayloadAction<LessonSlice['isWaitingRoom']>
    ) => {
      state.isWaitingRoom = action.payload;
    },

    resetLesson: () => initialState,
  },
});

export const LessonSelectors = {
  getIsWaitingRoom: (state: RootState) => state.lesson.isWaitingRoom,
};

export const { setWaitingRoom, resetLesson } = lessonSlice.actions;

export const LessonReducer = lessonSlice.reducer;
