export interface ProgressBarProps {
  containerStyle?: string;
  value: number;
  max: number;
}

export const ProgressBar = ({
  containerStyle,
  value,
  max,
}: ProgressBarProps) => {
  return (
      <progress
        className={`progress h-3 w-full bg-secondary-surface-200 shadow-inner progress-filled:bg-primary-400 progress-filled:shadow-inner ${containerStyle}`}
        value={value}
        max={max}
      ></progress>

  );
};
