import { Avatar, Card, TabLayout, Typography } from '@components/atoms';
import { TabNavigation } from '@components/organisms';
import { HomeTemplate, ModalRetoGrading } from '@components/templates';
import { useRetos } from '@hooks/index';
import { useEffect } from 'react';
import { MdChevronLeft } from 'react-icons/md';
import { useLocation, useParams } from 'react-router-dom';
import {
  getRetoPointsStorage,
  getRetoStorage,
  trackGA4PageView,
} from '@data/utils';
import { PAGE_NAMES, RETO_STATES_STR } from '@domain/constants';
import { useQuery } from '@tanstack/react-query';

export const RetoResponseDetailsPage = () => {
  const { getRetoResponseDetails, navigateToRetosResponses } = useRetos();

  const { retoId, responseId } = useParams();
  const location = useLocation();
  const retoPoints = getRetoPointsStorage();

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['retoResponseDetails', responseId],
    queryFn: getRetoResponseDetails,
    retry: false,
  });

  const { title } = getRetoStorage();
  const studentFullName = `${data?.student.firstName} ${data?.student.lastName}`;

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: location.pathname,
      title: PAGE_NAMES.retoResponseDetails,
    });
  }, [location.pathname]);

  return (
    <HomeTemplate
      childrenClassname='!px-0'
      layoutClassname='!bg-secondary-surface-100'
    >
      <TabNavigation />
      <TabLayout className='flex flex-col' isLoading={isLoading || isPending}>
        <MdChevronLeft
          size={24}
          onClick={() => navigateToRetosResponses(retoId!)}
          className='mb-4 flex-shrink-0 cursor-pointer'
        />
        <div className='flex items-center gap-4'>
          <Avatar
            className='!h-14 !w-14'
            image={data?.student.profilePictureURL}
            alt={studentFullName}
          />
          <Typography
            variant='span'
            className='text-[16px] font-bold leading-[20px]'
          >
            {studentFullName}
          </Typography>
        </div>

        <Card className='my-6 flex flex-col gap-4 bg-secondary-surface-100'>
          <Typography
            variant='h1'
            className='self-baseline text-[24px] leading-[22px]'
          >
            {title}
          </Typography>
          <Typography
            variant='span'
            className='self-baseline text-[16px] leading-[20px]'
          >
            {data?.answer}
          </Typography>
        </Card>

        {data?.state !== RETO_STATES_STR.graded && (
          <ModalRetoGrading
            studentName={studentFullName}
            points={Number(retoPoints)}
          />
        )}
      </TabLayout>
    </HomeTemplate>
  );
};
