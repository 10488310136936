const relationshipMapping = {
  mother: 0,
  father: 1,
  grandparent: 2,
  legal_guardian: 3,
  other: 4,
};

export const loginAdapter = (response: any) => {
  const { user } = response;

  const transformedUser = {
    id: user.id,
    email: user.email,
    username: user.username,
    firstName: user.first_name,
    lastName: user.last_name,
    role: user.role,
    phoneNumber: user.phone_number,
    avatar: user.profile_picture_url,
    parents: user.parents.map(parent => ({
      id: parent.id,
      firstName: parent.first_name,
      lastName: parent.last_name,
      phoneNumber: parent.phone_number,
      email: parent.email,
      relationship: parent.relationship,
    })),
    points: {
      available: user.points.available || 0,
      lifetime: user.points.lifetime || 0,
    },
  };

  return transformedUser;
};

const transformRelationship = parents => {
  return parents.map(parent => {
    const newItem = { ...parent };
    newItem.relationship = relationshipMapping[parent.relationship];
    return newItem;
  });
};

const processParents = (updatedParents: any, originalParents: any) => {
  const updatedIds = new Set(
    updatedParents.map(parent => parent.id).filter(id => id !== undefined)
  );

  const resultArray = [];

  originalParents.forEach(parent => {
    if (updatedIds.has(parent.id)) {
      const updatedParent = updatedParents.find(p => p.id === parent.id);
      resultArray.push(updatedParent);
    } else {
      resultArray.push({ ...parent, _destroy: 1 });
    }
  });

  updatedParents.forEach(parent => {
    if (parent.id === undefined) {
      resultArray.push(parent);
    }
  });

  return resultArray;
};

const formatParents = (parents: any) => {
  return parents.map(parent => ({
    id: parent.id,
    first_name: parent.firstName,
    last_name: parent.lastName,
    phone_number: parent.phoneNumber,
    email: parent.email,
    relationship: parent.relationship,
  }));
};

export const editParentsAdapter = (parents: any, oldParents: any) => {
  const transformedParentsRelationship = transformRelationship(parents.parents);

  const formattedParents = formatParents(transformedParentsRelationship);

  const updatedParents = processParents(formattedParents, oldParents);

  return {
    parents_attributes: updatedParents,
  };
};
