import { Typography } from '@components/atoms';

export interface NumericStepperProps {
  currentStep: number | string;
  maxSteps: number | string;
  containerStyles?: string;
  stepperStyles?: string;
}

export const NumericStepper = ({
  currentStep,
  maxSteps,
  containerStyles,
  stepperStyles,
}: NumericStepperProps) => {
  const baseStyle = '!text-primary-400 font-bold';
  return (
    <div className={`${containerStyles}`}>
      <Typography className={`${baseStyle} ${stepperStyles}`} variant='span'>
        {currentStep.toString()}
      </Typography>
      <Typography variant='span' className={`${baseStyle} ${stepperStyles}`}>
        /
      </Typography>
      <Typography variant='span' className={`${baseStyle} ${stepperStyles}`}>
        {maxSteps.toString()}
      </Typography>
    </div>
  );
};
