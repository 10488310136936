import { WebClientRest } from '@data/api/clients';
import { AUTH_TOKEN_STORAGE_KEY } from '@domain/constants';

interface RetoResponse {
  answer: string;
  image: string;
}

interface GradeResponse {
  points: number;
}

class RetosApi {
  private client: WebClientRest;

  constructor(client: WebClientRest) {
    this.client = client;
  }

  getRetoDetails(id: string) {
    return this.client.get(`/retos/${id}`, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getRetosResponses(id: string) {
    return this.client.get(`/retos/${id}/reto_responses`, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  getRetoResponseById(id: string) {
    return this.client.get(`/retos/${id}/reto_response`, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  submitRetoResponse(id: string, response: RetoResponse) {
    return this.client.patch(`/retos/${id}/submit`, response, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }

  gradeRetoResponse(id: string, response: GradeResponse) {
    return this.client.post(`/retos/${id}/grade`, response, {
      headers: {
        Accept: '*/*',
        Authorization: `${localStorage.getItem(AUTH_TOKEN_STORAGE_KEY)}`,
      },
    });
  }
}

export { RetosApi };
