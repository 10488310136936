export interface Setups {
  setups: Setup[];
}

export interface Setup {
  id: number;
  key: string;
  value: number;
  created_at: Date;
  updated_at: Date;
}

export const setupAdapter = (response: Setups) => {
  const adaptedSetups = response.setups.map(setup => ({
    id: setup.id,
    key: setup.key,
    value: setup.value,
  }));

  return adaptedSetups;
};
