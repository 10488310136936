import { Loader, TabLayout, Typography } from '@components/atoms';
import { TabNavigation } from '@components/organisms';
import { HomeTemplate, StudentCardList } from '@components/templates';
import { useEffect, useState } from 'react';
import { getClassStorage, trackGA4PageView } from '@data/utils';
import { useClasses } from '@hooks/index';
import { ModalSheet } from '@components/molecules';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ModalSheetStudent } from '@components/templates';
import { PAGE_NAMES } from '@domain/constants';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

export const StudentsPage = () => {
  const [isOpen, setOpen] = useState<boolean>(false);

  const { name, publicUid } = getClassStorage();
  const { getStudents, getStudentById } = useClasses();
  const location = useLocation();
  const { t } = useTranslation('pages/home/home/classes/students/studentsPage');
  const { data, isLoading, isPending } = useQuery({
    queryKey: ['getStudents'],
    queryFn: getStudents,
    retry: false,
  });
  const [selectedStudentId, setSelectedStudentId] = useState<number>();

  const {
    data: selectedStudent,
    isLoading: isLoadingSelectedStudent,
    isPending: isPendingSelectedStudent,
    refetch: refetchSelectedStudent,
  } = useQuery({
    queryKey: ['getStudentById', publicUid, selectedStudentId],
    queryFn: () => getStudentById(publicUid, selectedStudentId!),
    enabled: false,
    retry: false,
  });

  useEffect(() => {
    if (isOpen && selectedStudentId !== undefined) {
      refetchSelectedStudent();
    }
  }, [refetchSelectedStudent, isOpen, selectedStudentId]);

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: location.pathname,
      title: PAGE_NAMES.students,
    });
  }, [location.pathname]);

  return (
    <HomeTemplate
      childrenClassname='!px-0'
      layoutClassname='!bg-secondary-surface-100'
    >
      <TabNavigation />
      <TabLayout
        className='flex flex-1 flex-col'
        isLoading={isLoading || isPending}
      >
        <motion.div
          initial={{ y: '-50%', opacity: 0 }} // Start off-screen to the left
          animate={{ y: 0, opacity: 1 }} // Slide to the original position
          transition={{
            duration: 0.3,
            ease: 'easeInOut',
          }}
        >
          <div className='mb-4'>
            <Typography variant='h1'>{name}</Typography>
            <Typography variant='span' className='!text-secondary-buttons-700'>
              {t('totalStudents')}: {data?.length}
            </Typography>
          </div>
        </motion.div>

        <StudentCardList
          students={data}
          onClick={id => {
            setSelectedStudentId(id);
            setOpen(true);
          }}
          nameStyle='!font-normal'
          showBadges
          showScores
        />
      </TabLayout>

      <ModalSheet
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        onCloseEnd={() => setSelectedStudentId(undefined)}
      >
        {isLoadingSelectedStudent || isPendingSelectedStudent ? (
          <div className='h-[50vh] content-center'>
            <Loader />
          </div>
        ) : (
          <ModalSheetStudent student={selectedStudent} />
        )}
      </ModalSheet>
    </HomeTemplate>
  );
};
