import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Typography } from '../Typography/Typography';

interface AnimatedCounterProps {
  targetValue: number; // The value to count up to
  duration?: number;   // The duration of the counting animation in seconds
  className?: string;
}

const AnimatedCounter = ({ targetValue, duration = 1, className = "" }: AnimatedCounterProps) => {
  const [count, setCount] = useState(0);

  useEffect(() => {
    // Calculate the interval time based on the duration and target value
    const intervalTime = (duration * 1000) / targetValue;

    // Set up a counter that updates the state every interval
    const counter = setInterval(() => {
      setCount((prev) => {
        // Stop the interval once the count reaches the target value
        if (prev >= targetValue) {
          clearInterval(counter);
          return targetValue;
        }
        return prev + 1; // Increment the count
      });
    }, intervalTime);

    // Cleanup interval on component unmount
    return () => clearInterval(counter);
  }, [targetValue, duration]);

  return (
    <motion.div
        className={className}
        initial={{ scale: 0.8, opacity: 0 }}
        animate={{ scale: 1, opacity: 1 }}
        transition={{ type: 'spring', stiffness: 300, damping: 20 }}
    >
      <motion.span
        key={count} // Re-render each time count changes to apply animation
        initial={{ y: -20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.3 }}
      >
        <Typography variant='h2' className={className}>
            {count.toString()}
        </Typography>
      </motion.span>
    </motion.div>
  );
};

export default AnimatedCounter;
