import { Outlet, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { PUBLIC_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { IS_SPLASH_SCREEN_STORAGE_KEY } from 'src/domain/constants';

export const SplashRedirect = () => {
  const navigate = useNavigate();
  const isSplashSeen = !!localStorage.getItem(IS_SPLASH_SCREEN_STORAGE_KEY);

  useEffect(() => {
    if (!isSplashSeen) {
      navigate(PUBLIC_ROUTES_PATHS.splash, { replace: true });
    }
  }, [isSplashSeen, navigate]);

  return <Outlet />;
};
