export const modulesAdapter = (response: any) => {
  const transformedModules = response.modules.map(module => ({
    publicUid: module.public_uid,
    title: module.title,
    description: module.description,
    state: module.state,
    imageUrl: module.image_url,
  }));

  return {
    modules: transformedModules,
  };
};
