import { Button, Input, Layout, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { MdChevronLeft } from 'react-icons/md';
import { InputType, PAGE_NAMES } from '@domain/constants';
import { trackGA4PageView, usernameRules } from '@data/utils';
import { useForm } from 'react-hook-form';
import { useForgotPassword } from '@hooks/index';
import { PUBLIC_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useEffect } from 'react';

type Inputs = {
  credentials: string;
};

export const ForgotPasswordPage = () => {
  const { t } = useTranslation([
    'pages/auth/forgotPassword/forgotPasswordPage',
    'common',
  ]);

  const {
    navigateToLogin,
    credentials,
    credentialsSent,
    forgotPasswordMutation,
  } = useForgotPassword();

  const handleFormSubmit = async (data: Inputs) => {
    try {
      await forgotPasswordMutation.mutateAsync(data.credentials);
      reset();
    } catch (error) {
      console.log(error);
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { isValid },
  } = useForm<Inputs>({
    defaultValues: {
      credentials: '',
    },
    mode: 'onChange',
  });

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PUBLIC_ROUTES_PATHS.forgotPassword,
      title: PAGE_NAMES.forgotPassword,
    });
  }, []);

  return (
    <Layout isFlex containerStyles='justify-between'>
      <div className='flex-1'>
        <MdChevronLeft
          size={24}
          onClick={navigateToLogin}
          className='mb-6 flex-shrink-0 cursor-pointer rounded-full text-secondary-buttons-500'
        />
        <Typography variant='h1' className='mb-2'>
          {t('title')}
        </Typography>
        <Typography variant='span'>{t('description')}</Typography>

        <form
          onSubmit={handleSubmit(handleFormSubmit)}
          className='mb-4 mt-6'
          id='forgot-password-form'
        >
          <Input
            label={t('inputs.email')}
            type={InputType.text}
            control={control}
            name='credentials'
            disabled={credentialsSent}
            placeholder={credentials}
            rules={usernameRules(
              t('inputs.errors.invalid', {
                inputName: 'email or username',
                ns: 'common',
              })
            )}
            inputMode='email'
          />
        </form>

        {credentialsSent && (
          <div className='flex flex-col gap-4'>
            <Typography variant='span' className='font-bold'>
              {t('sentConfirmation')}
            </Typography>
            <Typography variant='span'>{t('checkSpam')}</Typography>
          </div>
        )}
      </div>

      <Button
        type='submit'
        text={t('button.confirm', { ns: 'common' })}
        style='primary'
        form='forgot-password-form'
        disabled={
          !isValid || credentialsSent || forgotPasswordMutation.isPending
        }
        loading={forgotPasswordMutation.isPending}
      />
    </Layout>
  );
};
