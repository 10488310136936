import { ChallengeCard } from '@components/molecules';
import { BorderContainer, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { categorizeRetos } from '@data/utils';
import { UserRole } from '@domain/constants';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { MdTask } from 'react-icons/md';
import { AnimatePresence, motion } from 'framer-motion';

interface RetoCardListProps {
  retos: any;
}

const RetoCardListItem = ({
  retos,
  title,
  clickable = true,
}: {
  retos: Record<string, any>[];
  title?: string;
  clickable?: boolean;
}) => {
  return (
    <div className='space-y-4'>
      {title && (
        <Typography variant='span' className='font-bold !text-black'>
          {title}
        </Typography>
      )}
      <div className='flex flex-col space-y-4'>
        {retos.map((reto, index) => (
          <motion.div
          initial={{ x: '-130%', opacity:0 }} // Start off-screen to the left
          animate={{ x: 0, opacity:1 }}       // Slide to the original positi
          transition={{
           type: "spring",
           damping: 40,
           stiffness: 500,
           delay: index * 0.1,
         }}
         >
          <ChallengeCard key={reto.id} reto={reto} clickable={clickable} />
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export const RetoCardList = ({ retos }: RetoCardListProps) => {
  const { t } = useTranslation('components/organisms/retoCardList');

  const { todayRetos, upcomingRetos, activeRetos } = categorizeRetos(retos);
  const userRole = useSelector(UserSelectors.getRole);

  return (
    <AnimatePresence mode="wait">
    <div className='w-full'>
      {retos && retos.length > 0 ? (
        userRole === UserRole.STUDENT ? (
          <div className='space-y-4'>
            {todayRetos.length > 0 && (
              <RetoCardListItem retos={todayRetos} title={t('today')} />
            )}
            {activeRetos.length > 0 && (
              <RetoCardListItem retos={activeRetos} title={t('active')} />
            )}
            {upcomingRetos.length > 0 && (
              <RetoCardListItem
                retos={upcomingRetos}
                title={t('upcoming')}
                clickable={false}
              />
            )}
          </div>
        ) : (
          <RetoCardListItem retos={retos} />
        )
      ) : (
        
        <motion.div
          initial={{ y: 10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -10, opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <BorderContainer containerStyles='flex w-full flex-col items-center p-8 gap-4 text-center bg-secondary-surface-100'>
            <MdTask size={40} className='flex-shrink-0 text-primary-300' />
            <Typography variant='span' className='font-bold !text-primary-400'>
              {t('emptyState')}
            </Typography>
          </BorderContainer>
        </motion.div>
      )}
    </div>
    </AnimatePresence>
  );
};
