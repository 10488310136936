import { homeAdapter } from '@data/adapters';
import { WebClientRest } from '@data/api/clients';
import { Api } from '@data/api';
import { HttpStatusCode } from '@domain/api';
import { useDispatch } from 'react-redux';
import { logoutUser } from '@store/slices';
import { AUTH_TOKEN_STORAGE_KEY } from '@domain/constants';

const LAST_PLAYED_KEY = 'lastPlayedDailyPrayerGame';

export const useHome = () => {
  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });
  const api = new Api(apiInstance);
  const dispatch = useDispatch();

  const getHome = async () => {
    try {
      const response = await api.home.getHome();

      if (response.status === HttpStatusCode.OK) {
        const homeData = homeAdapter(response.data);
        
        if (homeData.lastTimePlayed) {
          localStorage.setItem(LAST_PLAYED_KEY, homeData.lastTimePlayed);
        }

        return homeData;
      }
    } catch (error) {
      if (error?.response?.status === HttpStatusCode.UNAUTHORIZED) {
        dispatch(logoutUser());
        localStorage.removeItem(AUTH_TOKEN_STORAGE_KEY);
        throw error;
      }

      const errorMessage = error?.response?.data?.errors[0];
      console.log(errorMessage || error);
      throw error;
    }
  };

  return {
    getHome,
  };
};
