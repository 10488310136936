import { useTranslation } from 'react-i18next';
import { Api } from '@data/api';
import { WebClientRest } from '@data/api/clients';
import { getClassStorage, NotificationsDispatcher } from '@data/utils';
import {
  challengesAdapter,
  studentAdapter,
  studentsAdapter,
  submittedRetosAdapter,
} from '@data/adapters';
import { useDispatch } from 'react-redux';
import { setStudents } from '@store/slices';
import { HttpStatusCode } from '@domain/api';
import { ToastNotificationType } from '@domain/constants';

export const useClasses = () => {
  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });
  const api = new Api(apiInstance);

  const dispatch = useDispatch();
  const { t } = useTranslation('common');
  const { publicUid } = getClassStorage();

  const getStudents = async () => {
    try {
      const response = await api.classes.getStudents(publicUid);

      if (response.status === HttpStatusCode.OK) {
        const students = studentsAdapter(response.data);

        dispatch(setStudents(students));
        return students;
      }
    } catch (error) {
      const errorMessage = error.response.data.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const getStudentById = async (classId: string, id: number) => {
    try {
      const response = await api.classes.getStudentById(classId, id);

      if (response.status === HttpStatusCode.OK) {
        return studentAdapter(response.data);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const getRetos = async () => {
    try {
      const response = await api.classes.getRetos(publicUid);

      if (response.status === HttpStatusCode.OK) {
        const retos = challengesAdapter(response.data);
        return retos;
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const getSubmittedRetos = async () => {
    try {
      const response = await api.classes.getSubmittedRetos(publicUid);

      if (response.status === HttpStatusCode.OK) {
        const retos = submittedRetosAdapter(response.data);
        return retos;
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  return {
    getStudents,
    getStudentById,
    getRetos,
    getSubmittedRetos,
  };
};
