import { ButtonTabSelector } from '@components/molecules';

interface TabSelectorProps {
  tabs: Array<{
    id: string;
    label: string;
    route: string;
  }>;
}

export const TabSelector = ({ tabs }: TabSelectorProps) => {
  return (
    <nav className='flex w-full gap-2 rounded-xl bg-secondary-surface-200 p-2 shadow-inner'>
      {tabs.map(({ id, label, route }) => (
        <ButtonTabSelector key={id} label={label} route={route} />
      ))}
    </nav>
  );
};
