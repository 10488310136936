import { YoutubeEmbed } from '@components/atoms';
import { SlideContentWrapper } from '../SlideContentWrapper';
import { ActivitySubtype, ActivityType } from 'src/domain/constants';

interface RenderVideoSlideProps {
  title: string;
  videoURL: string;
  type: ActivityType;
  subtype: ActivitySubtype;
}

export const RenderVideoSlide = ({
  title,
  videoURL,
  type,
  subtype,
}: RenderVideoSlideProps) => {
  return (
    <SlideContentWrapper title={title} type={type} subtype={subtype}>
      <YoutubeEmbed url={videoURL} />
    </SlideContentWrapper>
  );
};
