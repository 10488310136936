import { Typography, BorderContainer } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { formatDateTime } from '@data/utils';

interface DueDateProps {
  date: Date;
}

export const DueDate = ({ date }: DueDateProps) => {
  const { t } = useTranslation('components/molecules/dueDate');
  const formattedDate = formatDateTime(date);

  return (
    <BorderContainer containerStyles='p-4 bg-secondary-surface-100 flex items-center justify-center'>
      <Typography
        variant='span'
        className='text-[16px] font-medium leading-[20px] !text-primary-400'
      >
        {`${t('submitBy')} ${formattedDate}`}
      </Typography>
    </BorderContainer>
  );
};
