import React, { useState } from 'react';
import { Button } from '@components/atoms';
import { DraggableWordList } from '../DraggableWordList/DraggableWordList';
import { PrayerTextWithDropZones } from '../PrayerTextWithDropZones/PrayerTextWithDropZones';
import { useTranslation } from 'react-i18next';
import { ANSWER_STATUS } from '@domain/constants/enums/states/States';
import { SlideAnswerSubmitted } from '../SlideAnswerSubmitted/SlideAnswerSubmitted';

interface EndingPrayerGameProps {
  prayerText: string;
  words: string[];
  onComplete: (
    filledPrayer: string,
    points: number,
    allCorrect: boolean
  ) => void;
  blankPositions: number[];
  pointsAwarded: number;
  isCompleted: boolean;
}

export const EndingPrayerGame: React.FC<EndingPrayerGameProps> = ({
  prayerText = '',
  words = [],
  onComplete,
  blankPositions = [],
  pointsAwarded = 0,
  isCompleted = false,
}) => {
  const { t } = useTranslation('components/molecules/endingPrayerGame');

  if (!prayerText || words.length === 0 || blankPositions.length === 0) {
    return <div>{t('errorLoadingPrayerGame')}</div>;
  }

  const [filledWords, setFilledWords] = useState<(string | null)[]>(
    Array(blankPositions.length).fill(null)
  );
  const [availableWords, setAvailableWords] = useState<string[]>(words);
  const [answerStatus, setAnswerStatus] = useState<(ANSWER_STATUS | null)[]>(
    Array(blankPositions.length).fill(null)
  );
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleDrop = (index: number) => (word: string) => {
    const newFilledWords = [...filledWords];
    const previousWord = newFilledWords[index];

    if (previousWord) {
      setAvailableWords(prevWords => [...prevWords, previousWord]);
    }

    newFilledWords[index] = word;
    setFilledWords(newFilledWords);
    setAvailableWords(prevWords => prevWords.filter(w => w !== word));
  };

  const handleRemoveWord = (index: number) => {
    if (!isSubmitted) {
      const removedWord = filledWords[index];
      if (removedWord) {
        const newFilledWords = [...filledWords];
        newFilledWords[index] = null;
        setFilledWords(newFilledWords);
        setAvailableWords(prevWords => [...prevWords, removedWord]);
      }
    }
  };

  const handleComplete = () => {
    const newAnswerStatus = filledWords.map((word, index) =>
      word === words[index] ? ANSWER_STATUS.correct : ANSWER_STATUS.incorrect
    );
    setAnswerStatus(newAnswerStatus);

    const correctCount = newAnswerStatus.filter(
      status => status === ANSWER_STATUS.correct
    ).length;
    const points = correctCount * pointsAwarded;
    const allCorrect = correctCount === filledWords.length;

    const completedPrayer = prayerText
      .split(' ')
      .map((word, index) => {
        if (word.startsWith('#blank')) {
          const blankIndex = blankPositions.indexOf(index);
          return filledWords[blankIndex] || '#blank';
        }
        return word;
      })
      .join(' ');

    setIsSubmitted(true);
    onComplete(completedPrayer, points, allCorrect);
  };

  return (
    <div className={`flex flex-col ${(isSubmitted || isCompleted) ? 'h-full overflow-y-auto' : ''}`}>
      <div className='flex-shrink-0'>
        <PrayerTextWithDropZones
          text={prayerText}
          onDrop={handleDrop}
          filledWords={filledWords}
          onRemoveWord={handleRemoveWord}
          answerStatus={answerStatus}
          blankPositions={blankPositions}
          isSubmitted={isSubmitted || isCompleted}
        />
      </div>
      {!isSubmitted && !isCompleted && (
        <>
          <div className='mb-16 flex flex-grow items-center justify-center overflow-hidden'>
            <DraggableWordList words={availableWords} />
          </div>
          <div className='fixed bottom-4 left-4 right-4'>
            <Button
              type='button'
              onClick={handleComplete}
              style='primary'
              disabled={filledWords.includes(null)}
              text={t('Submit')}
            />
          </div>
        </>
      )}
      {(isSubmitted || isCompleted) && (
        <div className="mt-4">
          <SlideAnswerSubmitted />
        </div>
      )}
    </div>
  );
};
