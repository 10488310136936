import { STATES_STR } from '@domain/constants';
import { convertToUTCMinus4 } from '@data/utils';

export const categorizeRetos = (retos: Record<string, any>[]) => {
  if (!retos) {
    return {
      todayRetos: [],
      activeRetos: [],
      upcomingRetos: [],
    };
  }
  const currentDate = new Date();
  const today = convertToUTCMinus4(currentDate);

  const todayRetos: Record<string, any>[] = [];
  const activeRetos: Record<string, any>[] = [];
  const upcomingRetos: Record<string, any>[] = [];

  retos.forEach(reto => {
    const dueDate = reto.dueDate;
    const lessonState = reto.lesson.state;

    if (lessonState === STATES_STR.finished) {
      if (dueDate === today) {
        todayRetos.push(reto);
      } else {
        activeRetos.push(reto);
      }
    } else {
      upcomingRetos.push(reto);
    }
  });

  return {
    todayRetos,
    activeRetos,
    upcomingRetos,
  };
};
