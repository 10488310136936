import { Typography } from '@components/atoms';
import { NavLink, useParams } from 'react-router-dom';

interface TabNavigationButtonProps {
  label: string;
  route: string;
}

export const TabNavigationButton = ({
  label,
  route,
}: TabNavigationButtonProps) => {
  const routeParams = useParams();
  const { classId } = routeParams;

  return (
    <NavLink
      className={({ isActive }) =>
        `w-full rounded-t-xl py-2 text-center ${
          isActive
            ? 'bg-secondary-surface-200 shadow-general '
            : 'bg-primary-200'
        }`
      }
      to={route.replace(':classId', classId!.toString())}
    >
      <Typography variant='span'>{label}</Typography>
    </NavLink>
  );
};
