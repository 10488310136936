import { Avatar, Button, Layout, Typography } from '@components/atoms';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdChevronLeft, MdShoppingBag } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { useProfile, useShop } from '@hooks/index';
import { useQuery } from '@tanstack/react-query';
import { ShopItemList } from '@components/organisms';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { trackGA4PageView } from '@data/utils';
import { PAGE_NAMES } from '@domain/constants';

export const SelectAvatarPage = () => {
  const userImage = useSelector(UserSelectors.getAvatar);
  const [selectedAvatar, setSelectedAvatar] = useState<Record<
    string,
    number
  > | null>(null);

  const { t } = useTranslation([
    'pages/home/profile/student/details/selectAvatar/selectAvatarPage',
    'common',
  ]);
  const { navigateToShop, navigateToProfileDetails, editAvatarMutation } =
    useProfile();
  const { getShopPurchasedItemsByType } = useShop();

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['purchasedShopItemsByAvatar'],
    queryFn: () => getShopPurchasedItemsByType('avatar'),
  });

  const handleSaveAvatar = async () => {
    try {
      await editAvatarMutation.mutateAsync(selectedAvatar?.id);
      navigateToProfileDetails();
    } catch (error) {
      console.log(error);
    }
  };

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.profileAvatar,
      title: PAGE_NAMES.profileAvatar,
    });
  }, []);

  return (
    <Layout
      isFlex
      containerStyles='justify-between'
      isLoading={isLoading || isPending}
    >
      <div className='mb-16 flex-1'>
        <MdChevronLeft
          size={24}
          onClick={navigateToProfileDetails}
          className='mb-6 flex-shrink-0 cursor-pointer rounded-full text-secondary-buttons-500'
        />
        <Typography variant='h1' className='mb-2'>
          {t('title')}
        </Typography>
        <div className='flex items-center justify-between gap-16'>
          <Typography variant='span'>{t('description')}</Typography>
          <Button
            text={t('buttons.shop')}
            style='link'
            onClick={navigateToShop}
            iconLeft={
              <MdShoppingBag
                size={24}
                className='flex-shrink-0 text-primary-400'
              />
            }
            type='button'
          />
        </div>

        <div className='my-6 flex items-center justify-center'>
          <Avatar className='w-40' image={selectedAvatar?.image || userImage} />
        </div>

        <ShopItemList
          items={data}
          studentPoints={null}
          onClick={item => setSelectedAvatar(item)}
          selectedAvatar={selectedAvatar?.id}
          className='gap-y-6'
          hidePrice
          hideEmptyState
        />
      </div>

      <div className='fixed bottom-4 left-0 right-0 z-50 flex w-full items-center justify-center'>
        <Button
          type='button'
          className='!w-[90%]'
          text={t('button.saveChanges', { ns: 'common' })}
          onClick={handleSaveAvatar}
          style='primary'
          disabled={!selectedAvatar?.id}
        />
      </div>
    </Layout>
  );
};
