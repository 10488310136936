import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@store/index';

interface ModalSheetState {
  infoModals: Array<{
    id: string;
    isOpen?: boolean;
    image: string;
    title: string;
    subtitle?: string;
    description: string;
    backgroundColor: 'orange' | 'blue';
    isBadge?: boolean;
    level?: number;
    levels?: number;
  }>;
  pointsModals: Array<{
    id: string;
    isOpen: boolean;
    title: string;
    subtitle?: string;
    pointsEarned: number;
  }>;
}

const initialState: ModalSheetState = {
  infoModals: [],
  pointsModals: [],
};

const modalSheetSlice = createSlice({
  name: 'modalSheet',
  initialState,
  reducers: {
    openInfoModal: (
      state,
      action: PayloadAction<Omit<ModalSheetState['infoModals'][0], 'id'>>
    ) => {
      state.infoModals.push({
        id: new Date().toISOString() + Math.random().toString(16).substring(7),
        ...action.payload,
        isOpen: true,
      });
    },

    openPointsModal: (
      state,
      action: PayloadAction<Omit<ModalSheetState['pointsModals'][0], 'id'>>
    ) => {
      state.pointsModals.push({
        id: new Date().toISOString() + Math.random().toString(16).substring(7),
        ...action.payload,
        isOpen: true,
      });
    },

    closeInfoModal: (state, action: PayloadAction<string>) => {
      state.infoModals = state.infoModals.filter(
        modal => modal.id !== action.payload
      );
    },

    closePointsModal: (state, action: PayloadAction<string>) => {
      state.pointsModals = state.pointsModals.filter(
        modal => modal.id !== action.payload
      );
    },

    clearModalSheetProps: () => initialState,
  },
});

export const ModalSheetSelectors = {
  getInfoModals: (state: RootState) => state.modalSheet.infoModals,
  getPointsModals: (state: RootState) => state.modalSheet.pointsModals,
  getProps: (state: RootState) => state.modalSheet,
};

export const {
  openInfoModal,
  clearModalSheetProps,
  closeInfoModal,
  openPointsModal,
  closePointsModal,
} = modalSheetSlice.actions;

export const ModalSheetReducer = modalSheetSlice.reducer;
