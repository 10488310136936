import { TabLayout } from '@components/atoms';
import {
  TabNavigation,
  RetoCardList,
  TabSelector,
} from '@components/organisms';
import { HomeTemplate } from '@components/templates';
import { useClasses } from '@hooks/index';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { trackGA4PageView } from '@data/utils';
import { useLocation, useParams } from 'react-router-dom';
import { PAGE_NAMES, UserRole } from '@domain/constants';
import { useTranslation } from 'react-i18next';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';

export const RetosPage = () => {
  const { getRetos } = useClasses();
  const location = useLocation();
  const { classId } = useParams();

  const { t } = useTranslation(['components/organisms/tabSelector']);

  const retoTabs = useMemo(
    () => [
      {
        id: 'active',
        label: t('active', { ns: 'components/organisms/tabSelector' }),
        route: PROTECTED_ROUTES_PATHS.retos.replace(':classId', classId!),
      },
      {
        id: 'submitted',
        label: t('submitted', { ns: 'components/organisms/tabSelector' }),
        route: PROTECTED_ROUTES_PATHS.gradedRetos.replace(':classId', classId!),
      },
    ],
    [t, classId]
  );

  const userRole = useSelector(UserSelectors.getRole);

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['getRetos'],
    queryFn: getRetos,
    retry: false,
  });

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: location.pathname,
      title: PAGE_NAMES.retos,
    });
  }, [location.pathname]);

  return (
    <HomeTemplate
      childrenClassname='!px-0'
      layoutClassname='!bg-secondary-surface-100'
    >
      <TabNavigation />
      <TabLayout
        className='flex flex-col gap-4'
        isLoading={isLoading || isPending}
      >
        {userRole === UserRole.STUDENT && <TabSelector tabs={retoTabs} />}

        <RetoCardList retos={data} />
      </TabLayout>
    </HomeTemplate>
  );
};
