import { NavLink } from 'react-router-dom';
import { Typography } from '@components/atoms';

interface ButtonTabSelector {
  label: string;
  route: string;
}

export const ButtonTabSelector = ({ label, route }: ButtonTabSelector) => {
  return (
    <NavLink
      to={route}
      end
      className={({ isActive }) =>
        `w-full rounded-xl py-2 text-center ${
          isActive
            ? 'bg-primary-400 [&>*]:text-secondary-text-100'
            : 'bg-transparent [&>*]:text-secondary-text-400'
        }`
      }
    >
      <Typography variant='span' className='font-bold'>
        {label}
      </Typography>
    </NavLink>
  );
};
