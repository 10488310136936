import {
  COURSE_NAME_STORAGE_KEY,
  COURSE_PUBLIC_UID_STORAGE_KEY,
} from '@domain/constants';

interface ClassStorage {
  name: string;
  publicUid: string;
}

export const setClassStorage = ({ name, publicUid }: ClassStorage) => {
  localStorage.setItem(COURSE_NAME_STORAGE_KEY, name);
  localStorage.setItem(COURSE_PUBLIC_UID_STORAGE_KEY, publicUid);
};

export const getClassStorage = (): ClassStorage => {
  const name: string = localStorage.getItem(COURSE_NAME_STORAGE_KEY) || '';
  const publicUid: string =
    localStorage.getItem(COURSE_PUBLIC_UID_STORAGE_KEY) || '';

  return { name, publicUid };
};

export const removeClassStorage = () => {
  localStorage.removeItem(COURSE_NAME_STORAGE_KEY);
  localStorage.removeItem(COURSE_PUBLIC_UID_STORAGE_KEY);
};

const DAILY_PRAYER_GAME_STORAGE_KEY = 'dailyPrayerGame';

export const setDailyPrayerGameStorage = (dailyPrayerGame: any) => {
  localStorage.setItem(DAILY_PRAYER_GAME_STORAGE_KEY, JSON.stringify(dailyPrayerGame));
};

export const getDailyPrayerGameStorage = () => {
  const data = localStorage.getItem(DAILY_PRAYER_GAME_STORAGE_KEY);
  return data ? JSON.parse(data) : null;
};

export const removeDailyPrayerGameStorage = () => {
  localStorage.removeItem(DAILY_PRAYER_GAME_STORAGE_KEY);
};
