import { WebClient } from '@domain/api';
import {
  UsersApi,
  ClassesApi,
  ShopApi,
  HomeApi,
  ModulesApi,
  LessonsApi,
  RetosApi,
  AchievementsApi,
  SetupApi,
  PrayerGameApi,
} from './resources';
import { WebClientRest } from './clients';

class Api {
  client: WebClient;
  users: UsersApi;
  classes: ClassesApi;
  shop: ShopApi;
  home: HomeApi;
  modules: ModulesApi;
  lessons: LessonsApi;
  retos: RetosApi;
  achievements: AchievementsApi;
  setup: SetupApi;
  prayerGame: PrayerGameApi;
  constructor(client: WebClientRest) {
    this.client = client;
    this.users = new UsersApi(client);
    this.classes = new ClassesApi(client);
    this.shop = new ShopApi(client);
    this.home = new HomeApi(client);
    this.modules = new ModulesApi(client);
    this.lessons = new LessonsApi(client);
    this.retos = new RetosApi(client);
    this.achievements = new AchievementsApi(client);
    this.setup = new SetupApi(client);
    this.prayerGame = new PrayerGameApi(client);
  }
}

export default Api;
