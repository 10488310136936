import { createContext } from 'react';

export const SendButtonContext = createContext({
  sendButtonState: {
    loading: false,
    disabled: false,
    answered: false,
  },
  setSendButtonState: (state: any) => {
    state;
  },
});
