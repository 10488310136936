import { ModalSheet } from '@components/molecules';
import { useDispatch, useSelector } from 'react-redux';
import { ModalSheetSelectors, closeInfoModal } from '@store/slices';
import { arcVitral } from '@assets/svg';
import {
  BadgeImage,
  InnerShadowContainer,
  Typography,
} from '@components/atoms';
import { TacoTruckSide } from '@assets/img';
import { assignLevelColorsByNumber } from '@data/utils';
import { motion } from 'framer-motion';

export const ModalSheetInfoGlobal = () => {
  const dispatch = useDispatch();
  const infoModals = useSelector(ModalSheetSelectors.getInfoModals);

  return (
    <>
      {infoModals.map(
        ({
          id,
          image,
          title,
          subtitle,
          description,
          backgroundColor,
          isOpen,
          isBadge,
          level,
        }) => {
          const radialBackgroundColor =
            backgroundColor === 'blue'
              ? 'from-[#00C7C7] to-[#008C8C]'
              : 'from-[#FFC349] to-[#C16800]';
          return (
            <ModalSheet
              key={id}
              isOpen={isOpen!}
              onClose={() => dispatch(closeInfoModal(id))}
              className={`relative bg-gradient-radial-top ${radialBackgroundColor}`}
            >
              <img
                src={arcVitral}
                alt='arc'
                className='absolute -top-7 right-0 w-full select-none user-drag-none'
              />
              <div className='flex flex-col items-center justify-center gap-4 p-4'>
                {isBadge && level ? (
                  <motion.div
                  className='rounded-full bg-secondary-surface-100 p-4'
                  initial={{ scale:0.1, opacity:0 }}
                  animate={{ scale:1, opacity:1 }}
                  transition={{
                    type: "spring", 
                    stiffness: 300, 
                    damping: 20,
                    delay: 0.3,
                  }}
                  >
                    <BadgeImage
                      src={image}
                      className='h-24 w-24'
                      color={assignLevelColorsByNumber(level)}
                    />
                  </motion.div>
                ) : (
                  <img
                    src={image}
                    alt='image'
                    className='h-24 object-contain'
                  />
                )}

              <motion.div
                initial={{ scale:0.1, opacity:0, rotate: 90}}
                animate={{ scale:1, opacity:1, rotate: 0 }}
                transition={{
                  type: "spring", 
                  stiffness: 200, 
                  damping: 15,
                  delay: 0.5,
                }}
                >
                  <img src={TacoTruckSide} className='h-36 object-contain'></img>
                </motion.div>

                <motion.div
                  initial={{ scale:0.1, opacity:0 }}
                  animate={{ scale:1, opacity:1 }}
                  transition={{
                    type: "spring", 
                    stiffness: 300, 
                    damping: 20,
                    duration: 10,
                    delay: 0.7,
                  }}
                >
                  <Typography variant='h1' className='!text-secondary-text-100'>
                    {title}
                  </Typography>
                </motion.div>

                <motion.div
                className='mb-8 w-full space-y-2 px-8 text-center [&>*]:leading-[18px]'
                  initial={{ scale:0.1, opacity:0 }}
                  animate={{ scale:1, opacity:1 }}
                  transition={{
                    type: "spring", 
                    stiffness: 300, 
                    damping: 20,
                    duration: 10,
                    delay: 0.9,
                  }}
                >
                <InnerShadowContainer className='mb-8 w-full space-y-2 px-8 text-center [&>*]:leading-[18px]'>
                  {subtitle && (
                    <Typography variant='p' className='font-bold'>
                      {subtitle}
                    </Typography>
                  )}

                  <Typography
                    variant='p'
                    className={`text-[12px] ${!title && '!font-bold'}`}
                  >
                    {description}
                  </Typography>
                </InnerShadowContainer>
                </motion.div>
              </div>
            </ModalSheet>
          );
        }
      )}
    </>
  );
};
