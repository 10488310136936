import { motion } from 'framer-motion';
import Button, { ButtonProps } from '../Button/Button';

export interface AnimatedButtonProps extends ButtonProps {}

const AnimatedButton = ({
  type,
  text,
  onClick,
  className = '',
  style,
  ...rest
}: ButtonProps) => {
  return (
    <motion.div
      whileHover={{ scale: 1.03 }}
      whileTap={{ scale: 0.9 }}
      className={className}
      transition={{ type: 'spring', stiffness: 400, damping: 17 }}
    >
      <Button
        {...rest}
        style={style}
        type={type}
        text={text}
        onClick={onClick}
      />
    </motion.div>
  );
};

export default AnimatedButton;
