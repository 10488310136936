import { Taco } from '@assets/img';

export const TacoImage = ({ imgClassName }: { imgClassName?: string }) => {
  return (
    <div className='grid place-items-center'>
      <img src={Taco} className={`w-[120px] ${imgClassName}`}></img>
      <div className='-mt-1 h-4 w-28 -rotate-2 rounded-[100%] bg-secondary-buttons-300 opacity-50'></div>
    </div>
  );
};
