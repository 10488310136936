import { Avatar, Typography } from '@components/atoms';
import { TacoIcon } from '@assets/svg';
import { MdLock } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

export interface ShopItemProps {
  id: number;
  name: string;
  avatarImage?: string | null;
  selectedAvatar?: number | null;
  onClick?: () => void;
  collectibleImage?: string | null;
  price?: number | string | null;
  studentPoints?: number | string | null;
  isPurchased?: boolean;
  avatarClassname?: string;
  description?: string;
  link?: string;
  type?: 'avatar' | 'collectible';
  hidePrice?: boolean;
}

export const ShopItem = ({
  id,
  name,
  avatarImage,
  selectedAvatar,
  onClick,
  collectibleImage,
  price,
  studentPoints,
  isPurchased,
  avatarClassname,
  description,
  link,
  type,
  hidePrice = false,
}: ShopItemProps) => {
  const { t } = useTranslation('components/molecules/shopItem');

  const handleClick = () => {
    if (!onClick) return;
    if (isPurchased) return;
    if (price && studentPoints) {
      if (Number(price) > Number(studentPoints) && type === 'avatar') {
        return;
      }
    }

    onClick({
      id,
      name,
      image: avatarImage || collectibleImage,
      price,
      studentPoints,
      description,
      link,
      isPurchased,
    });
  };

  return (
    <div className='flex flex-col items-center gap-2' onClick={handleClick}>
      {avatarImage && (
        <Avatar
          className={`h-24 w-24 ${avatarClassname}`}
          image={avatarImage}
          isActive={id === selectedAvatar}
        />
      )}
      {collectibleImage && (
        <img src={collectibleImage} className='h-24 w-24' alt='collectible' />
      )}
      {name && (
        <Typography variant='span' className='font-bold text-wrap-ellipsis'>
          {name}
        </Typography>
      )}

      {(price || price !== null) &&
        (studentPoints || studentPoints !== 0) &&
        !hidePrice && (
          <div className='flex items-center gap-2 rounded-xl bg-secondary-surface-200 p-2'>
            {isPurchased ? (
              <Typography variant='span' className='font-bold'>
                {t('purchased')}
              </Typography>
            ) : (
              <div className='flex items-center gap-2'>
                {Number(price) > Number(studentPoints) ? (
                  <MdLock size={16} className='flex-shrink-0' />
                ) : (
                  <img src={TacoIcon} alt='taco icon' />
                )}
                <Typography variant='span' className='font-bold'>
                  {price === 0 ? t('free') : price?.toString()}
                </Typography>
              </div>
            )}
          </div>
        )}
    </div>
  );
};
