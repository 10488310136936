import { Layout, Lottie } from '@components/atoms';
import { useCallback, useEffect, useState } from 'react';
import { PUBLIC_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useNavigate } from 'react-router-dom';
import { IS_SPLASH_SCREEN_STORAGE_KEY } from '@domain/constants';
import { SplashPageLottie } from '@assets/lotties';

export const SplashPage = () => {
  const navigate = useNavigate();
  const [isSplashSeen, setIsSplashSeen] = useState<boolean>(false);

  const navigateToLogin = useCallback(() => {
    navigate(PUBLIC_ROUTES_PATHS.login, { replace: true });
  }, [navigate]);

  useEffect(() => {
    if (isSplashSeen) {
      localStorage.setItem(IS_SPLASH_SCREEN_STORAGE_KEY, 'true');
      navigateToLogin();
    }
  }, [isSplashSeen, navigateToLogin]);

  return (
    <Layout isFlex isCentered containerStyles='justify-between'>
      <Lottie
        animationData={SplashPageLottie}
        loop={false}
        onComplete={() => setIsSplashSeen(true)}
      />
    </Layout>
  );
};
