import { useTranslation } from 'react-i18next';
import { MdPlayLesson, MdSportsEsports, MdTask } from 'react-icons/md';
import { InnerShadowContainer } from '@components/atoms';
import { Statistic } from '@components/molecules';

interface StatisticsCardProps {
  stats: any;
}

export const StatisticsCard = ({ stats }: StatisticsCardProps) => {
  const { t } = useTranslation(
    'pages/home/home/classes/myProgress/myProgressPage'
  );

  const iconProps = {
    className: '!text-secondary-buttons-500 flex-shrink-0',
    size: 24,
  };

  const statistics = [
    {
      title: t('statistics.dailyPrayerStreak'),
      value: stats.dailyPrayerStreak,
      Icon: <MdSportsEsports {...iconProps} />,
    },
    {
      title: t('statistics.retosCompleted'),
      value: stats.retosCompleted,
      Icon: <MdTask {...iconProps} />,
    },
    {
      title: t('statistics.lessonsCompleted'),
      value: stats.lessonsCompleted,
      Icon: <MdPlayLesson {...iconProps} />,
    },
  ];

  return (
    <InnerShadowContainer className='flex justify-between bg-secondary-surface-200 shadow-lgInner'>
      {statistics.map(item => (
        <Statistic key={item.title} {...item} />
      ))}
    </InnerShadowContainer>
  );
};
