import { Typography, Divider } from '@components/atoms';
import { ActivitySubtype, ActivityType } from '@domain/constants';
import { motion } from 'framer-motion';
import {
  MdBook,
  MdVideocam,
  MdImage,
  MdCloud,
  MdPinDrop,
  MdBallot,
  MdContactSupport,
} from 'react-icons/md';
import AnimatedDivider from '../../atoms/AnimatedDivider/AnimatedDivider';

interface SlideTitleProps {
  title: string;
  type: ActivityType;
  subtype: ActivitySubtype;
}

export const SlideTitle = ({ title, type, subtype }: SlideTitleProps) => {
  const iconMap = {
    [ActivityType.SLIDE]: {
      [ActivitySubtype.TEXT]: <MdBook size={24} />,
      [ActivitySubtype.VIDEO]: <MdVideocam size={24} />,
      [ActivitySubtype.IMAGE]: <MdImage size={24} />,
    },
    [ActivityType.ACTIVITY]: {
      [ActivitySubtype.WORD_CLOUD]: <MdCloud size={24} />,
      [ActivitySubtype.PIN_IT]: <MdPinDrop size={24} />,
      [ActivitySubtype.POLL]: <MdBallot size={24} />,
      [ActivitySubtype.OPEN_QUESTION]: <MdContactSupport size={24} />,
    },
  };

  const initial = { opacity: 0, scale: 0.5 }
  const animate = { opacity: 1, scale: 1 }
  const transition = {
    icon: {
      duration: 0.5,
      ease: [0, 0.71, 0.2, 1.01],
      scale: {
        type: "spring",
        damping: 15,
        stiffness: 300,
      }
    },
    title: {
      duration: 1,
      ease: [0, 0.71, 0.2, 1.01],
      scale: {
        type: "spring",
        damping: 15,
        stiffness: 300,
      },
      delay: 0.3,
    }
  }

  return (
    <div>
      <div className='flex items-center gap-4 [&>svg]:!flex-shrink-0 [&_*]:!text-secondary-text-400'>
      <motion.div
        initial={initial}
        animate={animate}
        transition={transition["icon"]}>
        {iconMap[type][subtype]}
      </motion.div>
      <motion.div
        initial={initial}
        animate={animate}
        transition={transition["title"]}>
        <Typography variant='h1'>{title}</Typography>
        </motion.div>
      </div>

      <AnimatedDivider containerStyles='-my-2' animationDelay={0.5} />
    </div>
  );
};
