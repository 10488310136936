import { ReactNode } from 'react';
import { ProgressBar, ProgressBarProps } from '@components/atoms';
import { NumericStepper, NumericStepperProps } from '@components/molecules';
import { AnimatedProgressBar } from '../../atoms/Animated/AnimatedProgressBar';

export interface ProgressStepperProps
  extends NumericStepperProps,
    Omit<ProgressBarProps, 'value' | 'max'> {
  leftComponent: ReactNode;
}

export const ProgressStepper = ({
  leftComponent,
  currentStep,
  maxSteps,
  stepperStyles,
  containerStyles,
}: ProgressStepperProps) => {
  return (
    <div className={`flex w-full flex-col gap-4 ${containerStyles}`}>
      <div className='flex w-full justify-between'>
        {leftComponent}
        <NumericStepper
          currentStep={currentStep}
          maxSteps={maxSteps}
          stepperStyles={stepperStyles}
        />
      </div>
      <AnimatedProgressBar value={Number(currentStep)} max={Number(maxSteps)} />
    </div>
  );
};
