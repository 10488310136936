import { ActivitySubtype, ActivityType } from 'src/domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';
import { RichTextTypography } from '@components/atoms';

interface RenderTextSlideProps {
  title: string;
  textContent: string;
  type: ActivityType;
  subtype: ActivitySubtype;
}

export const RenderTextSlide = ({
  title,
  textContent,
  type,
  subtype,
}: RenderTextSlideProps) => {
  return (
    <SlideContentWrapper title={title} type={type} subtype={subtype}>
      {textContent && <RichTextTypography description={textContent} />}
    </SlideContentWrapper>
  );
};
