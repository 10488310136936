import { FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import './FileUpload.css';
import { InnerShadowContainer } from '../../atoms';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);

export const FileUpload = ({ files, setFiles, disabled }) => {
  return (
    <InnerShadowContainer className='!p-0'>
      <FilePond
        files={files}
        onupdatefiles={setFiles}
        acceptedFileTypes={['image/jpeg', 'image/png', 'image/jpg']}
        maxFileSize={'5MB'}
        allowFileTypeValidation={true}
        allowMultiple={false}
        labelIdle='<span class="filepond--label-action">Browse an image</span>'
        name='files'
        credits={false}
        disabled={disabled}
      />
    </InnerShadowContainer>
  );
};
