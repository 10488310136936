import { Card, Typography } from '@components/atoms';
import { MessageBubble, Activity } from '@assets/img';
import { useTranslation } from 'react-i18next';
import { MdArrowForward } from 'react-icons/md';
import { ChallengeType, UserRole } from '@domain/constants';
import { useRetos } from '@hooks/index';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import {
  formatDateTime,
  setRetoPointsStorage,
  removeRetoPointsStorage,
} from '@data/utils';

interface RetoCardProps {
  reto: Record<string, any>;
  clickable?: boolean;
}

const getImage = (type: ChallengeType) => {
  switch (type) {
    case ChallengeType.questionAndAnswer:
      return MessageBubble;
    case ChallengeType.activity:
      return Activity;
    default:
      return MessageBubble;
  }
};

export const ChallengeCard = ({ reto, clickable }: RetoCardProps) => {
  const image = getImage(reto.type);

  const userRole = useSelector(UserSelectors.getRole);

  const { t } = useTranslation('components/molecules/challengeCard');

  const { navigateToRetosResponses, navigateToRetosAnswer } = useRetos();

  const handleClick = () => {
    if (userRole === UserRole.STUDENT) {
      navigateToRetosAnswer(reto.id);
    } else {
      removeRetoPointsStorage();
      setRetoPointsStorage(reto.pointsAwarded.toString());
      navigateToRetosResponses(reto.id);
    }
  };

  return (
    <Card
      className={`items-center justify-between gap-4 bg-secondary-surface-100 ${
        !clickable && 'cursor-default'
      }`}
      onClick={clickable ? handleClick : undefined}
    >
      <img src={image} className='h-14 w-14 object-contain' />
      <div className='w-full space-y-4 self-end'>
        <div className=''>
          <Typography
            variant='p'
            className='text-[16px] font-bold leading-[20px]'
          >
            {reto.title}
          </Typography>
          <Typography variant='p' className='!text-secondary-text-200'>
            {t(`challengeType.${reto.type}`)}
          </Typography>
        </div>
        {userRole === UserRole.STUDENT ? (
          <Typography
            variant='p'
            className='font-bold !text-secondary-text-200'
          >
            {t('tacoPoints', { points: reto.pointsAwarded })}
          </Typography>
        ) : (
          <Typography
            variant='p'
            className='font-bold !text-secondary-text-200'
          >
            {t('dueDate', { date: formatDateTime(reto.dueDate) })}
          </Typography>
        )}
      </div>
      {clickable && (
        <MdArrowForward
          size={24}
          className='flex-shrink-0 text-secondary-text-400'
        />
      )}
    </Card>
  );
};
