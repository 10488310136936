import { Api } from '@data/api';
import { WebClientRest } from '@data/api/clients';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { NotificationsDispatcher, trackGA4Events } from '@data/utils';
import { HttpStatusCode } from '@domain/api';
import {
  AchievementsAnalytics,
  badgeObtainedLabel,
  RETO_MILESTONE_REWARD,
  RETO_STATES_STR,
  RETO_STREAK_REWARD,
  retoCompletedLabel,
  RetosAnalytics,
  ToastNotificationType,
} from '@domain/constants';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  challengeAdapter,
  retoResponseAdapter,
  retoResponsesAdapter,
  submitRetoResponseAdapter,
} from '@data/adapters';
import { useDispatch, useSelector } from 'react-redux';
import { openInfoModal, openPointsModal, UserSelectors } from '@store/slices';

interface RetoResponse {
  answer: string;
  state: RETO_STATES_STR;
}

export const useRetos = () => {
  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });
  const api = new Api(apiInstance);
  const { t } = useTranslation([
    'common',
    'components/organisms/modalSheetRetoAnswer',
    'components/templates/modalSheetGlobal',
  ]);

  const navigate = useNavigate();
  const { classId, retoId, responseId } = useParams();
  const dispatch = useDispatch();
  const userId = useSelector(UserSelectors.getId);

  const getRetoResponses = async () => {
    try {
      const response = await api.retos.getRetosResponses(retoId);

      if (response.status === HttpStatusCode.OK) {
        return retoResponsesAdapter(response.data);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
    }
  };

  const getRetoDetails = async () => {
    try {
      const response = await api.retos.getRetoDetails(retoId);

      if (response.status === HttpStatusCode.OK) {
        return challengeAdapter(response.data);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
    }
  };

  const getRetoResponseDetails = async () => {
    try {
      const response = await api.retos.getRetoResponseById(responseId);

      if (response.status === HttpStatusCode.OK) {
        return retoResponseAdapter(response.data);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const gradeRetoResponse = async (points: number) => {
    try {
      const body = {
        reto: {
          points: Number(points),
          state: RETO_STATES_STR.graded,
        },
      };

      const response = await api.retos.gradeRetoResponse(responseId, body);

      if (response.status === HttpStatusCode.OK) {
        return true;
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const getAchievedPoints = ({
    earlyBird,
    milestone,
    streak,
  }: {
    earlyBird: boolean;
    milestone: boolean;
    streak: boolean;
  }) => {
    let pointsEarned = 0;

    if (milestone) {
      pointsEarned += Number(sessionStorage.getItem(RETO_MILESTONE_REWARD));
    }

    if (streak) {
      pointsEarned += Number(sessionStorage.getItem(RETO_STREAK_REWARD));
    }

    return pointsEarned;
  };

  const submitRetoResponse = async ({
    retoResponse,
  }: {
    retoResponse: RetoResponse;
  }) => {
    try {
      const body = {
        reto: {
          answer: retoResponse?.answer,
          state: retoResponse?.state,
        },
      };

      const response = await api.retos.submitRetoResponse(retoId, body);

      if (response.status === HttpStatusCode.OK && response.data) {
        const formattedResponse = submitRetoResponseAdapter(response.data);
        const points = getAchievedPoints({
          earlyBird: formattedResponse.earlyBird,
          milestone: formattedResponse.milestone,
          streak: formattedResponse.streak,
        });

        if (points > 0) {
          dispatch(
            openPointsModal({
              isOpen: true,
              pointsEarned: points,
              title: t('pointsGlobal.retoFinished', {
                ns: 'components/templates/modalSheetGlobal',
              }),
            })
          );
        }

        trackGA4Events(
          RetosAnalytics.categories.retoCompleted,
          RetosAnalytics.actions.reto,
          retoCompletedLabel(
            new Date().toLocaleDateString('en-us', {
              year: 'numeric',
              month: 'short',
              day: 'numeric',
            }),
            userId.toString(),
            formattedResponse.retosCompleted?.id?.toString()
          )
        );

        if (formattedResponse.retosCompleted) {
          const {
            id: badgeId,
            title,
            description,
            imageURL,
            level,
            levels,
          } = formattedResponse.retosCompleted;
          dispatch(
            openInfoModal({
              title: `Level ${level.level - levels + 1}`,
              description: description,
              image: imageURL,
              isBadge: true,
              backgroundColor: 'blue',
              level: level.level,
              subtitle: title,
              levels: levels,
            })
          );

          trackGA4Events(
            AchievementsAnalytics.categories.badgeObtained,
            AchievementsAnalytics.actions.achievements,
            badgeObtainedLabel(
              new Date().toLocaleDateString('en-us', {
                year: 'numeric',
                month: 'short',
                day: 'numeric',
              }),
              userId.toString(),
              badgeId.toString()
            )
          );
        }
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const navigateToRetos = () => {
    navigate(PROTECTED_ROUTES_PATHS.retos.replace(':classId', classId!));
  };

  const navigateToRetosAnswer = (retoId: string) => {
    navigate(
      PROTECTED_ROUTES_PATHS.retosAnswer
        .replace(':retoId', retoId)
        .replace(':classId', classId!)
    );
  };

  const navigateToRetosResponses = (retoId: string) => {
    navigate(
      PROTECTED_ROUTES_PATHS.retosResponses
        .replace(':retoId', retoId!)
        .replace(':classId', classId!)
    );
  };

  const navigateToRetoResponseDetails = (responseId: string) => {
    navigate(
      PROTECTED_ROUTES_PATHS.retoResponseDetails
        .replace(':classId', classId!)
        .replace(':retoId', retoId!)
        .replace(':responseId', responseId)
    );
  };

  const navigateToRetoResponse = () => {
    navigate(
      PROTECTED_ROUTES_PATHS.retosResponses
        .replace(':classId', classId!)
        .replace(':retoId', retoId!)
    );
  };

  const submitRetoResponseMutation = useMutation({
    mutationKey: ['submitRetoResponse', retoId],
    mutationFn: ({ retoResponse, retoType }) =>
      submitRetoResponse({ retoResponse, retoType }),
    retry: false,
  });

  const gradeRetoResponseMutation = useMutation({
    mutationKey: ['gradeRetoResponse', retoId],
    mutationFn: ({ points }) => gradeRetoResponse(points),
    retry: false,
  });

  return {
    navigateToRetos,
    getRetoResponses,
    navigateToRetosAnswer,
    submitRetoResponseMutation,
    navigateToRetosResponses,
    getRetoDetails,
    navigateToRetoResponse,
    navigateToRetoResponseDetails,
    getRetoResponseDetails,
    gradeRetoResponseMutation,
  };
};
