import { Loader } from '@components/atoms';

interface LayoutProps {
  children: React.ReactNode;
  isCentered?: boolean;
  isFlex?: boolean;
  containerStyles?: string;
  isLoading?: boolean;
}

const Layout = ({
  children,
  isCentered = false,
  isFlex = false,
  containerStyles = '',
  isLoading = false,
}: LayoutProps) => {
  const flexClass = isFlex ? 'flex flex-col' : '';
  const centeredClass = isCentered ? 'justify-center items-center' : '';

  return (
    <main
      className={`min-h-dvh w-full bg-secondary-surface-200 p-4 font-body text-black ${flexClass} ${centeredClass} ${containerStyles}`}
    >
      {isLoading ? (
        <div className='flex flex-1 items-center justify-center'>
          <Loader />
        </div>
      ) : (
        children
      )}
    </main>
  );
};

export default Layout;
