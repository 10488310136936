import { HomeTemplate } from '@components/templates';
import { Typography, ProfileCard } from '@components/atoms';
import { MdChevronRight } from 'react-icons/md';
import { useShop } from '@hooks/index';
import { PositionedAvatar, PointsCard } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { ProfileButtonsNavigation, ShopItemList } from '@components/organisms';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { mergeAndLimitArrays } from '@data/utils';

export const StudentPage = ({ user }: { user: any }) => {
  const [lastItemsPurchased, setLastItemsPurchased] = useState<
    Record<string, any>[]
  >([]);
  const { getShopLanding, navigateToShopPurchased } = useShop();

  const { data, isLoading, isPending } = useQuery({
    queryKey: ['latestItemsPurchased'],
    queryFn: () => getShopLanding('purchased'),
  });

  useEffect(() => {
    if (data) {
      setLastItemsPurchased(mergeAndLimitArrays(data));
    }
  }, [data]);

  const { t } = useTranslation('pages/home/profile/profilePage');

  return (
    <HomeTemplate isProfilePage>
      <PositionedAvatar />
      <div className='flex flex-1 flex-col justify-between pt-12'>
        <div>
          <Typography
            variant='h1'
            className='text-center text-[22px] leading-[26px]'
          >
            {user?.firstName} {user?.lastName}
          </Typography>

          <ProfileCard className='mt-4' isLoading={isLoading || isPending}>
            <div className='flex items-center justify-between'>
              <Typography
                variant='span'
                className='font-bold !text-primary-400'
              >
                {t('lastItemsPurchased')}
              </Typography>
              <MdChevronRight
                size={24}
                className='flex-shrink-0 text-secondary-buttons-500'
                onClick={navigateToShopPurchased}
              />
            </div>

            <ShopItemList
              items={lastItemsPurchased}
              studentPoints={null}
              hidePrice
              hideEmptyState
              className='my-4'
            />
            <PointsCard />
          </ProfileCard>
        </div>

        <ProfileButtonsNavigation />
      </div>
    </HomeTemplate>
  );
};
