import { FieldValues } from 'react-hook-form';
import { BasicField } from '@components/atoms';
import { InputProps as Props } from '@forms/index';

interface TextareaProps<T extends FieldValues>
  extends Props<T>,
    Omit<React.TextareaHTMLAttributes<HTMLTextAreaElement>, 'name'> {
  placeholder: string;
  label?: string;
  className?: string;
}

const Textarea = <T extends FieldValues>({
  control,
  name,
  rules,
  placeholder,
  containerStyle,
  className,
  label,
  ...rest
}: TextareaProps<T>) => {
  return (
    <BasicField
      containerStyle={containerStyle}
      control={control}
      rules={rules}
      name={name}
      render={({ field, fieldState: { invalid, error } }) => (
        <div>
          <label className='text-[14px]'>{label}</label>
          <textarea
            className={`textarea textarea-bordered w-full rounded-xl  border-primary-200 bg-secondary-surface-200 text-secondary-text-300 shadow-inner placeholder:text-secondary-text-200 focus-within:border-primary-400 focus-within:shadow-inner focus-within:outline-0 disabled:border-primary-200 ${className}`}
            placeholder={placeholder}
            {...field}
            {...rest}
          />
          {invalid && <span className='textarea-error'>{error?.message}</span>}
        </div>
      )}
    />
  );
};

export default Textarea;
