import { ReactNode } from 'react';
import { Button, Layout } from '@components/atoms';
import { ProgressStepper, ProgressStepperProps } from '@components/organisms';
import { MdArrowBack } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

interface SignupTemplateProps
  extends Omit<ProgressStepperProps, 'leftComponent'> {
  children: ReactNode;
  handleNext?: () => void;
  handleBack: () => void;
  skipButton?: () => void;
  disabled?: boolean;
  formId?: string;
  buttonLoading?: boolean;
}

export const SignupTemplate = ({
  children,
  skipButton,
  handleNext,
  handleBack,
  disabled,
  formId,
  buttonLoading,
  ...rest
}: SignupTemplateProps) => {
  const { t } = useTranslation('common');

  return (
    <Layout
      isFlex
      isCentered
      containerStyles='justify-between !bg-secondary-surface-100'
    >
      <div className='w-full space-y-5'>
        <ProgressStepper
          leftComponent={
            <MdArrowBack
              size={24}
              className='cursor-pointer text-primary-400'
              onClick={handleBack}
            />
          }
          {...rest}
          stepperStyles='!text-primary-400'
        />
        {children}
      </div>

      <div className='grid w-full place-items-center space-y-4'>
        <Button
          style='primary'
          type='submit'
          form={formId}
          disabled={disabled}
          onClick={handleNext}
          text={t('button.continue')}
          loading={buttonLoading}
        />
        {skipButton && (
          <Button
            style='link'
            type='button'
            className='!w-full'
            onClick={skipButton}
            text={t('button.finishLater')}
          />
        )}
      </div>
    </Layout>
  );
};
