export const AchievementsAnalytics = {
  // ACTIONS
  actions: {
    achievements: 'Achievements',
  },

  // CATEGORIES
  categories: {
    badgeObtained: 'Badge obtained',
  },

  // LABELS
  labels: {
    badgeObtained: ':date; :userID; :badgeID',
  },
};

export const badgeObtainedLabel = (
  date: string,
  userId: string,
  badgeId: string
) => {
  return AchievementsAnalytics.labels.badgeObtained
    .replace(':date', date)
    .replace(':userID', userId)
    .replace(':badgeID', badgeId);
};
