import { ReactNode, useEffect } from 'react';
import { Typography } from '@components/atoms';
import { MdClear } from 'react-icons/md';
import { AnimatePresence, motion } from 'framer-motion';

export interface ModalProps {
  open: boolean;
  title?: string;
  onClose?: () => void;
  children?: ReactNode;
}

export const Modal = ({ open, onClose, title, children }: ModalProps) => {
  // Disable scrolling and body overflow when modal is open
  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
    return () => {
      document.body.style.overflow = '';
    };
  }, [open]);

  if (!open) return null;

  return (
    <AnimatePresence mode='wait'>
      {open && (
        <motion.div
          initial={{ opacity: 0, zIndex: 10 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className='fixed left-0 top-0 z-10 flex h-screen w-screen items-center justify-center bg-black bg-opacity-40'>
            <motion.div
              className='m-[28px] w-full rounded-xl bg-secondary-surface-200 p-6'
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0 }}
              transition={{
                type: 'spring',
                stiffness: 200,
                damping: 15,
                delay: 0,
              }}
            >
              <div className='mb-4 flex items-center justify-between'>
                {title ? (
                  <Typography variant='h2' className='!text-primary-400'>
                    {title}
                  </Typography>
                ) : (
                  <span></span>
                )}
                <MdClear
                  size={24}
                  className='flex-shrink-0 cursor-pointer text-secondary-buttons-500'
                  onClick={onClose}
                />
              </div>
              {children}
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
