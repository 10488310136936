interface YoutubeEmbedProps {
  className?: string;
  height?: number;
  url: string;
  videoTitle?: string;
  width?: number;
}

export const YoutubeEmbed = ({
  className,
  height = 150,
  url,
  videoTitle,
  width = 300,
}: YoutubeEmbedProps) => {
  return (
    <iframe
      className={`h-full w-full rounded-xl ${className}`}
      src={url}
      title={videoTitle}
      width={width}
      height={height}
      allowFullScreen
    />
  );
};
