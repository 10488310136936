import { useState } from 'react';
import { ModalSheet } from '@components/molecules';
import { useTranslation } from 'react-i18next';
import { BorderContainer, Button, Card, Typography } from '@components/atoms';
import { RETO_STATES_STR } from 'src/domain/constants';

export const GradedRetoCard = ({ reto }: { reto: any }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('components/molecules/gradedRetoCard');

  return (
    <Card className='flex items-center justify-between !bg-secondary-surface-100'>
      <div className='flex flex-col justify-between space-y-4'>
        <div className='flex flex-col'>
          <Typography variant='span' className='font-bold'>
            {reto.reto.title}
          </Typography>
          <Typography variant='span' className='!text-secondary-text-200'>
            {t(`challengeType.${reto.reto.type}`)}
          </Typography>
        </div>
        <Typography variant='span' className='font-bold !text-primary-400'>
          {reto.state === RETO_STATES_STR.graded
            ? t('grade', { grade: reto.reto.pointsAwarded })
            : t('notGradedYet')}
        </Typography>
      </div>
      <Button
        style='link'
        text={t('buttons.viewAnswer')}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <ModalSheet isOpen={isOpen} onClose={() => setIsOpen(false)}>
          <div className='mb-8 space-y-4'>
            <Typography variant='span' className='font-bold'>
              {reto.reto.title}
            </Typography>
            <BorderContainer containerStyles='bg-secondary-surface-200 border border-secondary-buttons-100 rounded-xl p-4'>
              {reto.answer}
            </BorderContainer>
          </div>
        </ModalSheet>
      )}
    </Card>
  );
};
