import { ReactNode } from 'react';
import {
  HomeHeader,
  HomeBottomNavigation,
  HomeHeaderProps,
} from '@components/organisms';
import { Layout, Loader } from '@components/atoms';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';
import { UserRole } from '@domain/constants';
import { AnimatePresence } from 'framer-motion';

interface HomeTemplateProps extends HomeHeaderProps {
  children: ReactNode;
  childrenClassname?: string;
  isLoading?: boolean;
  hideBottomNavigation?: boolean;
  layoutClassname?: string;
}

export const HomeTemplate = ({
  children,
  childrenClassname,
  isLoading,
  isProfilePage,
  hideBottomNavigation,
  onBackButtonClick,
  layoutClassname,
}: HomeTemplateProps) => {
  const userRole = useSelector(UserSelectors.getRole);

  const homePadding =
    userRole === UserRole.STUDENT ? 'pt-[120px] pb-24' : 'pt-[120px] pb-8';

  return (
    <Layout isFlex containerStyles={`!p-0 ${layoutClassname}`}>
      <AnimatePresence mode='wait'>
        <HomeHeader
          isProfilePage={isProfilePage}
          onBackButtonClick={onBackButtonClick}
        />
        {isLoading ? (
          <Loader />
        ) : (
          <section
            className={`flex flex-1 flex-col px-4 ${homePadding} ${childrenClassname}`}
          >
            {children}
          </section>
        )}

        {userRole === UserRole.STUDENT && !hideBottomNavigation && (
          <HomeBottomNavigation />
        )}
      </AnimatePresence>
    </Layout>
  );
};
