import {AnimatePresence, motion} from 'framer-motion';

export const PathLeft = ({ fillColor, animate, index }: { fillColor: string, animate: boolean, index: number }) => {
  return (
    <svg
      width='330'
      height='120'
      viewBox='0 0 330 120'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <defs>
        <filter id='normal-shadow' x='-50%' y='-50%' width='200%' height='200%'>
          <feDropShadow
            dx='0'
            dy='4'
            stdDeviation='4'
            floodColor='rgba(0,0,0,0.25)'
          />
        </filter>
        <filter id='inset-shadow' x='-50%' y='-50%' width='200%' height='200%'>
          <feComponentTransfer in='SourceAlpha'>
            <feFuncA type='table' tableValues='1 0' />
          </feComponentTransfer>
          <feGaussianBlur stdDeviation='4' />
          <feOffset dx='0' dy='4' result='offsetblur' />
          <feFlood floodColor='rgba(0,0,0,0.25)' result='color' />
          <feComposite in2='offsetblur' operator='in' />
          <feComposite in2='SourceAlpha' operator='in' />
          <feMerge>
            <feMergeNode in='SourceGraphic' />
            <feMergeNode />
          </feMerge>
        </filter>
      </defs>
      <AnimatePresence mode='wait'>
      <motion.path
        d='M45.19 119.179C45.19 97.9188 60.8 80.3188 81.17 77.2488H252.1C254.97 77.2488 257.8 77.0788 260.59 76.7688H262.56V76.5188C300.16 71.3988 329.15 39.1388 329.15 0.0588379H283.96C283.96 21.3188 268.35 38.9188 247.98 41.9888H77.05C74.18 41.9888 71.35 42.1588 68.55 42.4688H66.58V42.7188C28.99 47.8388 0 80.1088 0 119.179'
        style={{ fill: fillColor }}
        filter='url(#normal-shadow)'
        initial={ animate ? { clipPath: 'inset(0 0 0 400%)' } : {}} // Start from top (empty)
        animate={animate ? { clipPath: 'inset(0 0 0 0)' } : {}} // Fill from top to bottom
        transition={{ duration: 1.0, delay: (index == 0) ? 0 : index - 0.4 }}
      />
      </AnimatePresence>
    </svg>
  );
};
