import { motion } from 'framer-motion';
import { Divider, DividerProps } from '../Divider/Divider';

export interface AnimatedDividerProps extends DividerProps { 
  animationDelay?: number;
}

const AnimatedDivider = ({ containerStyles, animationDelay }: AnimatedDividerProps) => {
  return (
    <motion.div
      initial={{ width: '0%' }} // Start with no width
      animate={{ width: '100%' }} // Animate to full width
      transition={{
        duration: 1, // Duration of the growth animation (1 second)
        ease: 'easeInOut', // Easing function for a smooth effect
        delay: animationDelay
      }}>
        <Divider containerStyles={containerStyles} />
    </motion.div>
  );
};

export default AnimatedDivider;