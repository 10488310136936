import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { ProgressBarProps } from '../ProgressBar/ProgressBar';

export interface AnimatedProgressBarProps extends ProgressBarProps {
  animationDelay?: number;
}

export const AnimatedProgressBar = ({ containerStyle, value, max, animationDelay = 0 }: AnimatedProgressBarProps) => {
  const [progress, setProgress] = useState(0);

  // Update progress when the value changes
  useEffect(() => {
    setProgress((value / max) * 100); // Calculate the progress percentage
  }, [value, max]);

  return (
    <div
    className={`relative h-3 w-full bg-secondary-surface-200 shadow-inner ${containerStyle}`}
    style={{ position: 'relative', borderRadius: '6px', overflow: 'hidden' }}
    >
      <motion.div
        initial={{ width: 0 }} // Start with 0 width
        animate={{ width: `${progress}%` }} // Animate to the current progress percentage
        transition={{
          delay: animationDelay,
          duration: 0.7, // Adjust duration as needed
          ease: 'easeOut', // Adjust easing as needed
        }}
        className={"absolute top-0 left-0 h-full bg-primary-400 shadow-inner"}
        style={{ borderRadius: 'inherit' }}
      />
      {/* <progress
        className={`progress h-3 w-full bg-secondary-surface-200 shadow-inner progress-filled:bg-primary-400 progress-filled:shadow-inner ${containerStyle}`}
        value={value}
        max={max}
      ></progress> */}
    </div>
  );
};
