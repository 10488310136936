import { useQuery } from '@tanstack/react-query';
import { MdChevronLeft } from 'react-icons/md';
import { ShopContainer, Typography } from '@components/atoms';
import { ModalAvatarSelect, ShopItemList } from '@components/organisms';
import { HomeTemplate } from '@components/templates';
import { useShop } from '@hooks/index';
import { useEffect, useState } from 'react';
import { UserSelectors } from '@store/slices';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { trackGA4PageView } from '@data/utils';
import { PAGE_NAMES } from '@domain/constants';

export const PurchasedAvatarsPage = () => {
  const [avatar, setAvatar] = useState<Record<string, string | number> | null>(
    null
  );

  const { t } = useTranslation('pages/home/shop/shopPurchasedAvatarsPage');
  const studentAvailablePoints = useSelector(UserSelectors.getAvailablePoints);
  const { getShopPurchasedItemsByType, navigateToShopPurchased } = useShop();
  const { data, isLoading, isPending } = useQuery({
    queryKey: ['purchasedShopItemsByAvatar'],
    queryFn: () => getShopPurchasedItemsByType('avatar'),
  });

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: PROTECTED_ROUTES_PATHS.shopPurchasedAvatars,
      title: PAGE_NAMES.shopPurchasedAvatars,
    });
  }, []);

  return (
    <HomeTemplate
      hideBottomNavigation
      childrenClassname='!bg-secondary-surface-100'
    >
      <ShopContainer
        childrenClassname='space-y-4'
        isLoading={isLoading || isPending}
      >
        <MdChevronLeft
          size={24}
          className='cursor-pointer text-secondary-buttons-500'
          onClick={navigateToShopPurchased}
        />
        <Typography variant='h1'>{t('title')}</Typography>
        <ShopItemList
          items={data}
          studentPoints={studentAvailablePoints}
          onClick={item => setAvatar(item)}
          hidePrice
        />
        <ModalAvatarSelect
          open={!!avatar}
          handleCancel={() => setAvatar(null)}
          {...avatar}
        />
      </ShopContainer>
    </HomeTemplate>
  );
};
