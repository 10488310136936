import { LessonPathCard, LessonPathCardProps } from '@components/molecules';

interface LessonsPathProps {
  lessons: LessonPathCardProps[];
}

export const LessonsPath = ({ lessons }: LessonsPathProps) => {
  return (
    <div className='mb-32 w-full -space-y-2'>
      {lessons.map((lesson, index) => (
        <LessonPathCard key={index} {...lesson} index={index} />
      ))}
    </div>
  );
};
