import { useCallback } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Api } from '@data/api';
import { WebClientRest } from '@data/api/clients';
import { HttpStatusCode } from '@domain/api';
import { NotificationsDispatcher } from '@data/utils';
import { ToastNotificationType } from '@domain/constants';
import { useTranslation } from 'react-i18next';

export const usePrayerGame = () => {
  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });
  const api = new Api(apiInstance);
  const { t } = useTranslation(['common']);

  const submitDailyPrayerGame = useCallback(async (
    id: string,
    points: number,
    allCorrectAnswers: boolean,
    answers: { [key: number]: string }
  ) => {
    try {
      const response = await api.prayerGame.submitDailyPrayerGame(id, {
        points,
        all_correct_answers: allCorrectAnswers,
        answer: answers,
      });

      if (response.status === HttpStatusCode.OK) {
        return response.data;
      }

      throw new Error('Error submitting daily prayer game');
    } catch (error: any) {
      console.error('Error submitting daily prayer game:', error);

      let errorMessage = 'An unexpected error occurred';
      if (error.response && error.response.data) {
        if (Array.isArray(error.response.data.errors)) {
          errorMessage = error.response.data.errors[0];
        } else if (typeof error.response.data.error === 'string') {
          errorMessage = error.response.data.error;
        } else if (typeof error.response.data.message === 'string') {
          errorMessage = error.response.data.message;
        }
      }

      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage,
      });
      throw error;
    }
  }, [api.prayerGame, t]);

  const submitDailyPrayerGameMutation = useMutation({
    mutationFn: ({ id, points, allCorrectAnswers, answers }: {
      id: string;
      points: number;
      allCorrectAnswers: boolean;
      answers: { [key: number]: string };
    }) => submitDailyPrayerGame(id, points, allCorrectAnswers, answers),
  });

  return {
    submitDailyPrayerGame: submitDailyPrayerGameMutation,
  };
};
