import { useRetos } from '@hooks/index';
import {
  BorderContainer,
  Button,
  RichTextTypography,
  TabLayout,
  Textarea,
  Typography,
  YoutubeEmbed,
} from '@components/atoms';
import { TabNavigation, ModalSheetRetoAnswer } from '@components/organisms';
import { HomeTemplate } from '@components/templates';
import { MdChevronLeft } from 'react-icons/md';
import { DueDate } from '@components/molecules';
import { useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  NotificationsDispatcher,
  trackGA4PageView,
  convertToUTCMinus4,
} from '@data/utils';
import {
  ChallengeType,
  PAGE_NAMES,
  RETO_STATES_STR,
  ToastNotificationType,
} from '@domain/constants';
import { useLocation, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

type Inputs = {
  answer: string;
};

export const RetosAnswerPage = () => {
  const { navigateToRetos, submitRetoResponseMutation, getRetoDetails } =
    useRetos();
  const location = useLocation();
  const { retoId } = useParams();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [retoType, setRetoType] = useState<ChallengeType | undefined>();

  const { data, isLoading, isPending, isRefetching, refetch } = useQuery({
    queryKey: ['retoDetails', retoId],
    queryFn: getRetoDetails,
    retry: false,
  });

  const { t } = useTranslation([
    'common',
    'pages/home/home/classes/retos/retoAnswerPage',
  ]);

  const handleAnswerSubmit = async (inputs: Inputs, event: any) => {
    try {
      const state = event.nativeEvent.submitter.value;

      await submitRetoResponseMutation.mutateAsync({
        retoResponse: { answer: inputs.answer, state },
        retoType,
      });

      const notificationState =
        state === RETO_STATES_STR.draft ? 'successDraft' : 'successSubmitted';

      NotificationsDispatcher({
        type: ToastNotificationType.success,
        title: t(`toastNotifications.${notificationState}.title`, {
          ns: 'pages/home/home/classes/retos/retoAnswerPage',
        }),
        message: t(`toastNotifications.${notificationState}.message`, {
          ns: 'pages/home/home/classes/retos/retoAnswerPage',
        }),
      });

      refetch();
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
    }
  };

  const currentDate = new Date();

  const currentDateInUTCMinus4 = convertToUTCMinus4(currentDate);

  const isRetoExpired = data?.dueDate < currentDateInUTCMinus4;
  const isRetoGraded = data?.studentResponse.state === RETO_STATES_STR.graded;

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isSubmitting },
  } = useForm<Inputs>({
    defaultValues: {
      answer: data?.studentResponse?.answer,
    },
    mode: 'onTouched',
  });

  useEffect(() => {
    if (isLoading || isPending || isRefetching) {
      return;
    }

    setRetoType(data?.type);
    setValue('answer', data?.studentResponse?.answer);
    setIsSubmitted(!!data?.studentResponse?.answer);
  }, [
    data?.studentResponse?.answer,
    setValue,
    data?.type,
    isRefetching,
    isLoading,
    isPending,
  ]);

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: location.pathname,
      title: PAGE_NAMES.retosAnswer,
    });
  }, [location.pathname]);

  return (
    <HomeTemplate
      childrenClassname='!px-0'
      layoutClassname='!bg-secondary-surface-100'
    >
      <TabNavigation />
      <TabLayout
        className='flex flex-col'
        isLoading={isLoading || isPending || isRefetching}
      >
        <MdChevronLeft
          size={24}
          className='flex-shrink-0 cursor-pointer text-secondary-buttons-500'
          onClick={navigateToRetos}
        />
        <div className='my-6 space-y-4'>
          <Typography
            variant='p'
            className='text-[16px] font-bold leading-[20px]'
          >
            {data?.subtitle}
          </Typography>
          {data?.description && (
            <RichTextTypography description={data?.description} />
          )}
          {data?.youtubeURL && (
            <YoutubeEmbed url={data?.youtubeURL} className='!h-60' />
          )}
        </div>

        {!isRetoExpired && <DueDate date={data?.dueDate} />}

        {!isRetoExpired && (
          <form
            onSubmit={handleSubmit((data, event) =>
              handleAnswerSubmit(data, event)
            )}
            className='mt-6 space-y-6'
            id='submit-answer'
          >
            {isSubmitted ? (
              <div></div>
            ) : (
              <Textarea
                control={control}
                name='answer'
                placeholder='Your answer...'
                className='h-28 max-h-32 resize-none overflow-auto leading-[18px]'
                readOnly={isSubmitted || isRetoExpired}
                autoCorrect='off'
                rules={{
                  required: true,
                }}
                inputMode='text'
              />
            )}
          </form>
        )}

        {isRetoExpired ? (
          <BorderContainer containerStyles='bg-secondary-surface-100 mt-6 border border-primary-200 flex flex-col items-center px-4 py-2 text-center gap-2'>
            <Typography variant='span' className='font-bold !text-primary-400'>
              {t('dueDate.title', {
                ns: 'pages/home/home/classes/retos/retoAnswerPage',
              })}
            </Typography>
            <Typography variant='span' className='!text-primary-300'>
              {t('dueDate.message', {
                ns: 'pages/home/home/classes/retos/retoAnswerPage',
              })}
            </Typography>
          </BorderContainer>
        ) : isRetoGraded ? (
          <BorderContainer containerStyles='bg-secondary-surface-100 mt-6 border border-primary-200 flex flex-col items-center px-4 py-2 text-center gap-2'>
            <Typography variant='span' className='font-bold !text-primary-400'>
              {t('graded.title', {
                ns: 'pages/home/home/classes/retos/retoAnswerPage',
              })}
            </Typography>
            <Typography variant='span' className='!text-primary-300'>
              {t('graded.message', {
                ns: 'pages/home/home/classes/retos/retoAnswerPage',
                points: data?.studentResponse?.points,
              })}
            </Typography>
          </BorderContainer>
        ) : !isSubmitted ? (
          <div className='mt-4 flex w-full gap-2'>
            <Button
              form='submit-answer'
              type='submit'
              style='secondary'
              className='flex-1'
              disabled={!isValid || isSubmitted}
              loading={isSubmitting}
              text={t('buttons.saveAsDraft', {
                ns: 'pages/home/home/classes/retos/retoAnswerPage',
              })}
              name='action'
              value={RETO_STATES_STR.draft}
            />
            <Button
              form='submit-answer'
              type='submit'
              style='primary'
              className='flex-1'
              disabled={!isValid || isSubmitting}
              loading={isSubmitting}
              text={t('buttons.submitAnswer', {
                ns: 'pages/home/home/classes/retos/retoAnswerPage',
              })}
              name='action'
              value={RETO_STATES_STR.submitted}
            />
          </div>
        ) : (
          <ModalSheetRetoAnswer
            answer={data?.studentResponse?.answer}
            retoType={retoType!}
            state={data?.studentResponse?.state}
            refetch={refetch}
          />
        )}
      </TabLayout>
    </HomeTemplate>
  );
};
