import { useCallback, useEffect, useState } from 'react';
import { usePubNub } from 'pubnub-react';
import { Marker } from 'react-image-marker';
import {
  ActivitySubtype,
  UseActivityProps,
  ActivityPayload,
  SlideActionType,
  CustomMessageEvent,
  SlideBodyType,
} from '@domain/constants';
import { useSelector } from 'react-redux';
import { UserSelectors } from '@store/slices';

interface PinMessage {
  top: number;
  left: number;
}

export const usePin = ({ channel, slideId }: UseActivityProps) => {
  const [markers, setMarkers] = useState<Marker[]>([]);

  const pubnub = usePubNub();
  const userId = useSelector(UserSelectors.getId);

  const handlePinMarker = useCallback(
    (event: CustomMessageEvent) => {
      const body = event.message.body;

      if (
        body.type === SlideBodyType.ACTIVITY &&
        body.activity_type === ActivitySubtype.PIN_IT &&
        body.activity_id === slideId
      ) {
        setMarkers(markers => [
          ...markers,
          {
            top: body.y_position!,
            left: body.x_position!,
          },
        ]);
      }
    },
    [slideId]
  );

  const sendPinMarker = async (message: PinMessage) => {
    if (message) {
      const { top, left } = message;

      const messagePayload: ActivityPayload = {
        uuid: userId,
        action: SlideActionType.ACTIVITY,
        body: {
          type: SlideBodyType.ACTIVITY,
          activity_type: ActivitySubtype.PIN_IT,
          x_position: left,
          y_position: top,
          activity_id: slideId,
        },
        subscribed_channel: channel,
        actual_channel: channel,
      };

      await pubnub.publish({
        channel: channel,
        message: messagePayload,
      });
    }
  };

  useEffect(() => {
    const listenerParams = {
      message: handlePinMarker,
    };

    pubnub.addListener(listenerParams);
    pubnub.subscribe({ channels: [channel] });

    return () => {
      pubnub.removeListener(listenerParams);
    };
  }, [pubnub, channel, handlePinMarker]);

  useEffect(() => {
    setMarkers([]);
  }, [slideId]);

  return {
    markers,
    setMarkers,
    sendPinMarker,
  };
};
