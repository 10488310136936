import { Divider, Typography } from '@components/atoms';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../../tailwind.config';

const fullConfig = resolveConfig(tailwindConfig);

const colors = [
  fullConfig.theme.colors.primary[400],
  fullConfig.theme.colors.secondary.phasethree[400],
  fullConfig.theme.colors.secondary.phasetwo[400],
  fullConfig.theme.colors.secondary.phasefour[400],
];

interface GraphEmptyStateProps {
  options: string[];
}

interface GraphItemProps {
  label: string;
  color: string;
}

const GraphItem = ({ label, color }: GraphItemProps) => {
  return (
    <div className='grid place-items-center gap-4'>
      <div
        className='h-2 w-[70px] rounded-xl'
        style={{ backgroundColor: color }}
      ></div>
      <Typography
        variant='span'
        className='text-[16px] font-bold leading-5 !text-secondary-text-200'
      >
        {label}
      </Typography>
    </div>
  );
};

export const GraphEmptyState = ({ options }: GraphEmptyStateProps) => {
  return (
    <div className='mt-40'>
      <div className='flex justify-between'>
        {options.map((option, index) => (
          <GraphItem key={option} label={option} color={colors[index]} />
        ))}
      </div>
      <Divider />
    </div>
  );
};
