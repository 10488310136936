import { VictoryBar, VictoryChart, VictoryAxis } from 'victory';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../../../../../tailwind.config';
import { BorderContainer, Divider, Typography } from '@components/atoms';
import { useTranslation } from 'react-i18next';
import { GraphEmptyState } from '@components/molecules';
import './PollActivityResponses.css';

const fullConfig = resolveConfig(tailwindConfig);
const purple = fullConfig.theme.colors.primary[400];
const blue = fullConfig.theme.colors.secondary.phasetwo[400];
const yellow = fullConfig.theme.colors.secondary.phasethree[400];
const red = fullConfig.theme.colors.secondary.phasefour[400];

interface PollActivityResponsesProps {
  containerStyle?: string;
  data: PollData[];
  correctAnswer?: string;
  options: string[];
}

export interface PollData {
  x: string;
  y: number;
}

const colorMap = [purple, yellow, blue, red];

export const PollActivityResponses = ({
  containerStyle,
  data,
  correctAnswer,
  options,
}: PollActivityResponsesProps) => {
  const { t } = useTranslation('components/molecules/pollActivityResponses');

  const mapStringsToAlphabet = (input: string[]): Record<string, string> => {
    const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const result: Record<string, string> = {};

    input.forEach((item, index) => {
      if (index < alphabet.length) {
        result[alphabet[index]] = item;
      } else {
        throw new Error(
          'Input array length exceeds available alphabet letters.'
        );
      }
    });

    return result;
  };

  const transformDataWithAlphabet = (
    data: PollData[],
    mapping: Record<string, string>
  ): PollData[] => {
    const reverseMapping: Record<string, string> = {};
    for (const [letter, value] of Object.entries(mapping)) {
      reverseMapping[value] = letter;
    }

    return data.map(item => ({
      x: reverseMapping[item.x],
      y: item.y,
    }));
  };

  const formattedOptions = mapStringsToAlphabet(options);
  const transformedData = transformDataWithAlphabet(data, formattedOptions);

  return (
    <div className={containerStyle}>
      {data.length > 0 ? (
        <div>
          <VictoryChart 
            height={270}
            animate={{duration: 500, onEnter:{duration:50}}}
          >
            <VictoryBar
              style={{
                data: {
                  width: 90,
                  fill: ({ index }) => colorMap[index % colorMap.length],
                },
              }}
              cornerRadius={12}
              data={transformedData}
              alignment='middle'
            />
            <VictoryAxis
              style={{
                axis: { stroke: 'transparent' },
                tickLabels: {
                  fill: '#999999',
                  fontFamily: 'Roboto',
                  fontSize: '16px',
                  fontWeight: '700',
                  lineHeight: '20px',
                },
              }}
            />
          </VictoryChart>
          <Divider />
        </div>
      ) : (
        <GraphEmptyState options={Object.keys(formattedOptions)} />
      )}
      <div className='mb-4'>
        {Object.keys(formattedOptions).map(option => (
          <Typography key={option} variant='p'>
            {option}. {formattedOptions[option]}
          </Typography>
        ))}
      </div>
      {correctAnswer && (
        <BorderContainer containerStyles='flex flex-col items-center justify-center gap-2 bg-primary-100'>
          <Typography variant='span'>{t('correctAnswer')}</Typography>
          <Typography
            variant='span'
            className='text-[24px] font-bold leading-[28px]'
          >
            {
              Object.entries(formattedOptions).find(
                ([_, val]) => val === correctAnswer
              )[0]
            }
          </Typography>
        </BorderContainer>
      )}
    </div>
  );
};
