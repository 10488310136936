import { ShopItem } from '@components/molecules';
import {
  ModalConfirmation,
  ModalConfirmationProps,
} from '@components/organisms';
import { useTranslation } from 'react-i18next';
import { useProfile } from 'src/ui/hooks';

interface ModalAvatarSelect extends ModalConfirmationProps {
  id: number;
  image: string;
  name: string;
  price: number;
  setAvatar: (value: Record<string, string | number> | null) => void;
}

export const ModalAvatarSelect = ({
  id,
  image,
  name,
  handleCancel,
  open,
  setAvatar,
}: ModalAvatarSelect) => {
  const { t } = useTranslation('components/organisms/modalAvatarSelect');

  const { editAvatarMutation } = useProfile();

  const handleSelectAvatar = async () => {
    try {
      await editAvatarMutation.mutateAsync(id);
      setAvatar(null);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ModalConfirmation
      open={open}
      title={t('title')}
      handleConfirm={handleSelectAvatar}
      handleConfirmLoading={editAvatarMutation.isPending}
      handleCancel={handleCancel}
    >
      <div className='flex w-full flex-col items-center '>
        <ShopItem id={id} name={name} avatarImage={image} price={null} />
      </div>
    </ModalConfirmation>
  );
};
