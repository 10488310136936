import { useMutation } from '@tanstack/react-query';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { loginSuccess } from '@store/slices';
import {
  PROTECTED_ROUTES_PATHS,
  PUBLIC_ROUTES_PATHS,
} from '@navigation/routes/RoutesPaths';
import { WebClientRest } from '@data/api/clients';
import { Api } from '@data/api';
import { HttpStatusCode } from '@domain/api';
import {
  AUTH_TOKEN_STORAGE_KEY,
  ToastNotificationType,
  LoginAnalytics,
} from '@domain/constants';
import { NotificationsDispatcher, trackGA4Events } from '@data/utils';
import { loginAdapter } from '@data/adapters';
import { useTranslation } from 'react-i18next';
import { queryClient } from 'src/main';

interface LoginData {
  login: string;
  password: string;
}

export const useLogin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });

  const { t } = useTranslation('common');

  const api = new Api(apiInstance);

  const getLoginData = async ({ login, password }: LoginData) => {
    try {
      const response = await api.users.loginUser({
        login,
        password,
      });

      if (response.status === HttpStatusCode.OK) {
        queryClient.clear();
        const user = loginAdapter(response.data);

        localStorage.setItem(
          AUTH_TOKEN_STORAGE_KEY,
          response.headers.authorization
        );

        dispatch(
          loginSuccess({
            ...user,
          })
        );

        trackGA4Events(
          LoginAnalytics.categories.login,
          LoginAnalytics.actions.login,
          LoginAnalytics.labels.login
        );

        navigate(PROTECTED_ROUTES_PATHS.home);
      }
      return;
    } catch (error: unknown) {
      const errorMessage = error.response.data.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
    }
  };

  const navigateToSignup = () => {
    navigate(PUBLIC_ROUTES_PATHS.signup.replace(':classCode', ''));
  };

  const navigateToForgotPassword = () => {
    navigate(PUBLIC_ROUTES_PATHS.forgotPassword);
  };

  const loginUserMutation = useMutation({
    mutationFn: ({ login, password }: LoginData) =>
      getLoginData({ login, password }),
  });

  return {
    loginUserMutation,
    navigateToSignup,
    navigateToForgotPassword,
  };
};
