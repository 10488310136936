export const LessonsAnalytics = {
  // ACTIONS
  actions: {
    lesson: 'Lesson',
  },

  // CATEGORIES
  categories: {
    joinLesson: 'Join Lesson',
    lessonStart: 'Lesson Start',
    lessonCompleted: 'Lesson Completed',
  },

  // LABELS
  labels: {
    joinLesson: ':date; :userID; :lessonID',
    lessonStart: ':date; :userID; :lessonID',
    lessonCompleted: ':date; :userID; :lessonID',
  },
};

export const joinLessonLabel = (
  date: string,
  userId: string,
  lessonId: string
) => {
  return LessonsAnalytics.labels.joinLesson
    .replace(':date', date)
    .replace(':userID', userId)
    .replace(':lessonID', lessonId);
};

export const lessonStartLabel = (
  date: string,
  userId: string,
  lessonId: string
) => {
  return LessonsAnalytics.labels.lessonStart
    .replace(':date', date)
    .replace(':userID', userId)
    .replace(':lessonID', lessonId);
};

export const lessonCompletedLabel = (
  date: string,
  userId: string,
  lessonId: string
) => {
  return LessonsAnalytics.labels.lessonCompleted
    .replace(':date', date)
    .replace(':userID', userId)
    .replace(':lessonID', lessonId);
};
