import { Content, UserRole } from '@domain/constants';
import { SlideContentWrapper } from '../SlideContentWrapper';
import {
  PollActivityInput,
  PollActivityResponses,
} from '@components/molecules';
import { usePoll } from '@hooks/index';
import { useState } from 'react';

interface RenderPollActivityProps {
  slide: Content;
  role: UserRole;
  channel: string;
  showResults: boolean;
}

export const RenderPollActivity = ({
  slide,
  role,
  channel,
  showResults,
}: RenderPollActivityProps) => {
  const { pollResponses, sendPollResponse } = usePoll({
    channel,
    slideId: slide.id,
  });

  const [selectedOption, setSelectedOption] = useState<string>('');

  return (
    <SlideContentWrapper
      title={slide.poll!.question}
      type={slide.type}
      subtype={slide.subtype}
    >
      {role === UserRole.CATECHIST ? (
        <PollActivityResponses
          data={pollResponses}
          options={slide.poll!.options}
        />
      ) : !showResults ? (
        <PollActivityInput
          options={slide.poll!.options}
          onClickOption={sendPollResponse}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
        />
      ) : (
        <PollActivityResponses
          data={pollResponses}
          options={slide.poll!.options}
        />
      )}
    </SlideContentWrapper>
  );
};
