import React from 'react';
import { Typography } from '@components/atoms';

interface PrayerTextProps {
  text: string;
  renderDropZone: (index: number) => React.ReactNode;
  blankPositions: number[];
}

const PrayerText: React.FC<PrayerTextProps> = ({ text, renderDropZone }) => {
  const words = text.split(/(\s+)/);
  let blankIndex = 0;

  return (
    <Typography variant="p" className="text-base sm:text-lg md:text-xl leading-relaxed">
      {words.map((word, index) => {
        if (word.startsWith('#blank')) {
          const dropZone = renderDropZone(blankIndex);
          blankIndex++;
          return <React.Fragment key={index}>{dropZone}</React.Fragment>;
        }
        return <React.Fragment key={index}><span className="mx-0.5 ">{word}</span></React.Fragment>;
      })}
    </Typography>
  );
};

export default PrayerText;
