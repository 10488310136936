export const RetosAnalytics = {
  // ACTIONS
  actions: {
    reto: 'Reto',
  },

  // CATEGORIES
  categories: {
    retoStart: 'Reto Start',
    retoCompleted: 'Reto Completed',
  },

  // LABELS
  labels: {
    retoStart: ':date; :retoID',
    retoCompleted: ':date; userID; :retoID',
  },
};

export const retoStartLabel = (date: string, retoId: string) => {
  return RetosAnalytics.labels.retoStart
    .replace(':date', date)
    .replace(':retoID', retoId);
};

export const retoCompletedLabel = (
  date: string,
  userId: string,
  retoId: string
) => {
  return RetosAnalytics.labels.retoCompleted
    .replace(':date', date)
    .replace(':userID', userId)
    .replace(':retoID', retoId);
};
