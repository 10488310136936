export enum ActivityType {
  SLIDE = 'slide',
  ACTIVITY = 'interactive_activity',
}

export enum ActivitySubtype {
  TEXT = 'text',
  VIDEO = 'video',
  IMAGE = 'image',
  WORD_CLOUD = 'word_cloud',
  PIN_IT = 'pin_it',
  POLL = 'poll',
  QUIZ = 'quiz',
  OPEN_QUESTION = 'open_question',
}
