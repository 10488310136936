import { useNavigate } from 'react-router-dom';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useDispatch, useSelector } from 'react-redux';
import { updateAvatar, updateName, UserSelectors } from '@store/slices';
import { HttpStatusCode } from '@domain/api';
import { Api } from '@data/api';
import { WebClientRest } from '@data/api/clients';
import { useMutation } from '@tanstack/react-query';
import { NotificationsDispatcher } from '@data/utils';
import { useTranslation } from 'react-i18next';
import { ToastNotificationType, UserRole } from '@domain/constants';
import { editParentsAdapter, loginAdapter } from '@data/adapters';

interface ChangePasswordData {
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

export const useProfile = () => {
  const navigate = useNavigate();
  const studentTotalPoints = useSelector(UserSelectors.getLifetimePoints);
  const catechistPhoneNumber = useSelector(UserSelectors.getPhoneNumber);
  const catechistEmail = useSelector(UserSelectors.getEmail);
  const userRole = useSelector(UserSelectors.getRole);

  const apiInstance = new WebClientRest(import.meta.env.VITE_API_BASE_URL, {
    'Content-Type': 'application/json',
  });
  const api = new Api(apiInstance);

  const { t } = useTranslation([
    'common',
    'pages/home/profile/student/details/editName/editNamePage',
    'pages/home/profile/student/details/editParents/editParentsPage',
    'pages/home/profile/student/details/selectAvatar/selectAvatarPage',
  ]);
  const dispatch = useDispatch();

  const navigateToProfile = () => {
    navigate(PROTECTED_ROUTES_PATHS.profile);
  };

  const navigateToProfileDetails = () => {
    navigate(PROTECTED_ROUTES_PATHS.profileDetails);
  };

  const navigateToChangePassword = () => {
    navigate(PROTECTED_ROUTES_PATHS.changePassword);
  };

  const navigateToEditName = () => {
    navigate(PROTECTED_ROUTES_PATHS.profileDetailsEditName);
  };

  const navigateToShop = () => {
    navigate(PROTECTED_ROUTES_PATHS.shop);
  };

  const navigateToParents = () => {
    navigate(PROTECTED_ROUTES_PATHS.profileDetailsEditParents);
  };

  const getUser = async () => {
    try {
      const response = await api.users.getUser();

      if (response.status === HttpStatusCode.OK) {
        return loginAdapter(response.data);
      }
    } catch (error) {
      const errorMessage = error.response.data.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const changePassword = async ({
    oldPassword,
    password,
    confirmPassword,
  }: ChangePasswordData) => {
    try {
      const body = {
        old_password: oldPassword,
        password: password,
        confirm_password: confirmPassword,
      };

      const response = await api.users.changePassword(body);

      if (response.status === HttpStatusCode.OK) {
        NotificationsDispatcher({
          type: ToastNotificationType.success,
          title: t('toastNotifications.success.title', {
            ns: 'pages/home/profile/changePassword/changePasswordPage',
          }),
          message: t('toastNotifications.success.message', {
            ns: 'pages/home/profile/changePassword/changePasswordPage',
          }),
        });

        userRole === UserRole.STUDENT
          ? navigateToProfileDetails()
          : navigateToProfile();
      }
    } catch (error) {
      const errorMessage = error.response.data.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const editParents = async ({
    data,
    oldData,
  }: {
    data: Record<string, string | number>[];
    oldData: Record<string, string | number>[];
  }) => {
    try {
      const body = editParentsAdapter(data, oldData);

      const response = await api.users.editUser(body);

      if (response.status === HttpStatusCode.OK) {
        NotificationsDispatcher({
          type: ToastNotificationType.success,
          title: t('toastNotifications.success.title', {
            ns: 'pages/home/profile/student/details/editParents/editParentsPage',
          }),
          message: t('toastNotifications.success.message', {
            ns: 'pages/home/profile/student/details/editParents/editParentsPage',
          }),
        });

        navigateToProfileDetails();
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const editName = async ({
    firstName,
    lastName,
  }: {
    firstName: string;
    lastName: string;
  }) => {
    try {
      const body = {
        first_name: firstName,
        last_name: lastName,
      };

      const response = await api.users.editUser(body);

      if (response.status === HttpStatusCode.OK) {
        const user = loginAdapter(response.data);

        dispatch(updateName(user));

        NotificationsDispatcher({
          type: ToastNotificationType.success,
          title: t('toastNotifications.success.title', {
            ns: 'pages/home/profile/student/details/editName/editNamePage',
          }),
          message: t('toastNotifications.success.message', {
            ns: 'pages/home/profile/student/details/editName/editNamePage',
          }),
        });

        navigateToProfileDetails();
      }
    } catch (error) {
      const errorMessage = error.response.data.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const editAvatar = async (avatarId: number) => {
    const body = {
      shop_id: avatarId,
    };

    try {
      const response = await api.users.editUser(body);

      if (response.status === HttpStatusCode.OK) {
        const updatedUser = loginAdapter(response.data);
        dispatch(updateAvatar(updatedUser.avatar));

        NotificationsDispatcher({
          type: ToastNotificationType.success,
          title: t('toastNotifications.success.title', {
            ns: 'pages/home/profile/student/details/selectAvatar/selectAvatarPage',
          }),
          message: t('toastNotifications.success.message', {
            ns: 'pages/home/profile/student/details/selectAvatar/selectAvatarPage',
          }),
        });
      }
    } catch (error) {
      const errorMessage = error.response.data.errors[0];
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.errors.generalTitle'),
        message: errorMessage || t('toastNotifications.errors.generalMessage'),
      });
      throw error;
    }
  };

  const changePasswordMutation = useMutation({
    mutationFn: ({
      oldPassword,
      password,
      confirmPassword,
    }: ChangePasswordData) =>
      changePassword({ oldPassword, password, confirmPassword }),
  });

  const editNameMutation = useMutation({
    mutationFn: ({
      firstName,
      lastName,
    }: {
      firstName: string;
      lastName: string;
    }) => editName({ firstName, lastName }),
  });

  const editParentsMutation = useMutation({
    mutationFn: ({ data, oldData }) => editParents({ data, oldData }),
  });

  const editAvatarMutation = useMutation({
    mutationFn: (avatarId: number) => editAvatar(avatarId),
  });

  return {
    catechistEmail,
    catechistPhoneNumber,
    navigateToChangePassword,
    navigateToEditName,
    navigateToProfileDetails,
    navigateToProfile,
    studentTotalPoints,
    navigateToShop,
    navigateToParents,
    changePasswordMutation,
    editNameMutation,
    editParentsMutation,
    editAvatarMutation,
    getUser,
  };
};
