import React, { useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { TacoIcon } from '@assets/svg';

interface AnimatedScoreProps {
  score: number;
  lastPoints: number;
  isHalfPoints: boolean;
}

export const AnimatedScore: React.FC<AnimatedScoreProps> = ({ score, lastPoints, isHalfPoints }) => {
  const [showAnimation, setShowAnimation] = useState(false);

  useEffect(() => {
    if (lastPoints > 0) {
      setShowAnimation(true);
      const timer = setTimeout(() => setShowAnimation(false), 1000);
      return () => clearTimeout(timer);
    }
  }, [lastPoints]);

  const displayPoints = isHalfPoints ? Math.floor(lastPoints / 2) : lastPoints;

  return (
    <div className='flex items-center relative'>
      <AnimatePresence>
        {showAnimation && lastPoints > 0 && (
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: 20 }}
            transition={{ duration: 0.5 }}
            className='absolute right-full mr-2 text-secondary-semantic-success-400 font-bold'
          >
            +{displayPoints}
          </motion.div>
        )}
      </AnimatePresence>
      <div className='flex items-center bg-white rounded-full px-3 py-1'>
        <span className='mr-2 font-bold text-purple-600'>{score}</span>
        <img src={TacoIcon} alt='Taco' className='h-5 w-5' />
      </div>
    </div>
  );
};