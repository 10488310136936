import { Typography, Avatar, Loader } from '@components/atoms';
import { SignupProps } from './index';
import { useSignup } from '@hooks/index';
import { OnboardingBlocker, SignupTemplate } from '@components/templates';
import { PAGE_NAMES, SIGNUP_STEPS_VALUES } from '@domain/constants';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { OnboardingSelectors } from '@store/slices';
import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { trackGA4PageView } from '@data/utils';

export const SignupAvatarPage = ({
  handleNextStep,
  handlePrevStep,
}: SignupProps) => {
  const avatarId = useSelector(OnboardingSelectors.getAvatar);
  const { createUserMutation, getFreeAvatars, setAvatarImage } = useSignup();
  const { t } = useTranslation('pages/auth/signup/signupAvatarPage');

  const { data, isPending, isLoading, isError } = useQuery({
    queryKey: ['getFreeAvatars'],
    queryFn: getFreeAvatars,
    retry: false,
  });

  const handleAvatarClick = (id: number): void => {
    setAvatarImage(id);
  };

  const handleSubmit = async () => {
    try {
      await createUserMutation.mutateAsync();
      handleNextStep();
    } catch (_) {
      console.log('error');
    }
  };

  // Google analytics page view effect
  useEffect(() => {
    trackGA4PageView({
      path: '/signup',
      title: PAGE_NAMES.signupAvatarPage,
    });
  }, []);

  return (
    <>
      <SignupTemplate
        handleBack={handlePrevStep}
        handleNext={handleSubmit}
        currentStep={SIGNUP_STEPS_VALUES.AVATAR}
        maxSteps={Object.keys(SIGNUP_STEPS_VALUES).length}
        formId='submit-guardian-form'
        disabled={avatarId === null || createUserMutation.isPending}
        buttonLoading={createUserMutation.isPending}
      >
        <div className='h-full flex-grow'>
          <Typography variant='h1'>{t('title')}</Typography>
          <Typography variant='span'>{t('subtitle')}</Typography>

          {isPending || isLoading ? (
            <Loader />
          ) : isError ? (
            <div className='flex h-[55vh] flex-1 items-center justify-center'>
              <Typography variant='span' className='text-center font-bold'>
                {t('emptyStates.noAvatars')}
              </Typography>
            </div>
          ) : data.length && data.length === 0 ? (
            <div className='flex h-[55vh] flex-1 items-center justify-center'>
              <Typography variant='span' className='text-center font-bold'>
                {t('emptyStates.noAvatars')}
              </Typography>
            </div>
          ) : (
            <div className='flex h-[55vh] flex-wrap items-start justify-center gap-8 overflow-auto px-4 py-4'>
              {data.map(avatar => {
                return (
                  <Avatar
                    containerStyle='w-32 h-32'
                    className='h-32 w-32'
                    image={avatar.image_url}
                    key={avatar.id}
                    isActive={avatar.id === avatarId}
                    onClick={() => handleAvatarClick(avatar.id)}
                  />
                );
              })}
            </div>
          )}
        </div>
      </SignupTemplate>
      <OnboardingBlocker />
    </>
  );
};
