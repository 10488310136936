import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { HomeTemplate } from '@components/templates';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { ModalSheetDailyPrayer } from '@components/templates/ModalSheetDailyPrayer/ModalSheetDailyPrayer';
import { DailyPrayerGame } from '@components/templates/DailyPrayerGame/DailyPrayerGame';
import { Button } from '@components/atoms';
import { getDailyPrayerGameStorage, NotificationsDispatcher } from '@data/utils';
import { usePrayerGame } from '@hooks/index';
import { CompleteDailyPrayer } from '@components/molecules/CompleteDailyPrayer/CompleteDailyPrayer';
import { ModalSheetPointsGlobal } from '@components/templates/ModalSheetPointsGlobal/ModalSheetPointsGlobal';
import { openPointsModal } from '@store/slices';
import { useTranslation } from 'react-i18next';
import { ToastNotificationType } from '@domain/constants';
import { useHome } from '@hooks/useHome'; 

const LAST_PLAYED_KEY = 'lastPlayedDailyPrayerGame';

export const PrayerGamePage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [gameStarted, setGameStarted] = useState(false);
  const [score, setScore] = useState(0);
  const [dailyPrayerGame, setDailyPrayerGame] = useState<any>(null);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [, setUserAnswers] = useState<{ [key: number]: string }>({});
  const [, setTotalPoints] = useState(0);
  const [isPaused, setIsPaused] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { submitDailyPrayerGame } = usePrayerGame();
  const toastShownRef = useRef(false);
  const { t, ready } = useTranslation(['pages/home/prayerGame/prayerGamePage']);
  const [translationsLoaded, setTranslationsLoaded] = useState(false);
  const { getHome } = useHome(); 

  const userAnswersRef = useRef<{ [key: number]: string }>({});
  const totalPointsRef = useRef(0);

  const [currentSentence, setCurrentSentence] = useState<any>(null);
  const [scoreMultiplier, setScoreMultiplier] = useState(1);
  const [isHalfPoints, setIsHalfPoints] = useState(false);

  const [finalPoints, setFinalPoints] = useState(0);

  useEffect(() => {
    if (ready) {
      setTranslationsLoaded(true);
    }
  }, [ready]);

  useEffect(() => {
    if (!translationsLoaded) return;

    const storedData = getDailyPrayerGameStorage();
    if (storedData && storedData.sentences && storedData.sentences.length > 0) {
      setDailyPrayerGame(storedData);
      setTimeLeft(storedData.timer);
      setCurrentSentence(storedData.sentences[0]);

      // Check if the game was played today
      const lastPlayed = localStorage.getItem(LAST_PLAYED_KEY);
      const today = new Date();
      const lastPlayedDate = lastPlayed ? new Date(lastPlayed) : null;

      if (lastPlayedDate && 
          lastPlayedDate.getDate() === today.getDate() &&
          lastPlayedDate.getMonth() === today.getMonth() &&
          lastPlayedDate.getFullYear() === today.getFullYear()) {
        setScoreMultiplier(0.5);
        setIsHalfPoints(true);
      } else {
        setScoreMultiplier(1);
        setIsHalfPoints(false);
      }
    } else if (!toastShownRef.current) {
      NotificationsDispatcher({
        type: ToastNotificationType.error,
        title: t('toastNotifications.error.title'),
        message: t('toastNotifications.error.message'),
      });
      toastShownRef.current = true;
      navigate(PROTECTED_ROUTES_PATHS.home);
    }
  }, [navigate, t, translationsLoaded]);

  const handleGameOver = useCallback(() => {
    setIsGameOver(true);
    setGameStarted(false);
    const allCorrectAnswers = dailyPrayerGame.sentences.every(
      (sentence: any) =>
        userAnswersRef.current[sentence.id] === sentence.correct_answer
    );

    // Calculate final points, doubling if all answers are correct
    const calculatedFinalPoints = allCorrectAnswers ? totalPointsRef.current * 2 : totalPointsRef.current;
    setFinalPoints(calculatedFinalPoints);

    submitDailyPrayerGame.mutate({
      id: dailyPrayerGame.public_uid,
      points: calculatedFinalPoints,
      allCorrectAnswers,
      answers: userAnswersRef.current,
    }, {
      onSuccess: () => {
        getHome().catch(error => {
          console.error('Error fetching home data after game completion:', error);
        });
      }
    });
  }, [dailyPrayerGame, submitDailyPrayerGame, getHome]);

  const handleNextSentence = useCallback(() => {
    if (!dailyPrayerGame || !dailyPrayerGame.sentences) return;

    setIsTransitioning(true);
    setTimeout(() => {
      if (currentSentenceIndex < dailyPrayerGame.sentences.length - 1) {
        const nextIndex = currentSentenceIndex + 1;
        setCurrentSentenceIndex(nextIndex);
        setCurrentSentence(dailyPrayerGame.sentences[nextIndex]);
        setTimeLeft(dailyPrayerGame.timer);
        setTimeout(() => {
          setIsTransitioning(false);
        }, 500);
      } else {
        handleGameOver();
      }
    }, 1000);
  }, [currentSentenceIndex, dailyPrayerGame, handleGameOver]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (
      gameStarted &&
      timeLeft > 0 &&
      !isTransitioning &&
      !isGameOver &&
      !isPaused
    ) {
      timer = setInterval(() => {
        setTimeLeft(prevTime => {
          if (prevTime <= 1) {
            clearInterval(timer);
            handleNextSentence();
            return 0;
          }
          return prevTime - 1;
        });
      }, 1000);
    } else if (timeLeft === 0 && gameStarted && !isGameOver) {
      handleNextSentence();
    }
    return () => clearInterval(timer);
  }, [
    gameStarted,
    timeLeft,
    isTransitioning,
    isGameOver,
    isPaused,
    handleNextSentence,
  ]);

  const handleSelectAnswer = useCallback(
    (answer: string, points: number) => {
      if (isTransitioning || isGameOver || !dailyPrayerGame || !currentSentence)
        return;

      userAnswersRef.current = {
        ...userAnswersRef.current,
        [currentSentence.id]: answer,
      };
      setUserAnswers(userAnswersRef.current);

      if (answer === currentSentence.correct_answer) {
        const adjustedPoints = Math.round(points * scoreMultiplier);
        const newScore = score + adjustedPoints;
        setScore(newScore);

        totalPointsRef.current += adjustedPoints;
        setTotalPoints(totalPointsRef.current);
      }

      if (currentSentenceIndex === dailyPrayerGame.sentences.length - 1) {
        handleGameOver();
      } else {
        handleNextSentence();
      }
    },
    [
      isTransitioning,
      isGameOver,
      dailyPrayerGame,
      currentSentence,
      currentSentenceIndex,
      handleNextSentence,
      handleGameOver,
      score,
      scoreMultiplier,
    ]
  );

  const handleStartGame = useCallback(() => {
    setIsModalOpen(false);
    setGameStarted(true);
    setCurrentSentenceIndex(0);
    setTimeLeft(dailyPrayerGame.timer);
    setScore(0);
    setIsGameOver(false);
    setIsTransitioning(false);
  }, [dailyPrayerGame]);

  const handlePauseGame = useCallback(() => {
    setIsPaused(true);
  }, []);

  const handleResumeGame = useCallback(() => {
    setIsPaused(false);
  }, []);

  const handleCloseGame = useCallback(() => {
    if (isGameOver) {
      // Show the points modal with the calculated final points
      dispatch(
        openPointsModal({
          id: 'dailyPrayerGame',
          title: 'Daily Prayer Game Completed!',
          pointsEarned: finalPoints,
        })
      );
      // Navigate back to home after a short delay
      setTimeout(() => {
        navigate(PROTECTED_ROUTES_PATHS.home);
      }, 500);
    } else {
      navigate(PROTECTED_ROUTES_PATHS.home);
    }
  }, [isGameOver, dispatch, finalPoints, navigate]);

  const shuffledOptions = useMemo(() => {
    if (!currentSentence) return [];
    return [
      currentSentence.correct_answer,
      currentSentence.incorrect_answer_1,
      currentSentence.incorrect_answer_2,
      currentSentence.incorrect_answer_3,
    ].sort(() => Math.random() - 0.5);
  }, [currentSentence]);

  const completePrayerText = useMemo(() => {
    if (!dailyPrayerGame || !dailyPrayerGame.sentences) return '';
    return dailyPrayerGame.sentences.map((s: any) => s.sentence).join(' ');
  }, [dailyPrayerGame]);

  if (!translationsLoaded) {
    return null; 
  }

  if (!dailyPrayerGame || !dailyPrayerGame.sentences) {
    return null;
  }

  return (
    <div className='relative h-screen w-full'>
      <HomeTemplate>
        {!gameStarted && !isGameOver && (
          <ModalSheetDailyPrayer
            isOpen={isModalOpen}
            onClose={handleCloseGame}
            onStartGame={handleStartGame}
            isHalfPoints={isHalfPoints}
          />
        )}
        {gameStarted && !isGameOver && currentSentence && (
          <div className='absolute inset-0 z-50'>
            <DailyPrayerGame
              question={currentSentence.sentence}
              options={shuffledOptions}
              onSelectAnswer={handleSelectAnswer}
              timeLeft={timeLeft}
              initialTime={dailyPrayerGame.timer}
              score={score}
              onCloseClick={handleCloseGame}
              imageUrl={dailyPrayerGame.image_url}
              isTransitioning={isTransitioning}
              minPoints={dailyPrayerGame.min_points}
              maxPoints={dailyPrayerGame.max_points}
              correctAnswer={currentSentence.correct_answer}
              onPause={handlePauseGame}
              onResume={handleResumeGame}
              isHalfPoints={isHalfPoints}
            />
          </div>
        )}
        {isGameOver && (
          <div className='absolute inset-0 z-50 flex items-center justify-center bg-secondary-surface-100'>
            <div className='w-full max-w-md px-4'>
              <CompleteDailyPrayer
                text={completePrayerText}
                correctAnswers={dailyPrayerGame.sentences.map(
                  (s: any) => s.correct_answer
                )}
                imageUrl={dailyPrayerGame.image_url}
                title={dailyPrayerGame.title}
              />
              <div className='mt-8'>
                <Button
                  onClick={handleCloseGame}
                  text='Finish'
                  style='primary'
                  className='w-full'
                />
              </div>
            </div>
          </div>
        )}
      </HomeTemplate>
      <ModalSheetPointsGlobal />
    </div>
  );
};
