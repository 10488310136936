import { TabNavigationButton } from '@components/molecules';
import { PROTECTED_ROUTES_PATHS } from '@navigation/routes/RoutesPaths';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { UserSelectors } from 'src/data/store/slices';
import { UserRole } from 'src/domain/constants';
import { AnimatePresence } from 'framer-motion';

export const TabNavigation = () => {
  const { t } = useTranslation('components/organisms/tabNavigation');
  const userRole = useSelector(UserSelectors.getRole);

  const STUDENT_TABS = useMemo(
    () => [
      {
        id: 'modules',
        label: t('modules'),
        route: PROTECTED_ROUTES_PATHS.modules,
      },
      {
        id: 'retos',
        label: t('retos'),
        route: PROTECTED_ROUTES_PATHS.retos,
      },
      {
        id: 'myProgress',
        label: t('myProgress'),
        route: PROTECTED_ROUTES_PATHS.myProgress,
      },
    ],
    [t]
  );

  const CATECHIST_TABS = useMemo(
    () => [
      {
        id: 'modules',
        label: t('modules'),
        route: PROTECTED_ROUTES_PATHS.modules,
      },
      {
        id: 'students',
        label: t('students'),
        route: PROTECTED_ROUTES_PATHS.students,
      },
      {
        id: 'retos',
        label: t('retos'),
        route: PROTECTED_ROUTES_PATHS.retos,
      },
    ],
    [t]
  );

  const TABS = userRole === UserRole.STUDENT ? STUDENT_TABS : CATECHIST_TABS;

  return (
    <AnimatePresence mode='wait'>
      <nav className='flex w-full justify-between gap-2 !bg-secondary-surface-100 px-4 [&_*]:font-bold [&_*]:!text-secondary-buttons-100'>
        {TABS.map(({ id, label, route }) => (
          <TabNavigationButton key={id} label={label} route={route} />
        ))}
      </nav>
    </AnimatePresence>
  );
};
